import React from 'react';
import 'react-image-lightbox/style.css';
import { NavItem, NavLink } from 'reactstrap';

const VLeftTab = props => {
    return (
        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
            <NavLink
                className={props.activeTab}
                onClick={props.setActiveTab}
            >
                <span style={{ overflowWrap: "anywhere" }}>{props.conetent}</span>
                <span>{props.count}</span>
            </NavLink>
        </NavItem>
    );
};

export default VLeftTab;