import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import { MENUITEMS } from '../../../constant/menu';


const Sidebar = (props) => {

    return (
        <Fragment>
            <div className="page-sidebar">
                <div className="sidebar custom-scrollbar">
                    <ul
                        className="sidebar-menu"
                        id="myDIV"
                        style={{ margin: '0px' }}
                    >
                        {
                            MENUITEMS.map((menuItem, i) =>
                                <li className="m-b-5" key={i}>
                                    <Link
                                        to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                                        className={`sidebar-header ${window.location.pathname === menuItem.path ? 'active' : ''}`}
                                    >
                                        {menuItem.icon}
                                        {menuItem.path === "/appartment-request" ? <span>3<sup className='text-lower'>rd</sup> Apartment Request</span> : <span>{props.t(menuItem.title)}</span>}
                                    </Link>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default translate(Sidebar);

