import React, { useState } from "react";
import { Collapse } from "reactstrap";

const OptionTab = (props) => {
  const [viewAnswer, setViewAnswer] = useState(false);
  return (
    <div className="card" count={props.count}>
      <div className="card-header">
        <h5 className="mb-0 d-flex justify-content-between align-items-center">
          <button
            className="btn btn-link collapsed pl-0 f-15"
            onClick={() => setViewAnswer(!viewAnswer)}
            data-toggle="collapse"
            data-target={viewAnswer}
            aria-expanded={viewAnswer}
            aria-controls="collapseicon"
          >
            {/* <span className="m-r-10 f-16 font-weight-bold">{props.id + 1}.</span> */}
            {props.question}
          </button>
        </h5>
      </div>
      <Collapse isOpen={viewAnswer}>
        <div className="card-body">{props.children}</div>
      </Collapse>
    </div>
  );
};

export default OptionTab;
