import React from 'react';
import 'react-image-lightbox/style.css';
import { NavItem, NavLink } from 'reactstrap';

const HLeftTab = props => {
    return (
        <NavItem className="nav nav-tabs" id="myTab" role="tablist">
            <NavLink
                className={props.activeTab}
                onClick={props.setActiveTab}
            >
                {props.icon}
                {props.conetent}
            </NavLink>
        </NavItem>
    );
};

export default HLeftTab;