import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-image-lightbox/style.css';
import Navigation from '../../components/common/Navigation';
import HRightTab from "../../components/common/Navigation/hRightTab";
import Address from '../../components/userProfile/Address';
import ChangePassword from "../../components/userProfile/Change Password/index";
import Detail from "../../components/userProfile/Detail/index";
import { PROFILE_TAB } from '../../constant/common';

const Profile = () => {
    const [activeTab, setActiveTab] = useState(1);
    return (
        <Fragment>
            <Helmet>
                <title>Profile</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyHeader">
                        <h5>Profile</h5>
                        <span></span>
                    </div>
                    <div className="pageBodyContent product-page-main">
                        <Navigation
                            TabType="borderb-tab-primary"
                            data={PROFILE_TAB}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab} >
                            <HRightTab tabId={1}>{activeTab === 1 ? <Detail /> : null}</HRightTab>
                            <HRightTab tabId={2}>{activeTab === 2 ? <Address /> : null}</HRightTab>
                            <HRightTab tabId={3}>{activeTab === 3 ? <ChangePassword /> : null}</HRightTab>
                        </Navigation>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    // }
};

export default Profile;