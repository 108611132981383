import React, { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Datatable from '../../components/common/DataTable'
import Navigation from '../../components/common/Navigation'
import HRightTab from '../../components/common/Navigation/hRightTab'
import SearchBar from '../../components/common/SearchBar'
import Upsert from '../../components/Version/upsert'
import { API_BUILDING, API_USER } from '../../constant/apiConstants'
import {
  API_STATUS,
  DEFAULT_PAGE_SIZE,
  MODEL,
  VERSION_PLATFORM,
  VERSION_STATUS_TAB3,
} from '../../constant/common'
import CommonService from '../../constant/commonService'
import UtilService from '../../services/common.service'
import ToolTipCommon from '../../components/common/ToolTip'
import Details from '../AssurerManagement/view'
import { Eye } from 'react-feather'
import BusinessList from '../AssurerManagement/BusinessList'
import { Button } from 'reactstrap'
import AddBuildingDetails from '../../components/BuildingDetails/AddBuildingDetails'
import DetailsModalCommon from '../../components/common/modal/detailsmodal'
import UploadExcel from '../../components/BuildingDetails/UploadExcel'
import { toast } from 'react-toastify'

const Building = (props) => {
  const [masterData, setMasterData] = useState([]) // data list api
  const [totalPages, setTotalPages] = useState(1) // total pages for pagination
  const [edit, setEditKey] = useState() // edit record..
  const [activeTab, setActiveTab] = useState(VERSION_PLATFORM.IOS)
  const [popupDetail, setPopupDetail] = useState()
  const [businessPopupDetail, setBusinessPopupDetail] = useState()
  const [uploadModal, setUploadModal] = useState()
  const [editOrAdd, setEditOrAdd] = useState('Add')

  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {},
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      search: {
        keys: ['name', 'cvr', 'city', 'street'],
        // keyword: "test",
      },
    },
  }

  const [filters, setFilters] = useState(initialApiFilter.filter)
  const [addBuildingDetail, setAddBuildingDetail] = useState(false)

  useEffect(() => {
    // call api on filter change
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])
  const handleFilterChange = (limit) => {
    // page and limit filter change from data table
    let filter = { ...filters, ...limit }
    setFilters(filter)
  }
  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters
    filter.page = 1
    filter.sort = sort
    setFilters(filter)
    fetch()
  }
  const search = async (searchVal) => {
    // handle search filters from search component
    let filter = filters
    filter.search.keyword = searchVal
    filter.page = 1
    setFilters(filter)
    fetch()
  }

  const fetch = () => {
    // api data
    let request = { ...API_BUILDING.paginate }
    request.obj = filters
    UtilService.callApi(request, function ({ code, data }) {
      if (code === API_STATUS.OK) {
        setMasterData(data?.list)
        setTotalPages(Math.ceil(data.count / filters.limit))
      } else {
        setMasterData([])
        setTotalPages(1)
      }
    })
  }

  const handlePopUpDetails = (data) => {
    setPopupDetail(data)
  }

  const columns = [
    // table columns
    {
      Header: 'No.',
      id: '_id',
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: ' Street Name',
      accessor: 'street',
    }, {
      Header: ' City',
      accessor: 'city',
    },
    {
      Header: ' Zip Code',
      accessor: 'zip',
    },
    {
      Header: ' Value',
      accessor: 'amount',
    },
    // {
    //   Header: 'Address',
    //   Cell: ({ original = {} }) => {
    //     return (
    //       <ToolTipCommon
    //         title={'Address'}
    //         onClick={() => handlePopUpDetails(original)}
    //       >
    //         <Eye
    //           className="actions-icon action-primary"
    //           style={{ width: 22 }}
    //         />
    //       </ToolTipCommon>
    //     )
    //   },
    // },
  ]

  const handleDelete = (id) => {
    let request = { ...API_BUILDING.delete }
    request.url = request.url + id
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        fetch()
        toast.success(message)
      } else {
        toast.error(message)
      }
    })
  }

  const handleEdit = (data) => {
    setEditOrAdd('Edit')
    setAddBuildingDetail(data)
  }

  const handleAddBuilding = () => {
    setEditOrAdd('Add')
    setAddBuildingDetail(true)
  }
  return (
    <Fragment>
      <Helmet>
        <title>Building</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="flex-wrap pageBodyHeader d-flex justify-content-between align-items-center">
              <div>
                <h5>Building</h5>
                <span></span>
              </div>
              <div className="flex-wrap d-flex align-items-right">
                <div className="m-r-10">
                  <SearchBar
                    handleSearch={search}
                    title={'Search by building name, cvr, street and city.'}
                  />
                </div>
                <Button color="primary" onClick={handleAddBuilding}>
                  Add Building
                </Button>
                <div className="ml-3">
                  <Button color="primary" onClick={() => setUploadModal(true)}>
                    Upload Excel
                  </Button>
                </div>
              </div>
            </div>
            <div className="search-page profile-page">
              <div className="PageBodyWrapper">
                <div className="pageBodyContent product-page-main">
                  <div className="table-responsive support-table">
                    <Datatable
                      columns={columns}
                      myData={masterData}
                      pagination={true}
                      pageSize={filters.limit}
                      page={filters.page}
                      class="-striped -highlight"
                      handleChange={handleFilterChange}
                      pages={totalPages}
                      handleSort={handleSort}
                      fetch={fetch}
                      model={MODEL.VERSION}
                      actions={{
                        canDelete: true,
                        canEditData: true,
                      }}
                      edit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupDetail && (
        <DetailsModalCommon
          id={popupDetail}
          onClose={() => setPopupDetail(false)}
        />
      )}
      {/* {popupDetail && (
        <Details id={popupDetail} onClose={() => setPopupDetail(false)} />
      )} */}
      {businessPopupDetail && (
        <BusinessList
          id={popupDetail}
          onClose={() => setBusinessPopupDetail(false)}
        />
      )}

      {addBuildingDetail && (
        <AddBuildingDetails
          fetch={fetch}
          editOrAdd={editOrAdd}
          editData={addBuildingDetail}
          onClose={() => setAddBuildingDetail(false)}
        />
      )}
      {uploadModal && (
        <UploadExcel
          id={uploadModal}
          fetch={fetch}
          onClose={() => setUploadModal(false)}
        />
      )}
    </Fragment>
  )
}
export default Building
