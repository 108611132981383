import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Lock} from 'react-feather';
import { toast } from 'react-toastify';
import Upsert from '../../components/Admin/upsert';
import Datatable from '../../components/common/DataTable';
import SearchBar from '../../components/common/SearchBar';
import { API_USER } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_PAGE_SIZE, USER_TYPE, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
import ToolTipCommon from '../../components/common/ToolTip';
import Delete from '../../components/common/Delete';

const Admin = () => {
    const [masterData, setMasterData] = useState([]); // data list api
    const [totalPages, setTotalPages] = useState(1) // total pages for pagination
    const [edit, setEditKey] = useState(); // edit record..
    const initialApiFilter = { // filter to send to api
        filter: {
            isDeleted: false,
            type: USER_TYPE.ADMIN
        },
        page: 1,
        limit: DEFAULT_PAGE_SIZE,
        search: {
            keys: ["name", "emails.email"],
            keyword: ""
        }
    }
    const [filters, setFilters] = useState(initialApiFilter)
    useEffect(() => { // call api on filter change 
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handleFilterChange = (limit) => { // page and limit filter change from data table
        let filter = { ...filters, ...limit }
        setFilters(filter)
    }
    const handleSort = (sort) => { // handle sort change from data table
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const search = (searchVal) => { // handle search filters from search component
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const handleResetPassword = (id, status) => {
        let request = status === 1 ? { ...API_USER.sendResetPassword } : { ...API_USER.sendPasswordResetLink };
        request.url = request.url + id
        UtilService.callApi(request, function (res) {
            if (res && res.code === API_STATUS.OK) {
                toast.success(res.message)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    }
    const fetch = () => { // api data 
        let request = { ...API_USER.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                setTotalPages(Math.ceil(res.data.count / filters.limit))
            }
            else {
                setMasterData([])
                setTotalPages(1)
            }
        })
    }
    const columns = [ // table columns
        {
            Header: "No.",
            id: "_id",
            Cell: row => {
                return (row.index + 1) + ((filters.page - 1) * filters.limit)
            },
            sortable: false,
            resizable: false,
            width: 50
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Email Address",
            id: "emails",
            Cell: row => row.original.emails[0].email,
            sortable: false,
            minWidth: 150
        },{
            Header: "Action",
            id: "status",
            Cell: row => {
                let status = row.original.status
                return (
                    <div className="action-act">
                        <ToolTipCommon  title="Reset Password">
                            <Lock className="actions-icon action-primary" onClick={() => handleResetPassword(row.original._id, 1)} style={{ width: 22 }} />
                        </ToolTipCommon>
                        <Delete id={row.original._id} model={"User"} fetch={fetch} />
                    </div>)
            },
            sortable: false,
            style: {
                textAlign: 'center',
                alignItems: 'center'
            }
        }
    ]
    return (
        <Fragment>
            <Helmet>
                <title>User Management</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                            <div>
                                <h5>User Management</h5><span></span>
                            </div>
                            <div className="d-flex flex-wrap align-items-right">
                                <div className="m-r-10" >
                                    <SearchBar fetch={fetch} handleSearch={search} title={"Search by name and email address."} />
                                </div>
                                <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={"Add Admin/User"} />
                            </div>
                        </div>
                        <div className="pageBodyContent product-page-main">
                            <div className="table-responsive support-table">
                                <Datatable
                                    // actions={{ canDelete: true, canReset: true }}
                                    columns={columns}
                                    myData={masterData}
                                    pagination={true}
                                    pageSize={filters.limit}
                                    page={filters.page}
                                    class="-striped -highlight"
                                    handleChange={handleFilterChange}
                                    pages={totalPages}
                                    handleSort={handleSort}
                                    fetch={fetch}
                                    model={"User"}
                                    edit={(id) => { setEditKey(id) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    // }
};
export default Admin;