import React, { Fragment, useEffect, useState } from "react";
import { MapPin } from "react-feather";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import CommonService from "../../constant/commonService";
import ImageByGender from "../../components/common/ImageByGender";

const Details = (props) => {
  const {
    id,
    child="", //string of title without icon of pin
    address="", //string of address with the icon of pin
    title=""
   } = props;
  const [data, setData] = useState();
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);
  const fetch = () => {
    setData(props.id);
  };

  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{title}</ModalHeader>
        <ModalBody>
          <div className="default-according style-1 faq-accordion">
            <div className="card">
              <div className="card-header">
                <div class="d-flex">
                  {address.length > 0 ? <div>
                    <div className="d-flex">
                      <MapPin
                        className="fillSvgColor mr-1"
                        style={{ width: 20, height: 20 }}
                      />
                    </div>
                  </div>: ""}
                  <p className="mb-0"> {child}</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Details;
