/* eslint-disable import/newline-after-import */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const SingleSelect = ({
    data,
    name,
    onChangeSelect,
    isSearchable = true,
    placeholder = '',
    ...rest
}) => {

    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(rest.value || '')
    }, [rest.value])

    const StylesConfig = {
        option: (provided, { isFocused, isSelected }) => ({
            ...provided,
            color: isSelected ? '#000' : '',
            background: isFocused ? '#14B59930' : isSelected ? '#14B599' : undefined,
            '&:hover': {
                background: '#14B599',
            },
        }),
    }


    return (
        < Select
            className='basic-single'
            classNamePrefix={name}
            isSearchable={isSearchable}
            name={name}
            options={data}
            placeholder={placeholder}
            styles={StylesConfig}
            {...rest}
            onChange={onChangeSelect}
            value={value}
        />
    )
}

export default SingleSelect
