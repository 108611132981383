import React from "react";
import { API_REQUEST_APARTMENT, API_USER } from "./apiConstants";

export const API_STATUS = {
  UN_AUTH_CODE: "E_UNAUTHORIZED",
  OK: "OK",
  CREATED: "CREATED",
  UNPROCESSABLE_ENTITY: "UNPROCESSABLE_ENTITY",
  BAD_REQUEST: "BAD_REQUEST",
};
export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA_URL = API_URL;
export const DEFAULT_API_ERROR = "Something went wrong. Please try again.";
export const DEFAULT_SUCCESS_MESSAGE = "Success";
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_OPTIONS = [10, 20, 50, 100];
export const DEFAULT_EMPTY_PAGE = 10;

// backend constant
export const PATIENT_STATUS = {
  ACTIVE: 1,
  DEACTIVE: 2,
  APRT_REQUEST: 3,
};
export const RANGE_DATE_FORMAT = "dd-MM-yyyy";
export const USER_TYPE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_ADMIN: 3,
  PATIENT: 4,
  PHYSICIAN: 5,
};
export const ROOM = {
  LIVING_ROOM: 1,
  BEDROOM: 2,
  KITCHEN: 3,
  TOILET: 4,
  OTHER: 5,
};
export const ROOM_TYPE = {
  [ROOM.LIVING_ROOM]: "Living Room",
  [ROOM.BEDROOM]: "Bed Room",
  [ROOM.KITCHEN]: "Kitchen",
  [ROOM.TOILET]: "Toilet",
  [ROOM.OTHER]: "Other",
};
export const GENDER_TYPE = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};
export const GENDER = {
  [GENDER_TYPE.MALE]: "Male",
  [GENDER_TYPE.FEMALE]: "Female",
  [GENDER_TYPE.OTHER]: "Other",
};

export const COMMUNICATION_TYPE = {
  AUDIO_CALL: 1,
  VIDEO_CALL: 2,
  CHAT: 3,
};

export const FAQ_TYPE = {
  PATIENT: 1,
  PHYSICIAN: 2,
};
export const VERSION_PLATFORM = {
  // WEB: 1,
  ANDROID: 2,
  IOS: 3,
};

export const ASSURER_MANAGEMENT = {
  ASSURER_REQUEST: 1,
  ASSURER_LIST: 2,
  ASSURER_DECLINED: 3,
  ASSURER_EXTRA_JOB_REQUEST: 4,
};

export const ORDER_MANAGEMENT = {
  ORDER_PENDING: 1,
  ORDER_COMPLETED: 2,
};

export const CHECKLIST_TYPE = {
  GENERAL: 1,
  ATTRIBUTE: 2,
};
export const DAYS_OF_WEEK = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};
export const MODEL = {
  MASTER: "Master",
  FAQS: "Faqs",
  USER: "User",
  VERSION: "Version",
  THEME: "Theme",
  DEPRECIATION: "Depreciation",
};

export const MASTER_CODES = {
  PROVINCE: "PROVINCE",
  CITY: "CITY",
  SUBJECT: "SUBJECT",
  MULTI_LANGUAGE_SUPPORT: "MULTILANGUAGESUPPORT",
  ATTRIBUTE: "ATTRIBUTE",
};

// filters constant
export const STATUS_FILTER = [
  { label: "All", value: 1 },
  { label: "Active", value: 2, type: true },
  { label: "Deactive", value: 3, type: false },
];

export const PLATFORM_TYPE_FILTER = [
  { label: "All", value: 1 },
  // { label: "Web", value: 2, type: VERSION_PLATFORM.WEB },
  { label: "Android", value: 2, type: VERSION_PLATFORM.ANDROID },
  { label: "IOS", value: 3, type: VERSION_PLATFORM.IOS },
];
export const GENDER_FILTER = [
  { label: "All", value: 1 },
  { label: "Male", value: 2, type: GENDER_TYPE.MALE },
  { label: "Female", value: 3, type: GENDER_TYPE.FEMALE },
  { label: "Other", value: 4, type: GENDER_TYPE.OTHER },
];

// navigation tab  constant

export const SETTINGS = {
  MASTER: 1,
  DATA_BANK: 2,
  CONFIG: 3,
};
export const PROFILE_TAB = [
  { id: 1, name: "Detail" },
  // { id: 2, name: 'Address' },
  { id: 3, name: " Change Password" },
];
export const FAQS_MENU = [
  { id: 1, name: "Patient" },
  { id: 2, name: "Physician" },
];

export const SETTINGS_MENU = [
  {
    id: SETTINGS.MASTER,
    name: "Master",
    icon: <i className="icon-target"></i>,
  },
  {
    id: SETTINGS.DATA_BANK,
    name: "Data Bank",
    icon: <i className="icon-target"></i>,
  },
  {
    id: SETTINGS.CONFIG,
    name: "Configuration",
    icon: <i className="icon-target"></i>,
  },
];

export const PATIENT_STATUS_TAB = [
  {
    id: PATIENT_STATUS.ACTIVE,
    name: "Active",
    key: "isActive",
    value: true,
    request: API_USER.paginate,
  },
  {
    id: PATIENT_STATUS.DEACTIVE,
    name: "Deactive",
    key: "isActive",
    value: false,
    request: API_USER.paginate,
  },
];
export const SELECT_STYLE = {
  control: (base, state) => ({
    ...base,
    borderColor: "#ced4da",
    padding: "0 0.75rem",
    fontSize: "1rem",
    color: "#eff0f1",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid #eff0f1",
    borderRadius: "0.25rem",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: 0,
  }),
  multiValue: (base, state) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#898989",
  }),
};
export const VERSION_STATUS_TAB = [
  {
    id: VERSION_PLATFORM.ANDROID,
    name: "Android",
    key: "platform",
    value: VERSION_PLATFORM.ANDROID,
  },
  {
    id: VERSION_PLATFORM.IOS,
    name: "IOS",
    key: "platform",
    value: VERSION_PLATFORM.IOS,
  },
];
export const ASSURER_MANAGEMENT_TAB = [
  {
    id: ASSURER_MANAGEMENT.ASSURER_REQUEST,
    name: "Request",
    key: "platform",
    value: ASSURER_MANAGEMENT.ASSURER_REQUEST,
    verified: false,
    delete: false,
    approveDecline: true,
    actionColumn: true,
    deleteBtn: false,
    apartmentColumn: false,
    reasonColumn: true,
  },
  {
    id: ASSURER_MANAGEMENT.ASSURER_LIST,
    name: "list",
    key: "platform",
    value: ASSURER_MANAGEMENT.ASSURER_LIST,
    verified: true,
    delete: false,
    approveDecline: false,
    actionColumn: true,
    deleteBtn: true,
    apartmentColumn: true,
    reasonColumn: false,
    viewHistory: true
  },
  {
    id: ASSURER_MANAGEMENT.ASSURER_DECLINED,
    name: "Declined",
    key: "platform",
    value: ASSURER_MANAGEMENT.ASSURER_DECLINED,
    verified: false,
    approveDecline: false,
    delete: true,
    actionColumn: false,
    deleteBtn: false,
    apartmentColumn: false,
    reasonColumn: false,
  },
  {
    id: ASSURER_MANAGEMENT.ASSURER_EXTRA_JOB_REQUEST,
    name: "Extra Job Request",
    key: "platform",
    value: ASSURER_MANAGEMENT.ASSURER_EXTRA_JOB_REQUEST,
    verified: false,
    approveDecline: true,
    delete: true,
    actionColumn: true,
    deleteBtn: false,
    apartmentColumn: false,
    reasonColumn: false,
  },
];

export const VERSION_STATUS_TAB3 = [
  {
    id: VERSION_PLATFORM.IOS,
    name: "Building",
    key: "building",
    value: VERSION_PLATFORM.IOS,
  },
  {
    id: VERSION_PLATFORM.ANDROID,
    name: "Value of building",
    key: "Value of building",
    value: VERSION_PLATFORM.ANDROID,
  },
];
export const VERSION_STATUS_TAB4 = [
  {
    id: CHECKLIST_TYPE.ATTRIBUTE,
    name: "Based on Attribute",
    key: "based_on_attribute",
    value: CHECKLIST_TYPE.ATTRIBUTE,
  },
  {
    id: CHECKLIST_TYPE.GENERAL,
    name: "General Checklist",
    key: "general Checklist",
    value: CHECKLIST_TYPE.GENERAL,
  },
];

export const APARTMENT_REQUEST_LIST = {
  PENDING: 1,
  APPROVE: 2,
  DECLINE: 3,
};

export const VERSION_STATUS_TAB5 = [
  {
    id: APARTMENT_REQUEST_LIST.PENDING,
    name: "Pending",
    key: "status",
    value: APARTMENT_REQUEST_LIST.PENDING,
    reason: true,
    action: true,
    declineReason: false,
  },
  {
    id: APARTMENT_REQUEST_LIST.APPROVE,
    name: "Approve",
    key: "status",
    value: APARTMENT_REQUEST_LIST.APPROVE,
    reason: false,
    action: false,
    declineReason: false,
  },
  {
    id: APARTMENT_REQUEST_LIST.DECLINE,
    name: "Decline",
    key: "status",
    value: APARTMENT_REQUEST_LIST.DECLINE,
    reason: false,
    action: false,
    declineReason: true,
  },
];

export const APARTMENT_LIMIT_REQUEST = {
  PENDING: 1,
  APPROVED: 2,
  DECLINED: 3,
};
export const REASON_FOR_ASSURER = {
  REGISTRATION: "Reason for registration",
  DECLINE: "Reason for decline",
  APARTMENT: "Assured apartment",
  BUILDING: "Buildings",
};
export const STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DECLINE: 3,
};

export const BOARD_MANAGEMENT = [
  {
    id: STATUS.PENDING,
    name: "Pending",
    key: "status",
    value: STATUS.PENDING,
    action: true,
    isVerified: false,
    isActive: true,
    isDeleted: false,
    reason: false,
    approveDecline: true,
    delete: false,
    amount: false,
  },
  {
    id: STATUS.APPROVED,
    name: "Approve",
    key: "status",
    value: STATUS.APPROVED,
    action: true,
    isVerified: true,
    isActive: true,
    isDeleted: false,
    reason: false,
    approveDecline: false,
    delete: true,
    amount: true,
  },
  {
    id: STATUS.DECLINE,
    name: "Decline",
    key: "status",
    value: STATUS.DECLINE,
    action: false,
    isVerified: false,
    isActive: false,
    isDeleted: true,
    reason: true,
    approveDecline: false,
    delete: true,
    amount: false,
  },
];

export const REASON_FOR_APARTMENT_REQUEST = {
  PENDING: "Reason",
  DECLINE: "Reason of decline",
};

export const BUILDING_BOARD_MANAGEMENT = {
  BUILDING: "Building",
  DECLINE: "Decline Reason",
};

export const BUILDING_PENDING_TAB = {
  status: 1,
};

export const APPROVE_HANDLE_STRING = {
  APPROVE: "Approve",
};

export const MAX_CVR = 8;

export const INVOICE_TYPE = {
  BOARD: 1,
  ASSURER: 2,
};

export const INVOICE_TAB_TYPE = {
  BOARD: 7,
  ASSURER: 5,
};

export const INVOICE_TYPE_LIST = [
  {
    id: INVOICE_TYPE.BOARD,
    name: "Board",
    value: 7,
  },
  {
    id: INVOICE_TYPE.ASSURER,
    name: "Assurer",
    value: 5,
  },
];

export const DATE_FORMAT = "DD/MM/YYYY";

export const LOCALISATION = {
  ENGLISH: "en",
  DANISH: "dn",
  DEFAULT: "DANISH"
};

export const ORDER_MANAGEMENT_TAB = [
  {
    id: ORDER_MANAGEMENT.ORDER_PENDING,
    name: "Pending",
    key: "platform",
    value: ORDER_MANAGEMENT.ORDER_PENDING,
    verified: false,
    delete: false,
    approveDecline: true,
    actionColumn: true,
    deleteBtn: false,
    apartmentColumn: false,
    reasonColumn: true,
  },
  {
    id: ORDER_MANAGEMENT.ORDER_COMPLETED,
    name: "completed",
    key: "platform",
    value: ORDER_MANAGEMENT.ORDER_COMPLETED,
    verified: true,
    delete: false,
    approveDecline: false,
    actionColumn: true,
    deleteBtn: true,
    apartmentColumn: true,
    reasonColumn: false,
  },
];

export const ORDER_TYPE = [
  { label: "Job create", value: 1 },
  { label: "Job accept", value: 2 },
  { label: "Register", value: 3 }
]