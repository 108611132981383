import React, { Fragment, useEffect, useRef, useState } from "react";
import useForm from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  API_MASTER,
  API_SPECIALIZATION,
  FETCH_TYPE,
  API_DEPRECIATION_MODEL,
} from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  MASTER_CODES,
  SELECT_STYLE,
} from "../../constant/common";
import UtilService from "../../services/common.service";
import MultiLanguage from "../common/MultiLanguage";
import find from "lodash/find";
// import Loader from '../common/loader';

const Upsert = (props) => {
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const { edit } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prevEdit = usePrevious({ edit });
  const [modal8, setModal8] = useState();
  const [loader, setloader] = useState(false);
  const [flag, setFlag] = useState(true);
  const [SubjectMaster, setSubjectMaster] = useState();
  const [typeList, setTypeList] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [multiLanguageData, setMultiLanguageData] = useState();
  const [inputTag, setInputTag] = useState([
    { subjectId: {}, year: "", type: {}, depre: "", depreId: "" },
  ]);
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: "onChange",
  });
  const toggle8 = () => {
    if (modal8) {
      setValue("subjects", undefined);
      setMultiLanguageData(null);
      setSelectedLanguage("EN");
      setDefaultLanguage("EN");
      setloader(false);
      props.editDone();
    } else {
      setloader(false);
      setInputTag([
        { subjectId: {}, year: "", type: {}, depre: "", depreId: "" },
      ]);
      setFlag(true);
    }
    setModal8(!modal8);
  };
  const subjects = useRef({});
  subjects.current = watch("subjects", undefined);
  useEffect(() => {
    fetchMastersNotEdit();
    fecthTypeNotEdit();
    setloader(false);

    register({ name: "subjects" }, { required: true });
    register({ name: "name" }, { required: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (prevEdit && prevEdit.edit !== edit) {
      if (edit) {
        toggle8();
        fetch(edit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);
  const fetchMastersNotEdit = async (lang = "EN") => {
    let request = { ...API_MASTER.listByCode };
    request.obj = {
      masters: [MASTER_CODES.SUBJECT],
      include: ["subMasters"],
    };
    await UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        let masters = res.data;
        if (
          masters &&
          masters[MASTER_CODES.SUBJECT] &&
          masters[MASTER_CODES.SUBJECT].subMasters
        ) {
          if (
            masters[MASTER_CODES.SUBJECT].subMasters &&
            masters[MASTER_CODES.SUBJECT].subMasters.length
          ) {
            let AllData = [];
            if (lang === "EN") {
              masters[MASTER_CODES.SUBJECT].subMasters.map((abc) => {
                if (
                  abc.multiLanguageData &&
                  abc.multiLanguageData.EN &&
                  abc.multiLanguageData.EN.name
                ) {
                  AllData.push({
                    value: abc._id,
                    label: abc.multiLanguageData.EN.name,
                  });
                } else {
                  AllData.push({ value: abc._id, label: abc.name });
                }
              });
            } else {
              masters[MASTER_CODES.SUBJECT].subMasters.map((abc) => {
                if (
                  abc.multiLanguageData &&
                  abc.multiLanguageData.DANISH &&
                  abc.multiLanguageData.DANISH.name
                ) {
                  AllData.push({
                    value: abc._id,
                    label: abc.multiLanguageData.DANISH.name,
                  });
                } else {
                  AllData.push({ value: abc._id, label: abc.name });
                }
              });
            }
            setSubjectMaster([...AllData]);
          }
        }
      }
    });
  };
  const fecthTypeNotEdit = async (lang = "EN", temArray) => {
    let request = { ...FETCH_TYPE.paginate };
    request.obj = {};
    await UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        if (res.data && res.data.length) {
          let Alldata = [];
          if (lang === "EN") {
            res.data.forEach((val) => {
              if (
                val.multiLanguageData &&
                val.multiLanguageData.EN &&
                val.multiLanguageData.EN.name
              ) {
                Alldata.push({
                  value: val._id,
                  label: val.multiLanguageData.EN.name,
                });
              } else {
                Alldata.push({ value: val._id, label: val.name });
              }
            });
          } else {
            res.data.forEach((val) => {
              if (
                val.multiLanguageData &&
                val.multiLanguageData.DANISH &&
                val.multiLanguageData.DANISH.name
              ) {
                Alldata.push({
                  value: val._id,
                  label: val.multiLanguageData.DANISH.name,
                });
              } else {
                Alldata.push({ value: val._id, label: val.name });
              }
            });
          }
          setTypeList([...Alldata]);
        }
      }
    });
  };

  const fetchMasters = async (lang = "EN") => {
    setloader(true);
    let request = { ...API_MASTER.listByCode };
    request.obj = {
      masters: [MASTER_CODES.SUBJECT],
      include: ["subMasters"],
    };
    await UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        let masters = res.data;

        if (
          masters &&
          masters[MASTER_CODES.SUBJECT] &&
          masters[MASTER_CODES.SUBJECT].subMasters
        ) {
          if (
            masters[MASTER_CODES.SUBJECT].subMasters &&
            masters[MASTER_CODES.SUBJECT].subMasters.length
          ) {
            let AllData = [];
            if (lang === "EN") {
              masters[MASTER_CODES.SUBJECT].subMasters.map((abc) => {
                if (
                  abc.multiLanguageData &&
                  abc.multiLanguageData.EN &&
                  abc.multiLanguageData.EN.name
                ) {
                  AllData.push({
                    value: abc._id,
                    label: abc.multiLanguageData.EN.name,
                  });
                } else {
                  AllData.push({ value: abc._id, label: abc.name });
                }
              });
              if (inputTag && inputTag.length !== 0) {
                let tempo = [];
                inputTag.map((inp) => {
                  let label = find(AllData, { value: inp.subjectId.value });
                  if (label) {
                    tempo.push({
                      subjectId: {
                        value: label.value,
                        label: label.label,
                      },
                      year: inp.year,
                      type: inp.type,
                      depre: inp.depre,
                      depreId: inp.depreId,
                    });
                  } else {
                    tempo = inputTag;
                  }
                });
                setInputTag([...tempo]);
                fecthType(lang, tempo);
              }
            } else {
              masters[MASTER_CODES.SUBJECT].subMasters.map((abc) => {
                if (
                  abc.multiLanguageData &&
                  abc.multiLanguageData.DANISH &&
                  abc.multiLanguageData.DANISH.name
                ) {
                  AllData.push({
                    value: abc._id,
                    label: abc.multiLanguageData.DANISH.name,
                  });
                } else {
                  AllData.push({ value: abc._id, label: abc.name });
                }
              });
              if (inputTag && inputTag.length !== 0) {
                let tempo = [];
                inputTag.map((inp) => {
                  let label = find(AllData, { value: inp.subjectId.value });
                  if (label) {
                    tempo.push({
                      subjectId: {
                        value: label.value,

                        label: label.label,
                      },
                      year: inp.year,
                      type: inp.type,
                      depre: inp.depre,
                      depreId: inp.depreId,
                    });
                  } else {
                    tempo = inputTag;
                  }
                });

                setInputTag([...tempo]);
                fecthType(lang, tempo);
              }
            }
            setloader(false);

            setSubjectMaster([...AllData]);
          }
        }
      }
      setloader(false);
    });
  };
  const fecthType = async (lang = "EN", temArray) => {
    setloader(true);

    let request = { ...FETCH_TYPE.paginate };
    request.obj = {};
    await UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        if (res.data && res.data.length) {
          let Alldata = [];
          if (lang === "EN") {
            res.data.forEach((val) => {
              if (
                val.multiLanguageData &&
                val.multiLanguageData.EN &&
                val.multiLanguageData.EN.name
              ) {
                Alldata.push({
                  value: val._id,
                  label: val.multiLanguageData.EN.name,
                });
              } else {
                Alldata.push({ value: val._id, label: val.name });
              }
            });
            if (temArray && temArray.length !== 0) {
              let tempo = [];
              temArray.map((inp) => {
                let label = find(Alldata, { value: inp.type.value });
                if (label) {
                  tempo.push({
                    subjectId: inp.subjectId,
                    year: inp.year,
                    type: {
                      value: label.value,
                      label: label.label,
                    },
                    depre: inp.depre,
                    depreId: inp.depreId,
                  });
                } else {
                  tempo = temArray;
                }
              });
              setInputTag([...tempo]);
            }
          } else {
            res.data.forEach((val) => {
              if (
                val.multiLanguageData &&
                val.multiLanguageData.DANISH &&
                val.multiLanguageData.DANISH.name
              ) {
                Alldata.push({
                  value: val._id,
                  label: val.multiLanguageData.DANISH.name,
                });
              } else {
                Alldata.push({ value: val._id, label: val.name });
              }
            });

            if (temArray && temArray.length !== 0) {
              let tempo = [];
              temArray.map((inp) => {
                let label = find(Alldata, { value: inp.type.value });
                if (label) {
                  tempo.push({
                    subjectId: inp.subjectId,
                    year: inp.year,
                    type: {
                      value: label.value,
                      label: label.label,
                    },
                    depre: inp.depre,
                    depreId: inp.depreId,
                  });
                } else {
                  tempo = temArray;
                }
              });
              setInputTag([...tempo]);
            }
          }

          setTypeList([...Alldata]);
        }
      }
      setloader(false);
    });
  };

  const fetch = (id) => {
    let request = { ...API_SPECIALIZATION.view };
    request.url = request.url + id;
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        let { name, subjects, multiLanguageData, improvementsData } = res.data;
        setValue("name", name);
        if (improvementsData && improvementsData.length) {
          let tempImp = [];
          improvementsData.map((abc, index) => {
            tempImp.push({
              subjectId: {
                value:
                  abc.subjectId && abc.subjectId._id ? abc.subjectId._id : "",
                label:
                  abc.subjectId &&
                  abc.subjectId.multiLanguageData &&
                  abc.subjectId.multiLanguageData.EN.name
                    ? abc.subjectId.multiLanguageData.EN.name
                    : "",
              },
              year: abc.year,
              type: {
                value:
                  abc.improvementType && abc.improvementType._id
                    ? abc.improvementType._id
                    : "",
                label:
                  abc.improvementType &&
                  abc.improvementType.multiLanguageData.EN.name
                    ? abc.improvementType.name
                    : "",
              },
              depre:
                abc.depreciationModel && abc.depreciationModel.name
                  ? abc.depreciationModel.name
                  : "",
              depreId:
                abc.depreciationModel && abc.depreciationModel._id
                  ? abc.depreciationModel._id
                  : "",
            });
          });

          setInputTag([...tempImp]);
          setValue("subjects", [improvementsData[0]._id]);
        }
        // setValue("subjects", subjects)
        if (!multiLanguageData) multiLanguageData = {};
        multiLanguageData[defaultLanguage] = {
          name: name,
        };
        setMultiLanguageData(multiLanguageData);
      } else {
        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
      }
    });
  };
  const onSubmit = (data) => {
    if (data !== "") {
      delete data.subjects;
      let request = edit
        ? { ...API_SPECIALIZATION.edit }
        : { ...API_SPECIALIZATION.createNew };
      request.url = edit ? request.url + edit : request.url;
      request.obj = { ...data };
      request.obj.multiLanguageData = multiLanguageData;
      let temp = [];
      let valid = true;

      if (inputTag && inputTag.length) {
        inputTag.map((abc) => {
          if (!abc.subjectId) {
            valid = false;
          }
          if (!abc.type) {
            valid = false;
          }
          if (Number(abc.year) !== 0 && !abc.year) {
            valid = false;
          }
          if (!abc.depreId) {
            valid = false;
          }
        });
        if (!valid) {
          toast.error("Please select all Subject, Type and year");
          return;
        } else {
          inputTag.map((abc) => {
            temp.push({
              improvementType: abc.type.value,
              subjectId: abc.subjectId.value,
              year: abc.year,
              depreciationModel: abc.depreId,
            });
          });
        }
      } else {
        toast.error("Please select Subject, Type and year");
        return;
      }

      if (props.parentId) request.obj.parentId = props.parentId;
      request.obj.improvementsData = [...temp];
      UtilService.callApi(request, function (res) {
        if (
          res &&
          res.data &&
          (res.code === API_STATUS.CREATED || res.code === API_STATUS.OK)
        ) {
          toggle8();
          props.fetch();
          setValue("subjects", []);
          setInputTag([
            { subjectId: "", year: "", type: "", depre: "", depreId: "" },
          ]);
          toast.success(res.message);
        } else {
          toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
        }
      });
    } else {
      errors.showMessages();
    }
  };

  return (
    <Fragment>
      <Button className="m-l-10" color="primary" onClick={toggle8}>
        {props.title}
      </Button>
      <Modal isOpen={modal8} toggle={toggle8} size="lg" centered={true}>
        <ModalHeader toggle={toggle8}>
          {edit ? "Edit Theme" : "Add Theme"}
        </ModalHeader>
        <ModalBody>
          <form
            className="form theme-form add-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {loader && (
              <div className={`loader-wrapperTest`}>
                <div className="loader">
                  <div className="whirly-loader"> </div>
                </div>
              </div>
            )}

            <MultiLanguage
              setSelectedLanguage={(lang) => {
                setSelectedLanguage(lang);
                if (!flag) {
                  fetchMasters(lang);
                } else {
                  setFlag(false);
                }
              }}
              setDefaultLanguage={(lang) => setDefaultLanguage(lang)}
              defaultLanguage={defaultLanguage}
              selectedLanguage={selectedLanguage}
            />
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Name <span className="color-red m-l-5">*</span>
              </label>
              <div className="col-sm-9 col-md-10">
                <input
                  value={
                    multiLanguageData &&
                    multiLanguageData[selectedLanguage] &&
                    multiLanguageData[selectedLanguage].name
                      ? multiLanguageData[selectedLanguage].name
                      : ""
                  }
                  className="form-control"
                  onChange={(e) => {
                    let var1 = { name: e.target.value };
                    let val = {
                      [selectedLanguage]:
                        multiLanguageData && multiLanguageData[selectedLanguage]
                          ? { ...multiLanguageData[selectedLanguage], ...var1 }
                          : var1,
                    };
                    setMultiLanguageData({ ...multiLanguageData, ...val });
                    setValue(
                      "code",
                      e.target.value.split(" ").join("").toUpperCase()
                    );
                    setValue("name", e.target.value);
                  
                
                  }}
                  type="text"
                  placeholder="Enter Theme Name"
                />
                <span className="color-red">
                  {errors.name &&
                    "Please enter theme name for english language."}
                </span>
              </div>
            </div>

            {inputTag &&
              inputTag.length &&
              inputTag.map((input, index) => {
                return (
                  <>
                    <div className="form-group row b-shadow align-items-center">
                      <div className="col-md-11">
                        <div className="row">
                          <label className="col-sm-3 col-md-3 col-form-label">
                            <Select
                              onChange={(e) => {
                                if (e) {
                                  let tem = inputTag;
                                  tem[index].subjectId = e;
                                  setInputTag([...tem]);
                                  setValue("subjects", e);
                                } else setValue("subjects", []);
                              }}
                              value={
                                input.subjectId
                                  ? input.subjectId.value
                                    ? input.subjectId
                                    : null
                                  : null
                              }
                              options={SubjectMaster}
                              placeholder="Select Subject"
                              styles={SELECT_STYLE}
                            />
                          </label>
                          <label className="col-sm-3 col-md-3 col-form-label">
                            <Select
                              onChange={(e) => {
                                if (e) {
                                  let tem = inputTag;
                                  tem[index].type = e;
                                  setInputTag([...tem]);
                                } else setValue("subjects", []);
                              }}
                              value={
                                input.type
                                  ? input.type.value
                                    ? input.type
                                    : null
                                  : null
                              }
                              options={typeList}
                              placeholder="Select Type"
                              styles={SELECT_STYLE}
                            />
                          </label>
                          <label className="col-sm-3 col-md-3 col-form-label">
                            <input
                              onBlur={(e) => {
                                let request = {
                                  ...API_DEPRECIATION_MODEL.paginate,
                                  fromTheme: true,
                                };
                                request.obj = {
                                  year: Number(e.target.value),
                                };

                                UtilService.callApi(request, function (res) {
                                  if (
                                    res &&
                                    res.data &&
                                    res.code === API_STATUS.OK
                                  ) {
                                    let tem = inputTag;
                                    tem[index].depre = res.data.name;
                                    tem[index].depreId = res.data._id;
                                    setInputTag([...tem]);
                                  } else {
                                    let tem = inputTag;
                                    tem[index].year = "";
                                    setInputTag([...tem]);
                                    toast.error(
                                      res && res.message
                                        ? res.message
                                        : DEFAULT_API_ERROR
                                    );
                                  }
                                });
                              }}
                              value={input.year}
                              className="form-control"
                              onChange={(e) => {
                                let tem = inputTag;
                                tem[index].year = e.target.value;
                                setInputTag([...tem]);
                              }}
                              type="number"
                              placeholder="Enter Year"
                            />
                          </label>
                          <label className="col-sm-3 col-md-3 col-form-label">
                            <input
                              value={input.depre}
                              className="form-control"
                              disabled
                              type="text"
                              placeholder="Depreciation Model"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="d-flex">
                          {inputTag.length - 1 === index && (
                            <span
                              onClick={() => {
                                let temp = inputTag;
                                temp[index + 1] = {
                                  subjectId: "",
                                  year: "",
                                  type: "",
                                  depre: "",
                                  depreId: "",
                                };
                                setInputTag([...temp]);
                              }}
                            >
                              <img
                                style={{ cursor: "pointer", width: "12px" }}
                                src={require("./plus.svg")}
                              />
                            </span>
                          )}

                          {inputTag.length > 1 && (
                            <span
                              onClick={() => {
                                let temp = inputTag;
                                temp.splice(index, 1);
                                setInputTag([...temp]);
                              }}
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "12px",
                                  marginLeft: "10px",
                                }}
                                src={require("./minus.svg")}
                              />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            Save
          </Button>
          <Button outline={true} color="primary" onClick={toggle8}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Upsert;
