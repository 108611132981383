import React, { Fragment } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useForm from "react-hook-form";
import moment from "moment";
import RcPicker from "../../components/common/RcPicker/RcPicker";

const AmountModal = (props) => {
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  });
  const { title = "", approveAmount } = props;

  const expirationDateChange = (date) => {
    const expirationDate = moment(date).format("YYYY/MM/DD");
    setValue("expirationDate", expirationDate);
  };

  const onSubmit = (data) => {
    approveAmount(data);
  };

  return (
    <Fragment>
    <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{title}</ModalHeader>      
        <ModalBody>
          <div className=" style-1 faq-accordion">
            <div className="card">
              <div className="card-header">
                <div className="">
                  <div>
                    <div className="position-relative d-flex align-items-center mb-3">
                      <input
                        name="amount"
                        className="form-control"
                        type="text"
                        placeholder="Enter amount for board"
                        ref={register({
                          required: true,
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Amount must contain only numbers",
                          },
                        })}
                      />
                      <div
                        className="position-absolute right-0"
                        style={{
                          right: "10px",
                          color: "#878787",
                          paddingLeft: "10px",
                          borderLeft: "1px solid #878787",
                        }}
                      >
                        DK
                      </div>
                    </div>

                    <span className="color-red">
                      {errors.amount &&
                        (errors.amount.message
                          ? errors.amount.message
                          : "Please enter amount.")}
                    </span>
                  </div>
                  <div>
                    <RcPicker
                      callBackDate={expirationDateChange}
                      ref={register(
                        { name: "expirationDate" },
                        {
                          required: true,
                          message: "Expiration date is required",
                        }
                      )}
                      placeholder="Select expiration date for board"
                      minDate={new Date()}
                    />
                    <span className="color-red">
                      {errors.expirationDate &&
                        (errors.expirationDate.ref.value
                          ? errors.expirationDate.message
                          : "Please select expiration date.")}
                    </span>
                  </div>
                  <div className="text-right">
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-primary d-inline-block mt-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AmountModal;
