import React, { Fragment, useEffect, useState } from 'react';

const Loader = () => {
    const [show, setShow] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShow(false)
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    return (
        <Fragment>
            <div className={`loader-wrapper ${show ? '' : 'loderhide'}`} >
                <div className="loader">
                    <div className="whirly-loader"> </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Loader;