import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const ToolTipCommon = (props) => {
  const { children, title, className = '' } = props
  const tooltip = (
    <Tooltip id="tooltip" placement="top">
      {title}
    </Tooltip>
  )
  return (
    <>
      {title ? (
        <OverlayTrigger left="0" placement="top" overlay={tooltip}>
          <div
            className={className}
            onClick={() => props.onClick && props.onClick()}
          >
            {children}
          </div>
        </OverlayTrigger>
      ) : (
        <div onClick={() => props.onClick && props.onClick()}>{children}</div>
      )}
    </>
  )
}

export default ToolTipCommon
