import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useForm from "react-hook-form";
import UtilService from "../../services/common.service";
import { ModalFooter } from "react-bootstrap";
import { CommonError } from "../../validation/commonValidation";
import { API_CHECKLIST, API_MASTER } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  MASTER_CODES,
} from "../../constant/common";
import { toast } from "react-toastify";
import map from "lodash/map";
import MultiLanguage from "../../components/common/MultiLanguage";

const AddAndEditChecklist = (props) => {
  const { editData } = props;
  const { editOrAdd, fetchListing, onClose } = props;
  const [attributesData, setAttributesData] = useState({});
  const [selectedType, setSelectedType] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState()
    const [selectedLanguage, setSelectedLanguage] = useState()
    const [multiLanguageData, setMultiLanguageData] = useState()
  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  });

  const setValuesManually = (editData) => {
    let { name, description, type,multilanguageData } = editData;

    setValue("name",name);
    setValue("description",description);
    setSelectedLanguage(defaultLanguage)
    setMultiLanguageData(multilanguageData)
    setSelectedType(String(type));
  };

  useEffect(() => {
    setMounted(true);
    setSelectedLanguage(defaultLanguage)
    register({ name: "description" }, { required: true ,message: "description is required!" });
    register({ name: "name" }, { required: true ,message: "name is required!" });
  }, []);

  useEffect(() => {
    if (editData && mounted) {
      setValuesManually(editData);
    }
  }, [editData, mounted]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const fetch = () => {
    let request = { ...API_MASTER.listByCode };
    request.obj = {
      masters: [MASTER_CODES.ATTRIBUTE],
      include: ["subMasters"],
    };
    UtilService.callApi(request, ({ code, data, message }) => {

      if (code === API_STATUS.OK) {
   
        const attributes = data?.ATTRIBUTE?.subMasters;
        setAttributesData(attributes);
      } else {
        toast.error(message ? message : DEFAULT_API_ERROR);
      }
    });
    // setData(props.id);
  };

  const handleTypeChange = (e) => {
    const { value } = e.target;
    setSelectedType(value);
  };

  const onSubmit = (data) => {
    props.onClose();
    
    if (data !== "") {
      if (editOrAdd === "add") {
        let request = { ...API_CHECKLIST.create };
        request.obj = { ...data, type: selectedType || "1" };
        request.obj.multilanguageData = {multiLanguageData, ...selectedLanguage }
        if (props.parentId) request.obj.parentId = props.parentId

        UtilService.callApi(request, ({ data, message, code }) => {
          if (code === API_STATUS.OK) {
            
            toast.success(message ? message : "CheckList created successfully");
            fetchListing();
            setTimeout(() => {
              onClose();
            }, 3000);
          } else {
            toast.error(message ? message : DEFAULT_API_ERROR);
          }
        });
      } else {
      
        delete data.type;
        let request = { ...API_CHECKLIST.update };
        request.obj = { ...data };
        
        if (selectedLanguage === "EN") {

          request.obj= {
           ...request.obj,
           "multilanguageData.EN" : { 
            name:multiLanguageData.EN.name,
            
            description:multiLanguageData.EN.description}
          };
        }
       
        if (selectedLanguage === "DANISH") {

          request.obj= {
           ...request.obj,
           "multilanguageData.DANISH" : { 
            name:multiLanguageData.DANISH.name,
            
            description:multiLanguageData.DANISH.description}
          };
   
        }
    
        request.url = request.url + editData._id;
        if (props.parentId) request.obj.parentId = props.parentId

       // request.obj = { ...data };
        UtilService.callApi(request, ({ data, message, code }) => {
          if (code === API_STATUS.OK) {
            toast.success(message ? message : "CheckList updated successfully");
            fetchListing();
            setTimeout(() => {
              onClose();
            }, 3000);
          } else {
            toast.error(message ? message : DEFAULT_API_ERROR);
          }
        });
      }
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>
          {editOrAdd === "add" ? "Add Checklist" : "Edit Checklist"}
        </ModalHeader>
        <ModalBody>
          <div className="form theme-form add-form">
            <MultiLanguage
              setSelectedLanguage={(lang) => setSelectedLanguage(lang)}
              setDefaultLanguage={(lang) => setDefaultLanguage(lang)}
              defaultLanguage={defaultLanguage}
              selectedLanguage={selectedLanguage}
            />
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Select checklist<span className="color-red m-l-5">*</span>
              </label>
              <div className="col-sm-9 col-md-10">
                <select
                  name="type"
                  class="drop-down-input"
                  ref={register("type")}
                  onChange={handleTypeChange}
                  disabled={editOrAdd === "add" ? false : true}
                >
                  <option value="1" selected={selectedType === "1"}>
                    General checklist
                  </option>
                  <option value="2" selected={selectedType === "2"}>
                    Based on attribute
                  </option>
                </select>
              </div>
            </div>
            {selectedType === "2" && (
              <div className="form-group row">
                <label className="col-sm-3 col-md-2 col-form-label">
                  Select attribute<span className="color-red m-l-5">*</span>
                </label>
                <div className="col-sm-9 col-md-10">
                  <select
                    name="attrId"
                    class="drop-down-input"
                    ref={register("attrId")}
                  >
                    {map(attributesData, (attr) => (
                      <option
                        option
                        value={attr._id}
                        selected={attr._id === editData?.attrId?._id}
                      >
                        {" "}
                        {attr.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Name <span className="color-red m-l-5">*</span>
              </label>
              <div className="col-sm-9 col-md-10">
                <input
                  value={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].name ? multiLanguageData[selectedLanguage].name : ""}
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter Name"
                  onChange={e => {
                    let var1 = { "name": e.target.value }
                    let val = {
                        [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
                    }
                    setMultiLanguageData({ ...multiLanguageData, ...val })
                        setValue("code", e.target.value.split(" ").join("").toUpperCase())
                        setValue("name", e.target.value)
                    
                }
                }
               
                />
                                <span className="color-red">{errors.name && 'Please enter name for english language.'}</span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Description <span className="color-red m-l-5">*</span>
              </label>
              <div className="col-sm-9 col-md-10">
                <textarea
                  name="description"
                  className="form-control"
                  type="text"
                  placeholder="Description"
                  value={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].description ? multiLanguageData[selectedLanguage].description : ""}
                  onChange={e => {
                      let var1 = { "description": e.target.value }
                      let val = {
                          [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
                      }
                      setMultiLanguageData({ ...multiLanguageData, ...val })
                          setValue("description", e.target.value)
                      
                  }
                  }
                  // ref={register({
                  //   required: {
                  //     value: true,
                  //     message: "description is required!",
                  //   },
                  // })}
                ></textarea>
                                                <span className="color-red">{errors.description && 'Please enter description for english language.'}</span>

                <span className="color-red"></span>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
            Save
          </button>
          <button className="btn btn-outline-primary" onClick={props.onClose}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddAndEditChecklist;
