import React, { Fragment } from 'react';
import { Menu } from 'react-feather';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import { API_FAQS } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
import List from "../common/List/index";

const DragHandle = SortableHandle(() => {
    return <Menu style={{ width: 24, height: 24, padding: 3, color: '#000', margin: '0 2px', }} />;
});

const SortableItem = SortableElement(({ edit, value, sequence, fetch }) => {
    return <List edit={edit} DragHandle={DragHandle} id={value._id} question={value.question} fetch={fetch} count={sequence}>
        {value.answer}
    </List>
});

const SortableList = SortableContainer(({ edit, items, onSortEnd, fetch }) => {
    return (
        <div onSortEnd={onSortEnd}>
            {items.map((d, i) =>
                <SortableItem key={d.sequence} sequence={i + 1} fetch={fetch} edit={edit} index={d.sequence} value={d} />
            )}
        </div>
    );
});

const FaqCard = (props) => {

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return
        let updatedSequenceId = props.listfaq.filter((f) => f.sequence === oldIndex)[0]._id
        let request = { ...API_FAQS.updateSequence };
        request.url = request.url + updatedSequenceId
        request.obj = { sequence: newIndex };
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                props.fetch()
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    };
    return (
        <Fragment>
            <div className="faq-title">
            </div>
            <div>
                {props.listfaq && props.listfaq.length ? <SortableList useWindowAsScrollContainer={true} useDragHandle={true} fetch={props.fetch} edit={props.edit} items={props.listfaq} onSortEnd={onSortEnd} />
                    : "No FAQs available"}
            </div>
        </Fragment>
    );
};
export default FaqCard;