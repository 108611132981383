import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Mail, Eye } from "react-feather";
import { Helmet } from "react-helmet";
import ActiveDeactive from "../../components/common/ActiveDeactive";
import Datatable from "../../components/common/DataTable";
import ImageByGender from "../../components/common/ImageByGender";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import { API_USER } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_PAGE_SIZE,
  MODEL,
  PATIENT_STATUS,
  PATIENT_STATUS_TAB,
  USER_TYPE,
  API_URL,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import ToolTipCommon from "../../components/common/ToolTip";
import Details from "./view";

const Patient = (props) => {
  const locationStatus =
    props.location.data && props.location.data.status
      ? props.location.data.status
      : null;
  const [activeTab, setActiveTab] = useState(
    locationStatus ? locationStatus : PATIENT_STATUS.ACTIVE
  );
  const [masterData, setMasterData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [popupDetail, setPopupDetail] = useState(false);

  const initialApiFilter = {
    filter: {
      filter: {
        isActive: true,
        type: USER_TYPE.PATIENT,
      },
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      search: {
        keys: ["name", "mobiles.mobile", "emails.email"],
        keyword: "",
      },
    },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = (limit) => {
    let filter = { ...filters, ...limit };
    setFilters(filter);
  };
  const handleSort = (sort) => {
    let filter = filters;
    filter.page = 1;
    filter.sort = sort;
    setFilters(filter);
    fetch();
  };
  const search = (searchVal) => {
    let filter = filters;
    filter.search.keyword = searchVal;
    filter.page = 1;
    setFilters(filter);
    fetch();
  };
  // fetch data
  const fetch = () => {
    let request = { ...API_USER.paginate };
    request.obj = filters;
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        setMasterData(res?.data?.list);
        setTotalPages(Math.ceil(res.data.count / filters.limit));
      } else {
        setMasterData([]);
        setTotalPages(1);
      }
    });
  };
  const handleTabChange = (id) => {
    setActiveTab(id);
    let key = PATIENT_STATUS_TAB.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.page = 1;
    filterToSet.filter[key.key] = key.value;
    setFilters(filterToSet);
    fetch();
  };
  const columns = [
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Profile Picture",
      id: "image",
      accessor: (row) => (
        <ImageByGender
          ImageClass="img-50 img-fluid height-50-px rounded-circle"
          src={row.image}
          gender={row.gender}
        />
      ),
      sortable: false,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Mobile No",
      id: "mobiles",
      Cell: (row) => {
        return row.original.mobiles[0]
          ? (row.original.mobiles[0].countryCode
            ? row.original.mobiles[0].countryCode
            : "") +
          " " +
          row.original.mobiles[0].mobile
          : "";
      },
      sortable: false,
      width: 150,
    },
    {
      Header: "Email Address",
      id: "emails",
      Cell: ({ original }) => {
        return original?.emails[0].email ? original?.emails[0]?.email : "";
      },
      sortable: false,
      width: 180,
    },
    {
      Header: "Registration Date",
      id: "createdAt",
      Cell: (row) => {
        return (
          <div>
            <div>{CommonService.getDate(row.original.createdAt)}</div>
          </div>
        );
      },
    },
    {
      Header: "Action",
      id: "isActive",
      Cell: (row) => {
        const setDetails = () => {
          setPopupDetail(row.original);
        };
        return (
          <div className="action-act">
            {/* <ToolTipCommon onClick={() => {
                            let arr = []
                            setImagePopup(arr)
                        }} title="View Image">
                            <FeatherImage className="actions-icon action-primary" style={{ width: 22 }} />
                        </ToolTipCommon> */}
            <ActiveDeactive
              message={"Are you sure you would like to activate this user?"}
              fetch={fetch}
              id={row.original._id}
              status={row.original.isActive}
              model={MODEL.USER}
              activeStatus={"User deactivated"}
              deactivateStatus={"User activated"}
            />
            {/* {row.original.isActive && (
              <ToolTipCommon title="Send Property Profile Over Email">
                <Mail
                  className="actions-icon action-primary"
                  style={{ width: 22 }}
                />
              </ToolTipCommon>
            )} */}
            <ToolTipCommon title="View Details">
              <Eye
                className="actions-icon action-primary"
                style={{ width: 22 }}
                onClick={setDetails}
              />
            </ToolTipCommon>
          </div>
        );
      },
      sortable: false,
      style: {
        textAlign: "center",
      },
    },
  ];
  return (
    <Fragment>
      <Helmet>
        <title>Simple User</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
              <div>
                <h5>Simple User</h5>
                <span></span>
              </div>
              <div className="d-flex flex-wrap align-items-right">
                <div className="m-r-10">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by name, mobile no, and email address."}
                  />
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={PATIENT_STATUS_TAB}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {PATIENT_STATUS_TAB.map((d, i) => {
                return activeTab === d.id ? (
                  <HRightTab key={i} tabId={d.id}>
                    <div className="search-page profile-page">
                      <div className="PageBodyWrapper">
                        <div className="pageBodyContent product-page-main">
                          <div className="table-responsive support-table">
                            <Datatable
                              columns={columns}
                              myData={masterData}
                              pagination={true}
                              pageSize={filters.limit}
                              page={filters.page}
                              class="-striped -highlight"
                              handleChange={handleFilterChange}
                              pages={totalPages}
                              handleSort={handleSort}
                              fetch={fetch}
                              model={MODEL.USER}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </HRightTab>
                ) : null;
              })}
            </Navigation>
          </div>
        </div>
      </div>
      {popupDetail && (
        <Details id={popupDetail} onClose={() => setPopupDetail(false)} />
      )}
    </Fragment>
  );
};

export default withRouter(Patient);
