import React, { Fragment, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Upsert from '../../components/common/Aboutus/upsert';
import { API_TNC } from '../../constant/apiConstants';
import UtilService from '../../services/common.service';
import { API_STATUS } from '../../constant/common';

const AboutUs = (props) => {
    const [edit, setEditKey] = useState();

    const initialApiFilter = { // filter to send to api
        filter: {
            isDeleted: false,
            type: 2
        },
        page: 1,
        sort: { "sequence": "ASC" },
        search: {
            keys: [],
            keyword: ""
        }
    }
    const fetch = () => { // api data 
        let request = { ...API_TNC.paginate };
        // request.url = request.url + "60544198e2574d08aef46953"

        request.obj = initialApiFilter
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setEditKey(res.data?.list[0])
            }
            else {
                setEditKey([])
            }
        })
    }
    useEffect(() => {
        fetch()
    }, [])

    return (
        <Fragment>
            <Helmet>
                <title>About us</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader d-flex flex-wrap justify-content-between align-items-center">
                            <div>
                                <h5>About Us</h5>
                                <span></span>
                            </div>
                        </div>
                        <div className="default-according style-1 faq-accordion">
                            <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={"Add FAQ"} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AboutUs;