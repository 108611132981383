import React, { useState } from "react";
import ToolTipCommon from "../ToolTip";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import useForm from 'react-hook-form';
import { CommonError } from "../../../validation/commonValidation";
import { ASSURER_MANAGEMENT } from "../../../constant/common";

const Decline = (props) => {
  const { register, handleSubmit, errors, setValue } = useForm({ mode: 'onChange' });
  const {id,handleApproveDecline,activeTabId,handleApproveExtraJobs,} = props

  const [show, showModal] = useState(false);

  const onClose = () => {
    showModal(false);
  };
  const handleDecline = () => {
    showModal(true)
  }
  const onSubmit = (data) =>{
    if(activeTabId == ASSURER_MANAGEMENT.ASSURER_EXTRA_JOB_REQUEST){
    return  handleApproveExtraJobs(id, data, onClose, "Decline")
    }else{
    return   handleApproveDecline(id, data, onClose, "Decline");

    }

    }

  return (
    <>
      <ToolTipCommon onClick={() => handleDecline()} title="Decline">
        <a className="d-flex align-items-center text-decline bg-danger py-1 px-2 cursor-pointer">
          Decline
        </a>
      </ToolTipCommon>

      <Modal isOpen={show} toggle={onClose} size="lg" centered={true}>
        <ModalHeader toggle={onClose}>{"Decline"}</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label for="exampleInputEmail1">Reason</label>
              <textarea
                name="declineReason"
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                ref={register({ required: { value: true, message: "decline reason is required!" } })}
              ></textarea>
              {Object.keys(errors) ? CommonError(errors?.declineReason?.message) : ""}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onSubmit)}> Save</Button>
          <Button outline={true} color="primary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Decline;
