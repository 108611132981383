import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useForm from "react-hook-form";
import { API_ASSURER_MANAGEMENT } from "../../constant/apiConstants";
import {
  API_STATUS,
  LOCALISATION,
  RANGE_DATE_FORMAT,
} from "../../constant/common";
import UtilService from "../../services/common.service";
import { toast } from "react-toastify";
import Input from "../../widgets/input";

const ExtraJobsModal = (props) => {
  const { title = "", limitAssurerId, extraJobDetail = {} } = props;
  const { assurerFees = {}, appLimitRequest = {} } = extraJobDetail || {};
  
 
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
} = useForm({
    mode: "onChange",
});

  const onSubmit = (values, e) => {
    let request = { ...API_ASSURER_MANAGEMENT.APPROVE_JOBS };
    request.url = `${request.url}/${limitAssurerId}`;
    request.obj = {
      isApproved: true,
      ...values,
    };
    
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        props.fetchList();
        toast.success(message);
        props.onClose();
      } else {
        toast.error(message);
      }
    });
  };

  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="md" centered={true}>
        <ModalHeader toggle={props.onClose}>{title}</ModalHeader>
        <ModalBody>
          <form>
            <div className=" style-1 faq-accordion">
              <div className="card">
                <div className="card-header">
                  <div class="">
                    <div>
                      <div className="grid gap-4">
                        <Input
                          name="noOfJobs"
                          label="No of jobs"
                          mandatory
                          placeholder="No Of Jobs"
                          rest={register({
                            required: {
                              value: true,
                              message: "No Of Jobs is required!",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "only numbers are allowed in price",
                            },
                          })}
                          error={errors.noOfJobs?.message}
                          defaultValue={appLimitRequest?.appLimit}
                        />

                        <Input
                          label="Job Create Price"
                          name="newJob"
                          mandatory
                          placeholder="Enter Job Create Price"
                          onChange={(event) => {
                            
                            setValue("newJob", event.target.value);
                          }}
                          rest={register({
                            required: {
                              value: true,
                              message: "Job create price is required!",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "only numbers are allowed in price",
                            },
                          })}
                          error={errors.newJob?.message}
                          defaultValue={assurerFees?.newJob}
                        />
                        <Input
                          name="acceptJob"
                          label="Approve Job Price"
                          mandatory
                          placeholder="Enter Approve Job Price"
                          rest={register({
                            required: {
                              value: true,
                              message: "Approve job price is required!",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "only numbers are allowed in price",
                            },
                          })}
                          value={watch("acceptJob")}
                          error={errors.acceptJob?.message}
                          defaultValue={assurerFees?.acceptJob}
                        />
                        <Input
                          name="transNo"
                          label="Transaction Number"
                          mandatory
                          placeholder="Enter Transaction Number"
                          rest={register({
                            required: {
                              value: true,
                              message: "Approve job price is required!",
                            },
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "only numbers are allowed in price",
                            },
                          })}
                          error={errors.transNo?.message}
                        />
                      </div>
                    </div>
                    <div className="text-right">
                      <button
                        onClick={handleSubmit(onSubmit)}
                        className="btn btn-primary d-inline-block mt-3"
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ExtraJobsModal;
