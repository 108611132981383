import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { Refresh_Token } from "../constant/apiConstants";
import { API_STATUS, API_URL, DEFAULT_API_ERROR } from "../constant/common";
const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const loaderActivity = (showLoader) => {
  const getLoaderEle = document.querySelectorAll(".loader-wrapper")[0];
  if (showLoader) {
    if (
      getLoaderEle &&
      getLoaderEle.classList &&
      getLoaderEle.classList.contains("loderhide")
    ) {
      getLoaderEle.classList.add("loader-api-call");
      getLoaderEle.classList.remove("loderhide");
    }
  } else {
    if (
      getLoaderEle &&
      getLoaderEle.classList &&
      getLoaderEle.classList.contains("loader-api-call")
    ) {
      getLoaderEle.classList.add("loderhide");
      getLoaderEle.classList.remove("loader-api-call");
    }
  }
};

/**
 * Common Request Middleware for AJAX call
 * config - Headers, Loaders
 */
apiInstance.interceptors.request.use(
  function (config) {
    const header_token =
      localStorage.getItem("token") &&
      JSON.parse(localStorage.getItem("token"));
    config.headers.Authorization = "JWT " + header_token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
/**
 * Common Response Middleware for AJAX call
 * config - Headers, Loaders
 */
apiInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
const callRefreshAPI = (object, cb) => {
  const userData = JSON.parse(localStorage.getItem("user"));
  let request = { ...Refresh_Token.send };
  request.obj = {
    username: userData.emails[0].email,
    refreshToken: userData.refreshToken,
  };
  UtilService.callApi(
    request,
    function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        localStorage.setItem("token", JSON.stringify(res.data.token.jwt));
        UtilService.callApi(object, cb);
      }
    },
    true
  );
};
/**
 * Util Service for API call
 */
const UtilService = {
  callApi(object, cb, isRefreshToken = false) {
    let headers = {
      headers: {
        "Content-Type": "application/json",
        showLoader: object.showLoader ? object.showLoader : undefined,
      },
    };
    if (object.showLoader) {
      loaderActivity(true);
    }
    if (object.method === "get" && !object.get) {
      object.url = object.url + "?t=" + moment().millisecond();
    }
    apiInstance[object.method](object.url, object.obj, headers)
      .then(({ data }) => {
        if (object.showLoader) {
          loaderActivity(false);
        }
        if (data.code === API_STATUS.UN_AUTH_CODE) {
          if (isRefreshToken) {
            localStorage.clear();
            window.location = "/login";
            toast.error(data.message ? data.message : DEFAULT_API_ERROR);
          } else {
            callRefreshAPI(object, cb);
          }
        } else {
          cb(data);
        }
      })
      .catch((error) => {
        if (object.showLoader) {
          loaderActivity(false);
        }
        if (error && error.response && error.response.data) {
          if (error.response.data.code === API_STATUS.UN_AUTH_CODE) {
            if (isRefreshToken) {
              localStorage.clear();
              window.location = "/login";
              toast.error(
                error.response.data.message
                  ? error.response.data.message
                  : DEFAULT_API_ERROR
              );
            } else {
              callRefreshAPI(object, cb);
            }
          } else cb(error.response.data);
        } else if (error) cb(error);
      });
  },
};

export default UtilService;
