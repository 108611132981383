import * as _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import UtilService from '../services/common.service';
import { API_COMMON } from './apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from './common';
const CommonService = {
    displayDate: (date, hour24 = false) => {
        let timeStr = "hh:mm a";
        if (hour24) {
            timeStr = "HH:mm";
        }
        return date ? moment(date).format(`L , ${timeStr}`) : "-";
    },
    getAdviceTime: (time) => {
        let h = Math.floor(time / 60)
        h ? h += " hours" : h = ""
        let m = time - (h * 24)
        m ? m += " minutes" : m = ""
        let str = h + " " + m
        return str
    },
    getDate: (date) => {
        return date ? moment(date).format(`DD/MM/YYYY`) : "";
    },
    getFullDate: (date) => {
        return date ? moment(date).format(`LL`) : "";
    },
    displaySlot: (start, end) => {
        let timeStr = "hh:mm a"
        let startTime = start ? moment(start).format(timeStr) : ""
        let endTime = end ? moment(end).format(timeStr) : ""
        return startTime + " - " + endTime
    },
    displayRangeDate: (start, end) => {
        let startTime = start ? moment(start).format(`DD/MM/YYYY hh:mm a`) : ""
        let endTime = end ? moment(end).format(`DD/MM/YYYY hh:mm a`) : ""
        return startTime + " - " + endTime
    },
    getTime: (date, hour24 = false) => {
        let timeStr = "hh:mm a";
        if (hour24) {
            timeStr = "HH:mm";
        }
        return date ? moment(date).format(`${timeStr}`) : "";
    },
    getKeyList: (data, list, filterData) => {
        let hadFilter = filterData
        Object.keys(data).map((d) => {
            let getFilter = list.filter((f) => {
                return d === f.key
            })[0]
            let getType = getFilter.list.filter((s) => {
                return s.value.toString() === data[d] || s.value === data[d]
            })[0]
            if (getType.type || getType.type === false) {
                hadFilter[d] = getType.type
            }
            else {
                delete hadFilter[d]
            }
        })
        return hadFilter
    },
    removeFile: (file, cb) => {
        let filePath = [];
        if (_.isString(file)) {
            filePath = [file];
        }
        else if (_.isArray(file)) {
            //do nothing
        }
        let request = { ...API_COMMON.removeFile };
        request.obj = {
            paths: filePath
        };
        UtilService.callApi(request, function (res) {
            if (res && res.code === API_STATUS.OK) {
                cb()
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    },
    lowerCaseAllWordsExceptFirstLetters: (word = "") => {
        return word.charAt(0) + word.slice(1).toLowerCase()
    }
}
export default CommonService