import React from "react";
import { CheckCircle, MinusCircle } from "react-feather";
import { toast } from "react-toastify";
import { API_COMMON } from "../../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  DEFAULT_SUCCESS_MESSAGE,
} from "../../../constant/common";
import UtilService from "../../../services/common.service";
import ToolTipCommon from "../ToolTip";

const ActiveDeactive = (props) => {
  const changeStatus = () => {
    const { activeStatus = "", deactivateStatus = "" } = props;
    const message = props.status ? activeStatus : deactivateStatus;

    if (!props.canNotDeactive) {
      let request = { ...API_COMMON.activeDeactive };
      request.obj = {
        id: props.id,
        fieldName: "isActive",
        model: props.model,
        status: !props.status,
      };
      UtilService.callApi(request, function (res) {
        if (res && res.data && res.code === API_STATUS.OK) {
          toast.success(message ? message : res.message);
          props.fetch();
        } else {
          toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
        }
      });
    } else {
      toast.error("Can not deactivate default record.");
    }
  };
  return props.status ? (
    <ToolTipCommon title="Click to deactivate" onClick={changeStatus}>
      <CheckCircle
        className="actions-icon font-success"
        style={{ width: 22 }}
      />
    </ToolTipCommon>
  ) : (
    <ToolTipCommon title="Click to Activate" onClick={changeStatus}>
      <MinusCircle className="actions-icon font-danger" style={{ width: 22 }} />
    </ToolTipCommon>
  );
};
export default ActiveDeactive;
