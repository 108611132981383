import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import UserProfilePhoto from "../../../components/common/userProfilephoto";
import { API_USER } from '../../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../../constant/common';
import { COUNTRY_CODES } from '../../../constant/countryCodes';
import UtilService from '../../../services/common.service';
import Button from "../../common/button/index";

const Detail = () => {
    const userData = JSON.parse(localStorage.getItem("user"))
    const [image, setImage] = useState()
    const { register, handleSubmit, errors, setValue } = useForm({ mode: "onChange" });
    const childRef = useRef();

    useEffect(() => {
        setDefaultValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const setDefaultValues = () => {
        setValue("firstName", userData.firstName)
        setValue("lastName", userData.lastName)
        setValue("email", userData.emails[0].email)
        setValue("dob", userData.dob ? moment(userData.dob).format('YYYY-MM-DD') : "")
        if (userData.mobiles && userData.mobiles[0]) {
            setValue("countryCode", userData.mobiles[0].countryCode)
            setValue("mobile", userData.mobiles[0].mobile)
        }
        else {
            setValue("countryCode", undefined)
            setValue("mobile", undefined)
        }
    }
    const onSubmit = data => {
        if (data !== '') {
            if (image) data.image = image
            let request = { ...API_USER.edit }
            request.url = request.url + userData._id
            request.obj = { ...data };
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    localStorage.setItem("user", JSON.stringify(res.data))
                    toast.success(res.message)
                    childRef.current.onSuccess(res.data.image)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            <form className="form theme-form">
                <div className="">
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">Profile Photo</label>
                        <div className="col-sm-9 col-md-5">
                            <UserProfilePhoto ref={childRef} setImage={(url) => setImage(url)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">First Name <span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="firstName" className="form-control"
                                ref={register({
                                    required: true, pattern: {
                                        value: /^[a-zA-Z]*$/,
                                        message: "First Name must contain only alphabetical characters."
                                    }
                                })} type="text" placeholder="Enter First Name" />

                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.firstName && (errors.firstName.message ? errors.firstName.message : 'Please enter first name.')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">Last Name <span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="lastName" className="form-control"
                                ref={register({
                                    required: true, pattern: {
                                        value: /^[a-zA-Z]*$/,
                                        message: "Last Name must contain only alphabetical characters."
                                    }
                                })} type="text" placeholder="Enter Last Name" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.lastName && (errors.lastName.message ? errors.lastName.message : 'Please enter last name.')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">Email</label>
                        <div className="col-sm-9 col-md-3">
                            <input name="email" className="form-control"
                                ref={register({
                                    required: true, pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: "Email address is not valid."
                                    }
                                })} type="text" placeholder="Enter Email Address" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.email && (errors.email.message ? errors.email.message : 'Please enter email address.')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">Phone Number</label>
                        <div className="col-sm-9 col-md-3 d-flex">
                            <select name={"countryCode"} ref={register({ required: true })} className="" style={{ width: "30%" }}>
                                {COUNTRY_CODES.map((o, i) => <option key={i} selected={(userData.mobiles && userData.mobiles[0]) && (userData.mobiles[0].countryCode === o)} value={o}>
                                    {o}
                                </option>)}
                            </select>
                            <input style={{ width: "70%" }} name="mobile" className="form-control"
                                ref={register({
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: "Mobile numbers must contain 10 digit phone number."
                                    }
                                })} type="text" placeholder="Enter Mobile number" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.phone && (errors.phone.message ? errors.phone.message : 'Please enter phone number.')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">DOB</label>
                        <div className="col-sm-9 col-md-3">
                            <input ref={register} name="dob" className="form-control digits" type="date" placeholder="Select DOB" />
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="">
                        <Button onClick={handleSubmit(onSubmit)} color="primary mr-1">Submit</Button>
                        <Button onClick={setDefaultValues} color="light">Reset</Button>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}
export default Detail;