import React, { Fragment, useRef, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Helmet } from 'react-helmet';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import Logo from '../../components/common/logo';
import { API_USER } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
const ResetPassword = (props) => {
    const [showPassword, setShowPassword] = useState({ show1: false, show2: false })
    const { register, handleSubmit, errors, watch, reset } = useForm({ mode: "onChange" }); // initialize the hook
    const password = useRef({});
    password.current = watch("password", "");
    const onSubmit = data => {
        if (data !== '') {
            // data.username = JSON.parse(localStorage.getItem("user")).emails[0].email
            delete data.pass
            let obj = {
                email: props.location.state.email,
                otp: props.location.state.otp
            }
            let request = { ...API_USER.resetPassword };
            request.obj = { ...data, ...obj };
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    toast.success(res.message)
                    props.history.push('/login')
                    reset()
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };

    return (
        <Fragment>
            <Helmet>
                <title>Reset Password</title>
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <Logo logoSize={'md'} />
                                            <h3 className={'p-t-15'}>
                                                {"Domus"}
                                            </h3>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Reset Password</h4>
                                                    {/* <h6> </h6> */}
                                                </div>
                                                <form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group form-row d-flex flex-column">
                                                        <label className="col-form-label pt-0">New Password</label>
                                                        <div className="d-flex">
                                                            <input name="password" className="form-control" type={showPassword.show1 ? "text" : "password"} placeholder="New Password"
                                                                ref={register({
                                                                    required: true, pattern: {
                                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{8,}$/,
                                                                        message: "Password Must be combination of uppercase, lowercase and a special character with 8 or more character"
                                                                    }
                                                                })} />
                                                            {showPassword.show1 ? <EyeOff className="eye-pass" onClick={() => setShowPassword({ ...showPassword, show1: !showPassword.show1 })} /> : <Eye className="eye-pass" onClick={() => setShowPassword({ ...showPassword, show1: !showPassword.show1 })} />}
                                                        </div>
                                                        <span className="color-red">{errors.password && (errors.password.message ? errors.password.message : 'Please enter password.')}</span>
                                                    </div>
                                                    <div className="form-group form-row d-flex flex-column">
                                                        <label className="col-form-label">Confirm New Password</label>
                                                        <div className="d-flex">
                                                            <input ref={register({
                                                                required: true,
                                                                validate: value =>
                                                                    value === password.current || "Passwords doesn't match."
                                                            })} name="pass" className="form-control" type={showPassword.show2 ? "text" : "password"} placeholder="Confirm Password" />
                                                            {showPassword.show2 ? <EyeOff className="eye-pass" onClick={() => setShowPassword({ ...showPassword, show2: !showPassword.show2 })} /> : <Eye className="eye-pass" onClick={() => setShowPassword({ ...showPassword, show2: !showPassword.show2 })} />}
                                                        </div>
                                                        <span className="color-red">{errors.pass && (errors.pass.message ? errors.pass.message : 'Please enter password.')}</span>
                                                    </div>


                                                    <div className="form-grouform-rowp form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit">Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </Fragment >
    );
};

export default withRouter(ResetPassword)
