import React, { Fragment, useRef, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import useForm from "react-hook-form";
import { toast } from "react-toastify";
import { API_USER } from "../../../constant/apiConstants";
import { API_STATUS, DEFAULT_API_ERROR } from "../../../constant/common";
import UtilService from "../../../services/common.service";
import Button from "../../common/button/index";

const ChangePassword = (props) => {
  const { register, handleSubmit, errors, watch, reset } = useForm({
    mode: "onChange",
  }); // initialize the hook
  const [showPassword, setShowPassword] = useState({
    show1: false,
    show2: false,
    show3: false,
  });
  const newPassword = useRef({});
  const currentPassword = useRef({});
  newPassword.current = watch("newPassword", "");
  currentPassword.current = watch("currentPassword", "");

  const onSubmit = (data) => {
    if (data !== "") {
      delete data.pass;
      let request = { ...API_USER.changePassword };
      request.obj = { ...data };
      UtilService.callApi(request, function (res) {
        if (res && res.data && res.code === API_STATUS.OK) {
          toast.success(res.message);
          reset();
        } else {
          toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
        }
      });
    } else {
      errors.showMessages();
    }
  };
  return (
    <Fragment>
      <form className="form theme-form">
        <div className="">
          <div className="form-group row">
            <label className="col-sm-3 col-md-2 col-form-label">
              Current Password
            </label>
            <div className="col-sm-9 col-md-3">
              <input
                name="currentPassword"
                ref={register({
                  required: true,
                })}
                className="form-control"
                type={showPassword.show1 ? "text" : "password"}
                placeholder="Current Password"
              />
              {showPassword.show1 ? (
                <EyeOff
                  className="eye-pass eye-profile"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      show1: !showPassword.show1,
                    })
                  }
                />
              ) : (
                <Eye
                  className="eye-pass eye-profile"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      show1: !showPassword.show1,
                    })
                  }
                />
              )}
              <span className="color-red">
                {errors.currentPassword && "Please enter current password."}
              </span>
            </div>
          </div>
          <div className="form-group row d-flex">
            <label className="col-sm-3 col-md-2 col-form-label">
              New Password
            </label>
            <div className="col-sm-9 col-md-3">
              <input
                name="newPassword"
                className="form-control"
                type={showPassword.show2 ? "text" : "password"}
                placeholder="New Password"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{8,}$/,
                    message:
                      "Password Must be combination of uppercase, lowercase and a special character with 8 or more character",
                  },
                  validate: (value) =>
                    value !== currentPassword.current ||
                    "Passwords should not match with current password.",
                })}
              />
              {showPassword.show2 ? (
                <EyeOff
                  className="eye-pass eye-profile"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      show2: !showPassword.show2,
                    })
                  }
                />
              ) : (
                <Eye
                  className="eye-pass eye-profile"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      show2: !showPassword.show2,
                    })
                  }
                />
              )}
              <span className="color-red">
                {errors.newPassword &&
                  (errors.newPassword.message
                    ? errors.newPassword.message
                    : "Please enter password.")}
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-md-2 col-form-label">
              Confirm Password
            </label>
            <div className="col-sm-9 col-md-3">
              <input
                ref={register({
                  required: true,
                  validate: (value) =>
                    value === newPassword.current || "Passwords doesn't match.",
                })}
                name="pass"
                className="form-control"
                type={showPassword.show3 ? "text" : "password"}
                placeholder="Confirm Password"
              />
              {showPassword.show3 ? (
                <EyeOff
                  className="eye-pass eye-profile"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      show3: !showPassword.show3,
                    })
                  }
                />
              ) : (
                <Eye
                  className="eye-pass eye-profile"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      show3: !showPassword.show3,
                    })
                  }
                />
              )}
              <span className="color-red">
                {errors.pass &&
                  (errors.pass.message
                    ? errors.pass.message
                    : "Please enter password.")}
              </span>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="">
            <Button onClick={handleSubmit(onSubmit)} color="primary mr-1">
              Submit
            </Button>
            <Button onClick={reset} color="light">
              Reset
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};
export default ChangePassword;
