import React, { Fragment } from 'react';
import { LogOut, Settings, User } from 'react-feather';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { MEDIA_URL } from '../../../constant/common';


const UserMenu = (props) => {
    const profile = JSON.parse(localStorage.getItem("user")).image
    const logOut = () => {
        localStorage.clear()
        props.history.push("/login")
    }
    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img
                        className="align-self-center pull-right img-40 height-40-px rounded-circle blur-up lazyloaded"
                        src={profile ? MEDIA_URL + profile : require("../../../assets/images/user/user.png")} alt="" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to="/profile"><User />Profile</Link></li>
                    <li><Link to="/setting"><Settings />Settings</Link></li>
                    <li><a href="" onClick={() => { logOut() }}><LogOut /> Log out</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default withRouter(connect()(UserMenu));