import React from 'react';

const RightSidebar = props => {
    return (
        <div>
            <div className="right-sidebar" id="right_side_bar">
                {props.children}
            </div>
        </div>
    )
}

export default RightSidebar; 