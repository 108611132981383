import React, { Component } from 'react'
import { Edit, Eye, Menu } from 'react-feather'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {
  DEFAULT_EMPTY_PAGE,
  DEFAULT_PAGE_OPTIONS,
} from '../../../constant/common'
import ActiveDeactive from '../ActiveDeactive'
import Delete from '../Delete'
import ToolTipCommon from '../ToolTip'
import Approve from '../Approve'
import Decline from '../Decline'
import PriceIcon from '../price'
import DownloadIcon from '../downloadIcon'

const DraggableContainer = sortableContainer(({ children }) => children)
const DraggableElement = sortableElement(({ children }) => children)
const DraggableHandle = sortableHandle(({ children }) => children)
class DragTrComponent extends React.Component {
  render() {
    const { children = null, rowinfo, className } = this.props
    if (rowinfo) {
      const { original } = rowinfo
      const { _id, sortingSequence, sequence } = original
      const SeqIndex = sequence ? sequence : sortingSequence
      return (
        <DraggableElement key={_id} index={SeqIndex} draggableId={_id}>
          <ReactTable.defaultProps.TrComponent className={className}>
            {children}
          </ReactTable.defaultProps.TrComponent>
        </DraggableElement>
      )
    } else
      return (
        <ReactTable.defaultProps.TrComponent className={className}>
          {children}
        </ReactTable.defaultProps.TrComponent>
      )
  }
}
export class Datatable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myData: this.props.myData,
    }
  }

  getTrProps = (state, rowinfo) => {
    return { rowinfo }
  }
  handleDragEnd = (element) => {
    if (element.oldIndex !== element.newIndex)
      this.props.updateSequence &&
        this.props.updateSequence(element.oldIndex, element.newIndex)
  }
  render() {
    const {
      pagination,
      columns,
      actions,
      fetch,
      sortable,
      edit,
      model,
      pageSize,
      handleChange,
      pages,
      page,
      handleSort,
      externalDelete,
      tittle,
      download,
      handleApproveDecline, // function which can be use to handle accept decline action in root file
      actionColumn = true, //flag used to hide action column
      handleDelete = false, //external delete function
      onApproveClick, //only use this function while customize approve button
      activeTabId,
      handleApproveExtraJobs,
      viewHistory
    } = this.props;
    const { myData } = this.props
    actionColumn &&
      actions &&
      columns.push({
        Header: <b>Action</b>,
        id: 'delete',
        Cell: (row) => {
          const { original: { appLimitRequest: { requestHistory = [] } = {} } = {} } = row
          return (
            <div div className="d-flex m-t-1 justify-content-center" >
              {
                actions.canActive && (
                  <ActiveDeactive
                    fetch={fetch}
                    id={row.original._id}
                    status={row.original.isActive}
                    model={model}
                  />
                )
              }
              {
                actions.canViewHistory && requestHistory.length > 0 && (
                  <ToolTipCommon
                    title="Request History"
                    onClick={() => viewHistory(row.original)}
                  >
                    <Eye
                      className="actions-icon action-primary"
                      style={{ width: 22 }}
                    />
                  </ToolTipCommon>
                )
              }
              {
                actions.canDelete && (
                  <Delete
                    id={row.original._id}
                    model={model}
                    fetch={fetch}
                    externalDelete={externalDelete}
                    tittle={tittle}
                    handleDelete={handleDelete}
                  />
                )
              }
              {
                actions.canEdit && (
                  <ToolTipCommon
                    title="Edit"
                    onClick={() => edit(row.original._id)}
                  >
                    <Edit
                      style={{
                        width: 22,
                        padding: 3,
                        color: 'rgb(40, 167, 69)',
                        margin: '0 2px',
                      }}
                    />
                  </ToolTipCommon>
                )
              }
              {
                actions.canDrag && (
                  <DraggableHandle>
                    <Menu
                      style={{
                        width: 22,
                        padding: 3,
                        color: '#000',
                        margin: '0 2px',
                      }}
                    />
                  </DraggableHandle>
                )
              }
              {
                actions.canApprove && (
                  <Approve
                    fetch={fetch}
                    id={row.original}
                    handleApproveDecline={handleApproveDecline}
                    onApproveClick={onApproveClick}
                    activeTabId={activeTabId}
                    handleApproveExtraJobs={handleApproveExtraJobs}
                  />
                )
              }
              {
                actions.canDecline && (
                  <Decline
                    handleApproveDecline={handleApproveDecline}
                    id={row.original}
                    activeTabId={activeTabId}
                    handleApproveExtraJobs={handleApproveExtraJobs}
                  />
                )
              }
              {actions.canPricing && <PriceIcon />}
              {
                actions.canDownload && (
                  <ToolTipCommon
                    title="Download Invoice"
                    onClick={() => download(row.original)}
                  >
                    <DownloadIcon />
                  </ToolTipCommon>
                )
              }
              {
                actions.canEditData && (
                  <ToolTipCommon title="Edit" onClick={() => edit(row.original)}>
                    <Edit
                      style={{
                        width: 22,
                        padding: 3,
                        color: 'rgb(40, 167, 69)',
                        margin: '0 2px',
                      }}
                    />
                  </ToolTipCommon>
                )
              }
            </div >
          )
        },
        style: {
          textAlign: 'center',
        },
        sortable: false,
        width: 150,
      })

    const ExtraProps =
      actions && actions.canDrag
        ? {
          TrComponent: DragTrComponent,
        }
        : {}
    return (
      <DraggableContainer
        useWindowAsScrollContainer={true}
        useDragHandle={true}
        onSortEnd={this.handleDragEnd}
        helperClass={'helper-class-hoc'}
      >
        <ReactTable
          {...ExtraProps}
          minRows={myData && myData.length ? 0 : DEFAULT_EMPTY_PAGE}
          manual
          data={myData}
          sortable={sortable === false ? false : true}
          getTrProps={this.getTrProps}
          pages={pages}
          page={page - 1}
          columns={columns}
          defaultPageSize={pageSize}
          pagination="primary"
          showPagination={pagination}
          pageSize={pageSize}
          pageSizeOptions={DEFAULT_PAGE_OPTIONS}
          onPageChange={(pageIndex) => {
            handleChange({ page: pageIndex + 1 })
          }}
          onPageSizeChange={(pageSize, pageIndex) => {
            handleChange({ limit: pageSize, page: 1 })
          }}
          onSortedChange={(sort) => {
            handleSort({ [sort[0].id]: sort[0].desc ? 'DESC' : 'ASC' })
          }}
          noDataText={'No Data'}
        />
      </DraggableContainer>
    )
  }
}

export default Datatable
