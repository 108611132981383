import React, { Fragment, useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { API_TNC } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
import Editor from '../common/editor';
import MultiLanguage from '../common/MultiLanguage';
const Upsert = (props) => {
    const [defaultLanguage, setDefaultLanguage] = useState('EN')
    const [selectedLanguage, setSelectedLanguage] = useState('EN')
    const [multiLanguageData, setMultiLanguageData] = useState()
    const [defaultTerms, setDefaultTerms] = useState("")
    const { register, handleSubmit, watch, errors, setValue, reset } = useForm({ mode: "onChange" });

    const onSubmit = (values) => {


        let request = props.edit?._id ? { ...API_TNC.update } : { ...API_TNC.create };
        request.url = props.edit?._id ? request.url + edit?._id : request.url
        const data = {
            "type": 1,
            "description": multiLanguageData?.EN?.description ? multiLanguageData?.EN?.description : multiLanguageData?.DANISH?.description,
            "multiLanguageData": { ...multiLanguageData }
        }
        request.obj = { ...data };
        console.log(`request`, request)
        UtilService.callApi(request, function (res) {
            if (res && res.data && (res.code === API_STATUS.CREATED || res.code === API_STATUS.OK)) {
                // toggle8()
                props.fetch()
                toast.success(res.message)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }
    const terms = useRef({});
    terms.current = watch("terms", "")
    const { edit } = props

    function handleAnswerChange(e) {
        var value = e.editor.getData();
        // setDefaultTerms(value)
        let var1 = { "description": value }
        let val = {
            [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
        }
        setMultiLanguageData({ ...multiLanguageData, ...val })
        if (selectedLanguage === defaultLanguage) {
            setValue("terms", value)
        }
    }
    useEffect(() => {
        if (props.edit?._id) {
            setMultiLanguageData(props.edit?.multiLanguageData)
            setDefaultTerms(props.edit?.description)
        }
    }
        , [props.edit])
    return (
        <Fragment>
            <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>
                <MultiLanguage
                    setSelectedLanguage={(lang) => setSelectedLanguage(lang)}
                    setDefaultLanguage={(lang) => setDefaultLanguage(lang)}
                    defaultLanguage={defaultLanguage}
                    selectedLanguage={selectedLanguage}
                />

                <div className="form-group row">
                    <label className="col-sm-12 col-md-2 col-form-label">Terms And Conditions <span className="color-red">*</span></label>
                    <div className="col-sm-12 col-md-10">
                        <Editor selectedLanguage={selectedLanguage}
                            defaultLanguage={defaultLanguage}
                            // content={
                            //     props.edit?.multiLanguageData.selectedLanguage.terms ? props.edit.multiLanguageData.selectedLanguage.terms : terms.current
                            // } 
                            content={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].description ? multiLanguageData[selectedLanguage].description : ""}

                            onChange={(e, lan) => {
                                handleAnswerChange(e)

                            }} />
                        <span className="color-red">{errors.terms && 'Please enter terms.'}</span>
                    </div>
                </div>
                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                    <Button onClick={handleSubmit(onSubmit)} style={{ width: "auto" }} color="primary">{edit?._id ? "Edit" : "Save"}</Button>
                    <Button onClick={() => {
                        setDefaultLanguage('EN')
                        setSelectedLanguage('EN')
                        setMultiLanguageData({})
                    }} style={{ width: "auto", marginLeft: "5px" }} outline={true} color="primary">Reset</Button>
                </div>
            </form>
        </Fragment>)
}

export default Upsert