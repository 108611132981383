import React, { Fragment, useEffect, useState } from 'react';
import { Eye } from 'react-feather';
import { Helmet } from 'react-helmet';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Datatable from '../../components/common/DataTable';
import SearchBar from '../../components/common/SearchBar';
import ToolTipCommon from '../../components/common/ToolTip';
import { API_CONTACT_US } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_PAGE_SIZE } from '../../constant/common';
import CommonService from '../../constant/commonService';
import UtilService from '../../services/common.service';

const ContactUsDetail = (props) => {
    const [masterData, setMasterData] = useState([]); // data list api
    const [totalPages, setTotalPages] = useState(1) // total pages for pagination
    const [view, setView] = useState(null)
    const initialApiFilter = { // filter to send to api
        filter: {
            page: 1,
            limit: DEFAULT_PAGE_SIZE,
            search: {
                keys: ["name", "email", "phone", "countryCode"],
                keyword: ""
            }
        }
    }
    const [filters, setFilters] = useState(initialApiFilter.filter)
    useEffect(() => { // call api on filter change 
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handleFilterChange = (limit) => { // page and limit filter change from data table
        let filter = { ...filters, ...limit }
        setFilters(filter)
    }
    const handleSort = (sort) => { // handle sort change from data table
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const search = (searchVal) => { // handle search filters from search component
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = () => { // api data 
        let request = { ...API_CONTACT_US.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                setTotalPages(Math.ceil(res.data.count / filters.limit))
            }
            else {
                setMasterData([])
                setTotalPages(1)
            }
        })
    }
    const columns = [ // table columns
        {
            Header: "No.",
            id: "_id",
            Cell: row => {
                return (row.index + 1) + ((filters.page - 1) * filters.limit)
            },
            sortable: false,
            resizable: false,
            width: 50
        },
        {
            Header: "Date",
            id: "createdAt",
            accessor: d => CommonService.getDate(d.createdAt)

        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Phone",
            id: "phone",
            accessor: row => (row.countryCode ? row.countryCode : "") + " " + row.phone
        },
        {
            Header: "Message",
            accessor: "message",
            sortable: false
        }, {
            Header: "Actions",
            id: "isActive",
            Cell: row => {
                return <ToolTipCommon onClick={() => setView(row.original.message)} title="Click to View Message" >
                    <Eye className="actions-icon action-primary" style={{ width: 22 }} />
                </ToolTipCommon>
            },
            sortable: false
        }
    ]
    return (
        <Fragment>
            <Helmet>
                <title>Contact Us Detail</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                            <div>
                                <h5>Contact Us Detail</h5><span></span>
                            </div>
                            <div className="d-flex flex-wrap align-items-right">
                                <div className="m-r-10" >
                                    <SearchBar handleSearch={search} title={"Search by name, email and phone number."} />
                                </div>
                            </div>
                        </div>
                        <div className="pageBodyContent product-page-main">
                            <div className="table-responsive support-table">
                                <Datatable
                                    columns={columns}
                                    myData={masterData}
                                    pagination={true}
                                    pageSize={filters.limit}
                                    page={filters.page}
                                    class="-striped -highlight"
                                    handleChange={handleFilterChange}
                                    pages={totalPages}
                                    handleSort={handleSort}
                                    fetch={fetch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal toggle={() => setView(null)} size="lg" centered={true} isOpen={view ? true : false} >
                <ModalHeader toggle={() => setView(null)}>Message</ModalHeader>
                <ModalBody>
                    <div className="p-r-15 p-l-15" style={{ wordBreak: "break-all" }}>
                        {view}
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    );
};
export default ContactUsDetail;