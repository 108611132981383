import PropTypes from 'prop-types';
import React from 'react';
import { Link } from "react-router-dom";
import { default as logo, default as smallLogo } from "../../assets/images/logo/logo.png";
const Logo = (props) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/`}>
            {props.IconOnly ? (
                <img className={`blur-up LogoSize lazyloaded ${props.logoSize}`} src={smallLogo} alt='' />
            ) : (
                    <img className={`blur-up LogoSize lazyloaded ${props.logoSize}`} src={logo} alt='' />
                )}
        </Link>
    )

};
Logo.propTypes = {
    logoSize: PropTypes.string,
    // logo: PropTypes.any.isRequired,
};
export default Logo;