import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SearchBar from '../../components/common/SearchBar';
import FaqCard from '../../components/faq/faqCard';
import Upsert from '../../components/faq/upsert';
import { API_FAQS } from '../../constant/apiConstants';
import { API_STATUS } from '../../constant/common';
import UtilService from '../../services/common.service';

const Faq = () => {
    const [FAQData, setFAQData] = useState();
    const [edit, setEditKey] = useState();

    const initialApiFilter = { // filter to send to api
        filter: {
            isDeleted: false
        },
        page: 1,
        sort: { "sequence": "ASC" },
        search: {
            keys: ["question", "answer"],
            keyword: ""
        }
    }
    const [filters, setFilters] = useState(initialApiFilter)
    const search = (searchVal) => { // handle search filters from search component
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    useEffect(() => { // call api - componentDidMount
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetch = () => { // api data 
        let request = { ...API_FAQS.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setFAQData(res.data.list)
            }
            else {
                setFAQData([])
            }
        })
    }
    return (
        <Fragment>
            <Helmet>
                <title>FAQs</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader d-flex flex-wrap justify-content-between align-items-center">
                            <div>
                                <h5>FAQs</h5>
                                <span></span>
                            </div>
                            <div className="d-flex flex-wrap align-items-right">
                                <div className="m-r-10" >
                                    <SearchBar handleSearch={search} title={"Search by question and answer."} />
                                </div>
                                <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={"Add FAQ"} />
                            </div>
                        </div>
                        <div className="default-according style-1 faq-accordion">
                            <FaqCard edit={(key) => { setEditKey(key) }} fetch={fetch} listfaq={FAQData} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Faq;