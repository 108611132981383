import React, { Fragment } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const BusinessList = (props) => {
  const { id } = props;
  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>Assured Apartment List</ModalHeader>
        <ModalBody>
          {id.map((property) => (
            <div className="default-according style-1 faq-accordion">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>Building name:</b> {property?.assuredBuildNm}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>Street:</b> {property?.street}
                    </p>
                    <p>
                      <b>Floor:</b> {property?.floorNo}
                    </p>
                    <p>
                      <b>Side:</b> {property?.side}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default BusinessList;
