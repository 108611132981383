import React, { Fragment, useEffect, useState, useRef } from "react";
import { MapPin } from "react-feather";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { API_BUILDING, API_USER } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  ROOM_TYPE,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import SimpleImageSlider from "react-simple-image-slider";
import { API_URL } from "../../constant/common";
import { ModalFooter } from "react-bootstrap";
import get from "lodash/get";

const UploadExcel = (props) => {
  const inputFile = useRef(null);
  const { onClose, fetch } = props;

  const validateFile = (file) => {
    const fileName = get(file, "name");
    var allowedExtensions = /(\.xlsx)$/i;
    if (!allowedExtensions.exec(fileName)) {
      return false;
    } else {
      return true;
    }
  };

  const fileUploadButton = () => {
    inputFile.current.click(); //it points to the input type file
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (validateFile(file)) {
      uploadFile(file);
      // upload file API
    } else {
      toast.error("only xlsx file allowed");
    }
  };
  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("building", file, file.name);
    let request = { ...API_BUILDING.uploadExcel };
    request.obj = formData;
    UtilService.callApi(request, function ({ code, data, message }) {
      if (code === API_STATUS.OK) {
        toast.success("File Uploaded successfully");
        setTimeout(() => {
          onClose();
        }, 3000);
        fetch();
      } else {
        toast.error("unable to upload file");
      }
    });
  };

  const handleFileDownload = () => {
    let request = { ...API_BUILDING.downloadExample };
    UtilService.callApi(request, function ({ code, data, message }) {
      if (code === API_STATUS.OK) {
        // server sent the url to the file!
        // now, let's download:
        window.open(`${process.env.REACT_APP_API_URL}${data.uri}`);
        // you could also do:
        // window.location.href = response.file;

        toast.success(message);
        setTimeout(() => {
          onClose();
        }, 3000);
        fetch();
      } else {
        toast.error("unable to download file");
      }
    });
  };

  return (
    <Fragment>
      <Button className="m-l-10" color="primary">
        Upload Excel
      </Button>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{"Upload Excel"}</ModalHeader>
        <ModalBody>
          <div className="py-2 px-3 warning">
            To upload buildings via excel, click on "download Example" button. A
            formatted sheet will be downloaded in which you can fill the details
            and save it. By clicking on "upload excel" button and choosing that
            same file to upload, this will upload the details of all the entered
            building in the excel
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-center">
            <Button
              className="w-100 mr-2"
              color="primary"
              onClick={handleFileDownload}
            >
              Download Example
            </Button>
            <input
              type="file"
              id="file"
              onChange={handleFileUpload}
              ref={inputFile}
              style={{ display: "none" }}
              // accept={".xlsx, .xls, .csv"}
            />
            <Button
              className="w-100 ml-2"
              color="primary"
              onClick={fileUploadButton}
            >
              Upload excel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UploadExcel;
