import React, { Fragment, useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { API_MASTER } from '../../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../../constant/common';
import UtilService from '../../../services/common.service';
import MultiLanguage from '../../common/MultiLanguage';
import UserProfilePhoto from '../../common/userProfilephoto';

const Upsert = (props) => {
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const { edit } = props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const prevEdit = usePrevious({ edit });
    const [defaultLanguage, setDefaultLanguage] = useState()
    const [selectedLanguage, setSelectedLanguage] = useState()
    
    const [multiLanguageData, setMultiLanguageData] = useState()
    const [modal8, setModal8] = useState();
    const { register, watch, handleSubmit, errors, setValue } = useForm({ mode: "onChange" });
    const activeChecked = watch("isActive", true);
    const [image, setImage] = useState()
    const toggle8 = () => {
        if (modal8) {
            setImage(null)
            setMultiLanguageData(null)
            setSelectedLanguage(defaultLanguage)
            props.editDone()
        }
        setModal8(!modal8)
    }
    useEffect(() => {
        register({ name: 'name' }, { required: true })
        register({ name: 'description' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (prevEdit && prevEdit.edit !== edit) {
            if (edit) {
                toggle8()
                fetch(edit)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])
    const fetch = id => {
        let request = { ...API_MASTER.view };
        request.url = request.url + id
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let { name, code, description, likeKeywords, isActive, isDefault, image, multiLanguageData } = res.data
                setValue("name", name)
                setValue("code", code)
                setValue("description", description)
                setValue("likeKeywords", likeKeywords ? likeKeywords.toString() : "")
                setValue("isActive", isActive)
                setValue("isDefault", isDefault)
                setImage(image)
                if (!multiLanguageData) multiLanguageData = {}
                multiLanguageData[defaultLanguage] = {
                    "name": name,
                    "description": description
                }
                setMultiLanguageData(multiLanguageData)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }

    const onSubmit = data => {
        if (data !== '') {
            data.image = image
            data.likeKeywords = data.likeKeywords ? data.likeKeywords.split(',') : ""
            let request = edit ? { ...API_MASTER.edit } : { ...API_MASTER.create };
            request.url = edit ? request.url + edit : request.url
            request.obj = { ...data };
            request.obj.multiLanguageData = multiLanguageData
            // request.obj.name = multiLanguageData[defaultLanguage].name
            if (props.parentId) request.obj.parentId = props.parentId
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    toggle8()
                    props.fetch()
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };


    return (
        <Fragment>
            <Button className="m-l-10" color="primary" onClick={toggle8}>{props.title}</Button>
            <Modal isOpen={modal8} toggle={toggle8} size="lg" centered={true}>
                <ModalHeader toggle={toggle8}>{props.title}</ModalHeader>
                <ModalBody>
                    <form encType='multipart/form-data' className="form theme-form add-form" onSubmit={handleSubmit(onSubmit)}>
                        <MultiLanguage
                            setSelectedLanguage={(lang) => setSelectedLanguage(lang)}
                            setDefaultLanguage={(lang) => setDefaultLanguage(lang)}
                            defaultLanguage={defaultLanguage}
                            selectedLanguage={selectedLanguage}
                            setValue={setValue}
                            multiLanguageData={multiLanguageData}
                        />
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Name <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input
                                    value={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].name ? multiLanguageData[selectedLanguage].name : ""}
                                    className="form-control"
                                    onChange={e => {
                                        let var1 = { "name": e.target.value }
                                        let val = {
                                            [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
                                        }
                                        setMultiLanguageData({ ...multiLanguageData, ...val })
                                        setValue("code", e.target.value.split(" ").join("").toUpperCase())
                                        setValue("name", e.target.value)
                                        
                                    }
                                    }
                                    type="text" placeholder="Enter Name" />
                                <span className="color-red">{errors.name && 'Please enter name for english language.'}
                              </span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Code<span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input
                                    onChange={e => (setValue("code", e.target.value.toUpperCase().trim()))}
                                    name="code" className="form-control" ref={register({
                                        required: true,
                                    })} type="text"
                                    placeholder="Enter Code" />
                                <span className="color-red">{errors.code && (errors.code.message ? errors.code.message : 'Please enter code.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Description</label>
                            <div className="col-sm-9 col-md-10">
                                <textarea
                                    value={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].description ? multiLanguageData[selectedLanguage].description : ""}
                                    onChange={e => {
                                        let var1 = { "description": e.target.value }
                                        let val = {
                                            [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
                                        }
                                        setMultiLanguageData({ ...multiLanguageData, ...val })
                                
                                            setValue("description", e.target.value)
                                        
                                    }
                                    }
                                    name="description"
                                    className="form-control"
                                    type="text" placeholder="Enter Description" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Like Keywords</label>
                            <div className="col-sm-9 col-md-10">
                                <input name="likeKeywords" className="form-control" ref={register} type="text" placeholder="Enter Like Keywords" />
                            </div>
                        </div>
                        {props.parentId && <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Image</label>
                            <div className="col-sm-9 col-md-10  col-form-label">
                                <UserProfilePhoto image={image} masterContent={true} setImage={(url) => setImage(url)} />
                            </div>
                        </div>}
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Active</label>
                            <div className="col-sm-9 col-md-10 col-form-label">
                                <input onChange={(e) => {
                                    if (!e.target.checked) setValue("isDefault", false)
                                }}
                                    name="isActive" className="form-control" ref={register} defaultChecked={true} type="checkbox" />
                            </div>

                        </div>
                        {props.parentId && <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">isDefault</label>
                            <div className="col-sm-9 col-md-10  col-form-label">
                                <input disabled={!activeChecked} name="isDefault" className="form-control" ref={register} type="checkbox" />
                            </div>
                        </div>}
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>
                    <Button outline={true} color="primary" onClick={toggle8}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
    // }
}

export default Upsert;