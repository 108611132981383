import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import 'react-image-lightbox/style.css';
import { Nav, TabContent } from 'reactstrap';
import HLeftTab from "./hLeftTab";

const Navigation = props => {
    const [activeTab, setActiveTab] = useState("1")
    return (
        <Fragment>
            <Nav tabs className={props.TabType}>
                {props.data.map((d, i) => {
                    return (
                        <div key={i}>
                            <HLeftTab
                                key={i}
                                icon={d.icon}
                                conetent={d.name}
                                activeTab={props.activeTab ? props.activeTab === (d.id) ? 'active' : '' : activeTab === (d.id) ? 'active' : ''}
                                setActiveTab={() => { props.activeTab ? props.setActiveTab(d.id) : setActiveTab(d.id) }}
                            />
                        </div>
                    )
                })}
            </Nav>
            <TabContent activeTab={props.activeTab ? props.activeTab : activeTab}>
                {props.children}
            </TabContent>
        </Fragment>
    );
};
Navigation.propTypes = {
    TabType: PropTypes.string,
};
export default Navigation;