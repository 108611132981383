import React from "react";
import {
  Feather,
  GitBranch,
  HelpCircle,
  List,
  Mail,
  Settings,
  User,
  Users,
  Twitch,
  CheckSquare,
  TrendingDown,
} from "react-feather";
import { ReactComponent as Patient } from "../assets/images/dashboard/patient.svg";
import { ReactComponent as UserManagement } from "../assets/images/dashboard/user-management.svg";
import { ReactComponent as TermsAndCondition } from "../assets/images/dashboard/terms.svg";
import { ReactComponent as AboutUs } from "../assets/images/dashboard/about.svg";
import { ReactComponent as Building } from "../assets/images/dashboard/Building.svg";
import { ReactComponent as Invoice } from "../assets/images/dashboard/invoice.svg";
import { ReactComponent as Download } from "../assets/images/dashboard/download.svg";

export const MENUITEMS = [
  {
    path: "/simple-user",
    title: "Simple User",
    icon: <Users className="fillSvgColor" />,
  },
  {
    path: "/properties",
    title: "Property Profile",
    icon: <Patient className="fillSvgColor" />,
  },
  {
    path: "/theme",
    title: "Theme",
    icon: <Feather className="fillSvgColor" />,
  },
  {
    path: "/assurer",
    title: "Assurer Management",
    icon: <List className="fillSvgColor" />,
  },
  {
    title: `3rd Apartment Request`,
    icon: <Twitch />,
    path: "/appartment-request",
  },
  {
    path: "/building",
    title: "Building",
    icon: <Building className="fillSvgColor" />,
  },
  { path: "/checklist", title: "Checklist", icon: <CheckSquare /> },
  {
    path: "/depreciation",
    title: "Depreciation Model",
    icon: <TrendingDown />,
  },
  {
    path: "/board-management",
    title: "Board Management",
    icon: <List className="fillSvgColor" />,
  },
  {
    path: "/admin",
    title: "User Management",
    icon: <UserManagement className="fillSvgColor" />,
  },
  {
    path: "/invoice",
    title: "Invoices",
    icon: <Invoice />,
  },
  {
    path: "/orders",
    title: "Orders",
    icon: <Invoice />,
  },
  { path: "/contact", title: "Contact Us Detail", icon: <Mail /> },
  { path: "/version", title: "App Versions", icon: <GitBranch /> },
  { path: "/faq", title: "FAQs", icon: <HelpCircle /> },
  {
    path: "/terms-and-conditions",
    title: "Terms And Conditions",
    icon: <TermsAndCondition className="fillSvgColor" />,
  },
  {
    path: "/about-us",
    title: "About Us",
    icon: <AboutUs className="fillSvgColor" />,
  },
  { title: "Profile", icon: <User />, path: "/profile" },
  { title: "Settings", icon: <Settings />, path: "/setting" },
];
