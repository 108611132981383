import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Datatable from "../../components/common/DataTable";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import { API_ASSURER_MANAGEMENT } from "../../constant/apiConstants";
import {
  API_STATUS,
  ASSURER_MANAGEMENT,
  DEFAULT_PAGE_SIZE,
  MODEL,
  ASSURER_MANAGEMENT_TAB,
  REASON_FOR_ASSURER,
  LOCALISATION,
} from "../../constant/common";
import UtilService from "../../services/common.service";
import ToolTipCommon from "../../components/common/ToolTip";
import Details from "./view";
import { Eye } from "react-feather";
import BusinessList from "./BusinessList";
import { toast } from "react-toastify";
import DetailsModalCommon from "../../components/common/modal/detailsmodal";
import EditPriceModal from "../../components/common/modal/editPriceModal";
import LimitModal from "./LimitModal";
import ExtraJobsModal from "./extrajobsModal";
import ViewHistory from "../../components/common/modal/viewRequestHistory";

const AssurerManagement = (props) => {
  const [assurerData, setAssurerData] = useState([]); // data list api
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [activeTab, setActiveTab] = useState(
    ASSURER_MANAGEMENT.ASSURER_REQUEST
  );

  const [popupDetail, setPopupDetail] = useState();
  const [businessPopupDetail, setBusinessPopupDetail] = useState();
  const [popupTitle, setPopupTitle] = useState("");
  const [deleteButton, setDeleteButton] = useState(false);
  const [approveDecline, setApproveDecline] = useState(true);
  const [actionColumn, setActionColumn] = useState(true);
  const [popupChild, setPopupChild] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [apartmentColumn, setApartmentColumn] = useState(false);
  const [reasonColumn, setReasonColumn] = useState(true);
  const [editOption, setEditOption] = useState(false);
  const [editingPriceData, setEditingPriceData] = useState();
  const [editingUserId, setEditingUserId] = useState();
  const [limitModal, setLimitModal] = useState(false);
  const [extraJobsModal, setExtraJobsModal] = useState(false);
  const [extraJobDetail, setExtraJobDetail] = useState({})
  const [viewHistory, setViewHistory] = useState(false)
  const [limitAssurerId, setLimitAssurerId] = useState();
  const [viewModalData, setViewModalData] = useState()
  const initialApiFilter = {
    // filter to send to api



    filter: {
      query: {
        isVerified: false,
        isDeleted: false,
        type: {
          "$in": [5, 6] // 5 for assurer and 6 for main assurer(currently we don't have any data for type 6.)
        }
        ,
        search: "",
        searchColumns: ["name", "emails.email", "mobiles.mobile", "compName"]
      },
      options: {
        page: 1,
        limit: 10,
        populate: [
          {
            "path": "mainAssurer",
            "select": "name street zip city amtPerSq cvr buildingImg"
          },
          {
            "path": "assuredProp"
          },
          {
            "path": "addresses.city",
            "select": "name"
          }
        ],
        select: "name firstName lastName emails mobiles image addresses type compName cvr reasonForRegistration compLogo signature decReason creditLeft platObj.platFee expirationDate assurerFees didPaidFee assurerFees appLimitRequest"
      }

    },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);

  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = ({ limit, page }) => {
    let filter = { ...filters };
    if (limit) {
      filter.options.offset = limit * page - limit;
      filter.options.limit = limit;
    } else {
      filter.options.offset = filters.options.limit * page - filters.options.limit;
    }
    filter.options.page = page;
    setFilters(filter);
  };

  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.options.page = 1;
    filter.options.sort = sort;
    setFilters(filter);
    fetch();
  };

  const search = (searchVal) => {
    // handle search filters from search component
    // name, email, phone, company name
    let filter = filters;
    filter.query = { ...filter.query, search: searchVal };
    setFilters(filter);
    fetch();
  };

  const fetch = (id) => {
    // api data
    const activeTabId = id || activeTab
    let request = activeTabId == ASSURER_MANAGEMENT.ASSURER_EXTRA_JOB_REQUEST ? { ...API_ASSURER_MANAGEMENT.extraJobRequest } : { ...API_ASSURER_MANAGEMENT.paginate };
    request.obj = activeTabId == ASSURER_MANAGEMENT.ASSURER_EXTRA_JOB_REQUEST ? { ...filters, query: { ...filters.query, isVerified: undefined, isDeleted: undefined } } : filters;
    UtilService.callApi(request, function ({ data, code }) {
      if (data && code === API_STATUS.OK) {
        const { docs } = data;
        setTotalPages(Math.ceil(data.totalDocs / filters.options.limit));
        setAssurerData(docs);
      } else {
        setAssurerData([]);
        setTotalPages(1);
      }
    });
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
    let key = ASSURER_MANAGEMENT_TAB.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.query.isVerified = key.verified;
    filterToSet.query.isDeleted = key.delete;
    setActionColumn(key.actionColumn);
    setDeleteButton(key.deleteBtn);
    setFilters(filterToSet);
    setApproveDecline(key.approveDecline);
    setApartmentColumn(key.apartmentColumn);
    setReasonColumn(key.reasonColumn);
    setEditOption(key.verified);
    setViewHistory(key.viewHistory)
    fetch(id);
  };

  const handleDelete = (id) => {
    let request = { ...API_ASSURER_MANAGEMENT.delete };
    request.url = request.url + id;
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        fetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const handlePopUpDetails = (data, title) => {
    setPopupDetail(data);
    setPopupTitle(title);
    if (title === REASON_FOR_ASSURER.REGISTRATION) {
      setPopupChild(data.reasonForRegistration);
    } else {
      setPopupChild(data.decReason);
    }
  };
  const handleApproveDecline = (props, { declineReason = "" }) => {
    let request = { ...API_ASSURER_MANAGEMENT.approveDecline };
    request.url = request.url + props._id + `?lng=${LOCALISATION.ENGLISH}`;
    if (Object.keys(declineReason).length > 0) {
      request.obj = { declineReason, isVerified: false };
    } else {
      setLimitModal(true);
      setLimitAssurerId(props._id);
      return;
    }
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        fetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const handleApproveExtraJobs = (props, { declineReason = "" }) => {


    let request = { ...API_ASSURER_MANAGEMENT.APPROVE_JOBS };
    request.url = `${request.url}/${props._id}`;
    if (Object.keys(declineReason).length > 0) {
      request.obj = { noOfJobs: props?.appLimitRequest?.appLimit, declineReason, isApproved: false };
    } else {
      setExtraJobsModal(true);
      setExtraJobDetail(props)
      setLimitAssurerId(props._id);
      return;
    }
    UtilService.callApi(request, function ({ data, code, message }) {

      if (data && code === API_STATUS.OK) {
        fetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  }
  const handleDetailsModal = (data, title) => {
    if (REASON_FOR_ASSURER.BUILDING === title && data.mainAssurer.length) {
      return setDetailsModal(data.mainAssurer);
    } else {
      toast.error("Assurer has not been assigned as Main assurer.");
    }
  };

  const handleAssuredApartment = (data, title) => {
    if (REASON_FOR_ASSURER.APARTMENT === title && data.assuredProp.length > 0) {
      return setBusinessPopupDetail(data.assuredProp);
    } else {
      toast.error("The Assurer has no assured apartments.");
    }
  };

  const columns = [
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.options.page - 1) * filters.options.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Company Name",
      accessor: "compName",
    },
    {
      Header: "Name",
      Cell: ({ original = {} }) => {
        const name = original?.firstName + " " + original?.lastName;
        return name ? name : "";
      },
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ original = {} }) => {
        return original
          ? (original?.mobiles?.[0] ? original?.mobiles?.[0]?.countryCode : "") +
          " " +
          (original?.mobiles?.[0] ? original?.mobiles?.[0]?.mobile : "")
          : "";
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original = {} }) => {
        return original
          ? original?.emails[0]
            ? original?.emails[0]?.email
            : ""
          : "";
      },
    },
    {
      Header: REASON_FOR_ASSURER.REGISTRATION,
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={REASON_FOR_ASSURER.REGISTRATION}
            onClick={() =>
              handlePopUpDetails(original, REASON_FOR_ASSURER.REGISTRATION)
            }
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
      show: reasonColumn,
    },
    {
      Header: REASON_FOR_ASSURER.DECLINE,
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={REASON_FOR_ASSURER.DECLINE}
            onClick={() =>
              handlePopUpDetails(original, REASON_FOR_ASSURER.DECLINE)
            }
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
      show: !actionColumn,
    },
    {
      Header: "Job Create Price",
      accessor: "assurerFees.newJob",
    },
    {
      Header: "Approve Job Price",
      accessor: "assurerFees.acceptJob",
    },

    {
      Header: REASON_FOR_ASSURER.APARTMENT,
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={REASON_FOR_ASSURER.APARTMENT}
            onClick={() =>
              handleAssuredApartment(original, REASON_FOR_ASSURER.APARTMENT)
            }
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
      show: apartmentColumn,
    },
    {
      Header: REASON_FOR_ASSURER.BUILDING,
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={REASON_FOR_ASSURER.BUILDING}
            onClick={() =>
              handleDetailsModal(original, REASON_FOR_ASSURER.BUILDING)
            }
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
      show: apartmentColumn,
    },
  ];
  const handleEdit = (data = {}) => {
    setEditingPriceData({
      ...data.assurerFees,
      name: `${data.firstName} ${data.lastName}`,
    });
    setEditingUserId(data.id);
  };
  const handleViewHistory = (data) => {
    setViewModalData(data?.appLimitRequest?.requestHistory)
  };
  return (
    <Fragment>
      <Helmet>
        <title>Assurer management</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
              <div>
                <h5>Assurer management</h5>
                <span></span>
              </div>
              <div className="d-flex flex-wrap align-items-right">
                <div className="m-r-10">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by name, email, phone, company name"}
                  />
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={ASSURER_MANAGEMENT_TAB}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {ASSURER_MANAGEMENT_TAB.map((d, i) => {
                return activeTab === d.id ? (
                  <HRightTab key={i} tabId={d.id}>
                    <div className="search-page profile-page">
                      <div className="PageBodyWrapper">
                        <div className="pageBodyContent product-page-main">
                          <div className="table-responsive support-table">
                            <div className="search-page profile-page">
                              <div className="PageBodyWrapper">
                                <div className="pageBodyContent product-page-main">
                                  <div className="table-responsive support-table">
                                    <Datatable
                                      columns={columns}
                                      myData={assurerData}
                                      pagination={true}
                                      pageSize={filters.options.limit}
                                      page={filters.options.page}
                                      class="-striped -highlight"
                                      handleChange={handleFilterChange}
                                      pages={totalPages}
                                      handleSort={handleSort}
                                      fetch={fetch}
                                      model={MODEL.VERSION}
                                      actions={{
                                        canEditData: editOption,
                                        canDelete: deleteButton,
                                        canApprove: approveDecline,
                                        canDecline: approveDecline,
                                        canViewHistory: viewHistory
                                      }}
                                      externalDelete={
                                        API_ASSURER_MANAGEMENT.delete
                                      }
                                      handleDelete={handleDelete}
                                      handleApproveDecline={
                                        handleApproveDecline
                                      }
                                      actionColumn={actionColumn}
                                      edit={handleEdit}
                                      handleApproveExtraJobs={handleApproveExtraJobs}
                                      activeTabId={d.id}
                                      viewHistory={handleViewHistory}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </HRightTab>
                ) : null;
              })}
            </Navigation>
          </div>
        </div>
      </div>
      {popupDetail && (
        <Details
          id={popupDetail}
          child={popupChild}
          title={popupTitle}
          onClose={() => setPopupDetail(false)}
        />
      )}
      {businessPopupDetail && (
        <BusinessList
          id={businessPopupDetail}
          onClose={() => setBusinessPopupDetail(false)}
        />
      )}
      {detailsModal && (
        <DetailsModalCommon
          id={detailsModal}
          onClose={() => setDetailsModal(false)}
        />
      )}
      {editingPriceData && (
        <EditPriceModal
          price={editingPriceData}
          editingUserId={editingUserId}
          onClose={() => setEditingPriceData(false)}
          fetch={fetch}
        />
      )}
      {limitModal && (
        <LimitModal
          title={"Enter approval details"}
          onClose={() => setLimitModal(false)}
          fetchList={fetch}
          limitAssurerId={limitAssurerId}
        />
      )}
      {extraJobsModal && (
        <ExtraJobsModal
          title={"Enter approval details"}
          onClose={() => setExtraJobsModal(false)}
          fetchList={fetch}
          limitAssurerId={limitAssurerId}
          extraJobDetail={extraJobDetail}
        />
      )}
      {viewModalData && <ViewHistory
        myData={viewModalData}
        onClose={() => setViewModalData(false)}
      />}
    </Fragment>
  );
};

export default AssurerManagement;
