import React from 'react';
import { API_URL, GENDER_TYPE } from '../../../constant/common';
import Image from '../Image';

const ImageByGender = (props) => {
    return (
        <Image ImageClass={props.ImageClass}
            src={props.src ? API_URL + props.src :
                props.gender === GENDER_TYPE.MALE ?
                    require("../../../assets/images/user/user.png")
                    : require("../../../assets/images/user/femaleUser.png")}
            alt="user" />

    )

};
export default ImageByGender;