import React, { Fragment, useEffect, useState } from "react";
import { MapPin } from "react-feather";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader, Toast } from "reactstrap";
import { CommonError } from "../../validation/commonValidation";
import { API_BUILDING, API_CITY, API_USER } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  MAX_CVR,
} from "../../constant/common";
import UtilService from "../../services/common.service";
import { ModalFooter } from "react-bootstrap";
import useForm from "react-hook-form";
import SingleSelect from "../common/select";
import useSearch from "../common/select/useSearch";





const AddBuildingDetails = (props) => {
  const { editData, editOrAdd, fetch } = props;
  const delay = useSearch()
  const [mounted, setMounted] = useState(false);
  const [city, setCity] = useState([])
  const [selectedCity, setSelectedCity] = useState([])
  const [citySearch, setCitySearch] = useState("")
  const [cityError, setCityError] = useState(false)


  const { register, handleSubmit, errors, setValue, watch } = useForm({
    mode: "onChange",
  });

  const setValuesManually = (editData) => {
    let { name, street, zip, city = "", cvr, cityId = "" } = editData;
    setValue("name", name);
    setValue("street", street);
    setValue("zip", zip);
    setValue("cvr", cvr);
    setSelectedCity([{ label: city, value: cityId }])
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (editData && mounted) {
      setValuesManually(editData);
    }
  }, [editData, mounted]);

  const onSubmit = (data) => {
    if (selectedCity.length === 0) {
      return setCityError(true)
    }
    if (data !== "" && !cityError) {
      if (editOrAdd === "Add") {
        let request = { ...API_BUILDING.ADD };
        request.obj = { ...data, cityId: selectedCity.value, city: selectedCity.label };
        UtilService.callApi(request, ({ message, code }) => {
          if (code === API_STATUS.OK) {
            toast.success(message);
            fetch();
            setTimeout(() => {
              props.onClose();
            }, 3000);
          } else {
            toast.error(message ? message : DEFAULT_API_ERROR);
          }
        });
      }
      if (editOrAdd === "Edit") {
        let request = { ...API_BUILDING.edit };
        request.url = request.url + editData._id;
        request.obj = { ...data, cityId: selectedCity.value, city: selectedCity.label };
        UtilService.callApi(request, ({ message, code }) => {
          if (code === API_STATUS.OK) {
            toast.success(message);
            fetch();
            setTimeout(() => {
              props.onClose();
            }, 3000);
          } else {
            toast.error(message ? message : DEFAULT_API_ERROR);
          }
        });
      }
    } else {
      errors.showMessages();
    }
  };
  const getCityData = async () => {
    let request = { ...API_CITY.list };
    request.obj = {
      "filter": {
        "name": {
          "$regex": citySearch,
          "$options": "i"
        }
      }
    }
    UtilService.callApi(request, ({ code, data: { docs = [] } }) => {
      if (code === API_STATUS.OK) {
        const city = []
        const cityData = docs
        cityData.map(i => {
          city.push({ label: i.name, value: i._id })
        })
        setCity(city)
      }

    })
  }

  const handleOnChangeCity = (data) => {
    setSelectedCity(data)
    setCityError(false)
  }


  const handleOnChangeSelectCity = (city) => {
    setCitySearch(city)
    delay.debounced(getCityData)

  }
  useEffect(() => {
    getCityData()
  }, [])

  return (
    <Fragment>
      <Button className="m-l-10" color="primary">
        {props.title}
      </Button>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{"Add Building"}</ModalHeader>
        <ModalBody>
          <form className="form theme-form add-form">
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Name <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter Name"
                  ref={register({
                    required: { value: true, message: "name is required!" },
                  })}
                />
                {Object.keys(errors) ? CommonError(errors?.name?.message) : ""}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Street Name <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="street"
                  className="form-control"
                  type="text"
                  placeholder="Enter Street Name"
                  ref={register({
                    required: { value: true, message: "street is required!" },
                  })}
                />
                {Object.keys(errors)
                  ? CommonError(errors?.street?.message)
                  : ""}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                City <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <SingleSelect
                  id="city"
                  name="city"
                  placeholder={"Select city"}
                  data={city}
                  onInputChange={handleOnChangeSelectCity}
                  onChangeSelect={handleOnChangeCity}
                  value={selectedCity || []}
                  ref={register}
                />
                {cityError ? CommonError("Please select city!") : ""}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                CVR Number
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="cvr"
                  className="form-control"
                  type="text"
                  placeholder="Enter CVR Number"
                  ref={register({
                    maxLength: {
                      value: 8,
                      message: "Please enter 8 digit CVR number.",
                    },
                    required: { value: true, message: "CVR is required!" },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "only numbers are allowed in CVR",
                    },
                  })}
                  maxLength={MAX_CVR}
                />
                {Object.keys(errors) ? CommonError(errors?.cvr?.message) : ""}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Zip Code <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="zip"
                  className="form-control"
                  type="text"
                  placeholder="Enter Zip Code"
                  ref={register({
                    required: { value: true, message: "zip is required!" },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "only numbers are allowed in zip code",
                    },
                  })}
                />
                {Object.keys(errors) ? CommonError(errors?.zip?.message) : ""}
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit(onSubmit)}>
            {editOrAdd}
          </Button>
          <Button outline={true} color="primary" onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddBuildingDetails;
