import React, { Fragment, useEffect, useState } from 'react'
import { Mail, Eye, CheckSquare } from 'react-feather'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import Datatable from '../../components/common/DataTable'
import SearchBar from '../../components/common/SearchBar'
import ToolTipCommon from '../../components/common/ToolTip'
import Details from '../../components/PropertyDetails/view'
import { API_PROPERTY_PROFILE } from '../../constant/apiConstants'
import {
  API_STATUS,
  DEFAULT_PAGE_SIZE,
  MODEL,
  ROOM_TYPE,
  DEFAULT_API_ERROR,
} from '../../constant/common'
import CommonService from '../../constant/commonService'
import UtilService from '../../services/common.service'
import Delete from '../../components/common/Delete'
import Upsert from '../../components/Version/upsert'
import AddPropertyDetails from '../../components/PropertyDetails/AddPropertyDetails'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const PropertyProfile = (props) => {
  const [masterData, setMasterData] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [popupDetail, setPopupDetail] = useState()
  const [addPropertyDetails, setAddPropertyDetails] = useState(false)
  const [edit, setEditKey] = useState('')
  const initialApiFilter = {
    filter: {
      filter: {
        isDeleted: false,
        isActive: true,
        addedBy: props.location.data ? props.location.data.id : undefined,
      },
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      search: {
        keys: ['street', 'addedBy.name'],
        keyword: '',
      },
    },
  }
  const [filters, setFilters] = useState(initialApiFilter.filter)
  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  // const handleFilterChange = (limit) => {
  //   let filter = { ...filters, ...limit };
  //   setFilters(filter);
  // };

  const handleFilterChange = ({ limit, page }) => {
    // page and limit filter change from data table
    let filter = { ...filters }
    if (limit) {
      filter.offset = limit * page - limit
      filter.limit = limit
    } else {
      filter.offset = filters.limit * page - filters.limit
    }
    filter.page = page
    setFilters(filter)
  }

  const handleSort = (sort) => {
    const sorting = {}
    if (sort['addedBy.name'] === 'ASC') {
      sorting['addedBy.name'] = 1
    } else {
      sorting['addedBy.name'] = -1
    }
    let filter = filters
    filter.page = 1
    filter.sort = sorting
    setFilters(filter)
    fetch()
  }
  const search = (searchVal) => {
    let filter = filters
    filter.search.keyword = searchVal
    filter.page = 1
    setFilters(filter)
    fetch()
  }
  // fetch data
  const fetch = () => {
    let request = { ...API_PROPERTY_PROFILE.paginate }
    request.obj = filters
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        setMasterData(res.data.list)
        setTotalPages(res.data.paginator.pageCount)
      } else {
        setMasterData([])
        setTotalPages(1)
      }
    })
  }
  const sendPropertyProfileEmail = (propertyId) => {
    let request = { ...API_PROPERTY_PROFILE.send_email }
    request.url = request.url + propertyId
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        toast.success(res.message)
      } else {
        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
      }
    })
  }
  const columns = [
    {
      Header: 'No.',
      id: '_id',
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: 'Added By',
      accessor: 'addedBy.name',
    },
    {
      Header: 'Building Name',
      accessor: 'buildingId.name',
    },
    {
      Header: 'Added Date',
      id: 'createdAt',
      Cell: (row) => {
        return (
          <div>
            <div>{CommonService.getDate(row.original.createdAt)}</div>
          </div>
        )
      },
    },
    // {
    //     Header: "Value",
    //     accessor: "value"
    // }, {
    //     Header: "Apartment Size",
    //     id: "apartmentSize",
    //     accessor: d => d.apartmentSize + " Sq m"
    // }, {
    //     Header: "Improvement Amount",
    //     accessor: "improvementAmount"
    // },
    {
      Header: 'Address',
      id: 'address',
      Cell: (row) => {
        const fullAddress = []
        fullAddress.push(row.original.street ? row.original.street : '')
        fullAddress.push(row.original.HomeNumber ? row.original.HomeNumber : '')
        fullAddress.push(row.original.floorNo ? row.original.floorNo : '')
        fullAddress.push(
          row.original.apartmentSize ? row.original.apartmentSize : '',
        )
        fullAddress.push(row.original.zip ? row.original.zip : '')
        fullAddress.push(row.original.city ? row.original.city : '')

        const addressString = fullAddress.join(' ')
        return (
          <ToolTipCommon className="display-full" title={addressString}>
            {addressString}
          </ToolTipCommon>
        )
      },
    },
    {
      Header: 'Attributes',
      id: 'rooms',
      Cell: (row) => {
        return (
          <ToolTipCommon
            className="display-full"
            title={
              row.original.rooms && row.original.rooms.length
                ? row.original.rooms
                  .map((a) =>
                    a.roomType && ROOM_TYPE[a.roomType]
                      ? (a.numberOfRooms ? a.numberOfRooms : '0') +
                      ' ' +
                      ROOM_TYPE[a.roomType]
                      : '',
                  )
                  .toString()
                : ''
            }
          >
            {row.original.rooms && row.original.rooms.length
              ? row.original.rooms
                .map((a) =>
                  a.roomType && ROOM_TYPE[a.roomType]
                    ? (a.numberOfRooms ? a.numberOfRooms : '0') +
                    ' ' +
                    ROOM_TYPE[a.roomType]
                    : '',
                )
                .toString()
              : ''}
          </ToolTipCommon>
        )
      },
      sortable: false,
    },
    {
      Header: 'Added by Assurer',
      id: 'addedByAssure',
      Cell: (row) => {
        return (
          <div className="action-act">
            {row.original.addedByAssurer && (
              <ToolTipCommon title="added by assurer">
                <CheckSquare
                  className="actions-icon action-primary"
                  style={{ width: 22 }}
                />
              </ToolTipCommon>
            )}
          </div>
        )
      },
      sortable: false,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Action',
      id: 'isActive',
      Cell: (row) => {
        return (
          <div className="action-act">
            <ToolTipCommon
              title="View Details"
              onClick={() => setPopupDetail(row.original._id)}
            >
              <Eye
                className="actions-icon action-primary"
                style={{ width: 22 }}
              />
            </ToolTipCommon>
            <ToolTipCommon
              title="Send Property Profile Over Email"
              onClick={() => {
                sendPropertyProfileEmail(row.original._id)
              }}
            >
              <Mail
                className="actions-icon action-primary"
                style={{ width: 22 }}
              />
            </ToolTipCommon>
            <ToolTipCommon title="Delete">
              <Delete
                id={row.original._id}
                model={'property'}
                fetch={fetch}
                externalDelete={API_PROPERTY_PROFILE.delete}
              />
            </ToolTipCommon>
          </div>
        )
      },
      sortable: false,
      style: {
        textAlign: 'center',
      },
    },
  ]

  return (
    <Fragment>
      <Helmet>
        <title>Property Profile</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="flex-wrap pageBodyHeader d-flex justify-content-between align-items-center">
              <div>
                <h5>Property Profile</h5>
                <span></span>
              </div>
              <div className="flex-wrap d-flex align-items-right">
                <SearchBar
                  value={
                    props.location.data ? props.location.data.search : undefined
                  }
                  handleSearch={search}
                  title={'Search by user name'}
                />
                {/* <Button
                  onClick={() => setAddPropertyDetails(true)}
                  color="primary"
                >
                  Add Property
                </Button> */}
              </div>
            </div>
            <div className="search-page profile-page">
              <div className="PageBodyWrapper">
                <div className="pageBodyContent product-page-main">
                  <div className="table-responsive support-table">
                    <Datatable
                      columns={columns}
                      myData={masterData}
                      pagination={true}
                      pageSize={filters.limit}
                      page={filters.page}
                      class="-striped -highlight"
                      handleChange={handleFilterChange}
                      pages={totalPages}
                      handleSort={handleSort}
                      fetch={fetch}
                      model={MODEL.USER}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popupDetail && (
        <Details id={popupDetail} onClose={() => setPopupDetail(false)} />
      )}
      {addPropertyDetails && (
        <AddPropertyDetails
          id={addPropertyDetails}
          onClose={() => setAddPropertyDetails(false)}
        />
      )}
    </Fragment>
  )
}

export default PropertyProfile
