import React, { Fragment, useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import useForm from "react-hook-form";
import { CommonError } from "../../../validation/commonValidation";
import {
  API_ASSURER_MANAGEMENT,
  API_BUILDING,
} from "../../../constant/apiConstants";
import UtilService from "../../../services/common.service";
import { toast } from "react-toastify";
import { API_STATUS, DEFAULT_API_ERROR } from "../../../constant/common";

const EditPriceModal = (props) => {
  const { price, editingUserId, fetch } = props;
  const [mounted, setMounted] = useState(false);

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (price && mounted) {
      setValuesManually(price);
    }
  }, [price, mounted]);

  const setValuesManually = (price) => {
    const { acceptJob, newJob } = price;
    setValue("acceptJob", acceptJob);
    setValue("newJob", newJob);
  };

  const onSubmit = (data) => {
    if (data !== "") {
      let request = { ...API_ASSURER_MANAGEMENT.updatePrice };
      request.url = request.url + editingUserId;
      request.obj = data;
      UtilService.callApi(request, ({ message, code }) => {
        if (code === API_STATUS.OK) {
          toast.success("Price updated successfully");
          setTimeout(() => {
            props.onClose();
            fetch();
          }, 3000);
        } else {
          toast.error(message ? message : DEFAULT_API_ERROR);
        }
      });
    }
  };

  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{price.name}</ModalHeader>
        <ModalBody>
          <div className="form theme-form add-form">
            <div className="default-according style-1 faq-accordion">
              <div class="form-group row">
                <label class="col-sm-3 col-md-2 col-form-label">
                  Job Create Price<span class="color-red m-l-5">*</span>
                </label>
                <div class="col-sm-9 col-md-10">
                  <input
                    name="newJob"
                    class="form-control"
                    type="text"
                    ref={register({
                      required: { value: true, message: "newJob is required!" },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "only numbers are allowed in price",
                      },
                    })}
                  />
                  {Object.keys(errors)
                    ? CommonError(errors?.newJob?.message)
                    : ""}
                </div>
              </div>
              <div class="form-group row mt-3">
                <label class="col-sm-3 col-md-2 col-form-label">
                  Approve Job Price<span class="color-red m-l-5">*</span>
                </label>
                <div class="col-sm-9 col-md-10">
                  <input
                    name="acceptJob"
                    class="form-control"
                    type="text"
                    ref={register({
                      required: {
                        value: true,
                        message: "acceptJob is required!",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "only numbers are allowed in price",
                      },
                    })}
                  />
                  {Object.keys(errors)
                    ? CommonError(errors?.acceptJob?.message)
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
            Save
          </button>
          <button className="btn btn-outline-primary" onClick={props.onClose}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default EditPriceModal;
