import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Datatable from "../../components/common/DataTable";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import { API_BOARD_MANAGEMENT } from "../../constant/apiConstants";
import {
  API_STATUS,
  APPROVE_HANDLE_STRING,
  BOARD_MANAGEMENT,
  BUILDING_BOARD_MANAGEMENT,
  BUILDING_PENDING_TAB,
  DEFAULT_PAGE_SIZE,
  LOCALISATION,
  MODEL,
} from "../../constant/common";
import UtilService from "../../services/common.service";
import ToolTipCommon from "../../components/common/ToolTip";
import { Eye } from "react-feather";
import Details from "../AssurerManagement/view";
import DetailsModalCommon from "../../components/common/modal/detailsmodal";
import { toast } from "react-toastify";
import { commonSearchQuery } from "../../components/common/commonAPiRequest";
import AmountModal from "./AmountModal";
import moment from "moment";
import BoardMemberModal from "./BoardMemberModal";

const Board = (props) => {
  const [masterData, setMasterData] = useState([]); // data list api
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [activeTab, setActiveTab] = useState(BUILDING_PENDING_TAB.status);
  const [popupDetail, setPopupDetail] = useState();
  const [actionColumn, setActionColumn] = useState(true);
  const [modalDetails, setModalDetails] = useState();
  const [showReason, setShowReason] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [approveDecline, setApproveDecline] = useState(true);
  const [deleteAction, setDeleteAction] = useState(false);
  const [amountDetails, setAmountDetails] = useState();
  const [boardId, setBoardId] = useState();
  const [buildName, setBuildName] = useState();
  const [amountColumn, setAmountColumn] = useState(false);
  const [boardMemberModal, setBoardMemberModal] = useState(false);
  const [boardMemberDetails, setBoardMemberDetails] = useState();

  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {
          isVerified: false,
          isActive: true,
          isDeleted: false
      },
      query: {
          search: "",
          searchColumns: [ "emails.email", "mobiles.mobile", "name", "buildId.cvr", "buildId.name" ]
      },
      options: {
          page: 1,
          limit: DEFAULT_PAGE_SIZE
      },
  },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);

  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = ({ limit, page }) => {
    let filter = { ...filters };
    if (limit) {
      filter.options.limit = limit;
    }
    filter.options.page = page;

    setFilters(filter);
  };

  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.options.page = 1;
    filter.sort = sort;
    setFilters(filter);
    fetch();
  };

  const search = (searchVal) => {
    // handle search filters from search component
    const searchKey = ["emails.email", "mobiles.mobile", "name", "buildId.cvr", "buildId.name"];
    let searchReg = commonSearchQuery(searchKey, searchVal);
    let filter = filters;
    filter.filter = { ...filter.filter, $or: searchReg };
    setFilters(filter);
    fetch();
  };

  const approveAmount = (dataPayload) => {
    let request = { ...API_BOARD_MANAGEMENT.approveOrDecline };
    request.url = request.url + boardId + `?lng=${LOCALISATION.ENGLISH}`;
    request.obj = {
      amount: dataPayload.amount,
      isVerified: true,
      expirationDate: dataPayload.expirationDate,
      buildName,
    };
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        setAmountDetails(false);
        fetch();
        toast.success(message);
      } else {
        setAmountDetails(false);
        toast.error(message);
      }
    });
  };

  const handleApproveDecline = async (
    props,
    { declineReason = "" },
    onClose,
    string
  ) => {
    if (string === APPROVE_HANDLE_STRING.APPROVE) {
      setAmountDetails(true);
      setBoardId(props._id);
      setBuildName(props.buildId?.name);
    } else {
      let request = { ...API_BOARD_MANAGEMENT.approveOrDecline };
      request.url = request.url + props._id + `?lng=${LOCALISATION.ENGLISH}`;
      if (Object.keys(declineReason).length > 0) {
        request.obj = {
          declineReason,
          isVerified: false,
          buildName: props.buildId?.name,
        };
      }
      UtilService.callApi(request, function ({ data, code, message }) {
        if (data && code === API_STATUS.OK) {
          fetch();
          toast.success(message);
        } else {
          toast.error(message);
        }
      });
    }
  };

  const fetch = () => {
    // api data
    let request = { ...API_BOARD_MANAGEMENT.paginate };
    request.obj = filters;
    UtilService.callApi(request, function ({ data = {}, code }) {
      if (code === API_STATUS.OK) {
        const { docs } = data;
        setMasterData(docs);
        setTotalPages(Math.ceil(data.totalDocs / filters.options.limit));
      } else {
        setMasterData([]);
        setTotalPages(1);
      }
    });
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
    let key = BOARD_MANAGEMENT.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.page = 1;
    filterToSet.filter["isActive"] = key.isActive;
    filterToSet.filter["isDeleted"] = key.isDeleted;
    filterToSet.filter["isVerified"] = key.isVerified;
    setActionColumn(key.action);
    setShowReason(key.reason);
    setFilters(filterToSet);
    setApproveDecline(key.approveDecline);
    setDeleteAction(key.delete);
    setAmountColumn(key.amount);
    fetch();
  };

  const handlePopUpDetails = (data, title) => {
    if (title === BUILDING_BOARD_MANAGEMENT.DECLINE) {
      setModalDetails(data);
      setModalTitle(title);
    } else setPopupDetail(data);
  };

  const handleDelete = (id) => {
    let request = { ...API_BOARD_MANAGEMENT.delete };
    request.url = request.url + id;
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        fetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const handleBoardMember = (open, details) => {
    setBoardMemberModal(open);
    setBoardMemberDetails(details);
  };

  const columns = [
    // table columns
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.options.page - 1) * filters.options.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: BUILDING_BOARD_MANAGEMENT.BUILDING,
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={BUILDING_BOARD_MANAGEMENT.BUILDING}
            onClick={() =>
              handlePopUpDetails(original, BUILDING_BOARD_MANAGEMENT.BUILDING)
            }
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
    },
    {
      Header: "CVR",
      accessor: "buildId.cvr",
      
    },
    {
      Header: "Amount",
      accessor: "platObj.platFee",
      show: amountColumn,
    },
    {
      Header: "Expiration date",
      Cell: ({ original }) => {
        return original.expirationDate
          ? moment(original.expirationDate).format("DD/MM/YYYY")
          : "";
      },
      show: amountColumn,
    },
    {
      Header: "Board Member",
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={"Board member details"}
            onClick={() => handleBoardMember(true, original)}
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
      show: amountColumn,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original = {} }) => {
        return original
          ? original?.emails[0]
            ? original?.emails[0]?.email
            : ""
          : "";
      },
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ original = {} }) => {
        return original
          ? (original?.mobiles?.[0] ? original?.mobiles?.[0]?.countryCode : "") +
              " " +
              (original?.mobiles?.[0] ? original?.mobiles?.[0]?.mobile : "")
          : "";
      },
    },
    {
      Header: "Payment",
      Cell: ({ original = {} }) => {
        if (original.didPaidFee) {
          return "Completed";
        }
        return "Remaining";
      },
    },
    {
      Header: BUILDING_BOARD_MANAGEMENT.DECLINE,
      Cell: ({ original = {} }) => {
        return (
          <ToolTipCommon
            title={BUILDING_BOARD_MANAGEMENT.DECLINE}
            onClick={() =>
              handlePopUpDetails(original, BUILDING_BOARD_MANAGEMENT.DECLINE)
            }
          >
            <Eye
              className="actions-icon action-primary"
              style={{ width: 22 }}
            />
          </ToolTipCommon>
        );
      },
      show: showReason,
    },
  ];
  // name, email, phone, company name
  return (
    <Fragment>
      <Helmet>
        <title>Board management</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="flex-wrap pageBodyHeader d-flex justify-content-between align-items-center">
              <div>
                <h5>Board management</h5>
                <span></span>
              </div>
              <div className="flex-wrap d-flex align-items-right">
                <div className="d-flex">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by name, phone, email, CVR , building "}
                  />
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={BOARD_MANAGEMENT}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {BOARD_MANAGEMENT.map((d, i) => {
                return activeTab === d.id ? (
                  <HRightTab key={i} tabId={d.id}>
                    <div className="search-page profile-page">
                      <div className="PageBodyWrapper">
                        <div className="pageBodyContent product-page-main">
                          <div className="table-responsive support-table">
                            <div className="search-page profile-page">
                              <div className="PageBodyWrapper">
                                <div className="pageBodyContent product-page-main">
                                  <div className="table-responsive support-table">
                                    <Datatable
                                      columns={columns}
                                      myData={masterData}
                                      pagination={true}
                                      pageSize={filters.options.limit}
                                      page={filters.options.page}
                                      class="-striped -highlight"
                                      handleChange={handleFilterChange}
                                      pages={totalPages}
                                      handleSort={handleSort}
                                      fetch={fetch}
                                      model={MODEL.VERSION}
                                      actions={{
                                        canDelete: deleteAction,
                                        canApprove: approveDecline,
                                        canDecline: approveDecline,
                                      }}
                                      actionColumn={actionColumn}
                                      handleApproveDecline={
                                        handleApproveDecline
                                      }
                                      handleDelete={handleDelete}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </HRightTab>
                ) : null;
              })}
            </Navigation>
          </div>
        </div>
      </div>
      {popupDetail && (
        <DetailsModalCommon
          id={popupDetail.buildId}
          onClose={() => setPopupDetail(false)}
        />
      )}
      {modalDetails && (
        <Details
          title={modalTitle}
          child={modalDetails.decReason}
          onClose={() => setModalDetails("")}
        />
      )}
      {amountDetails && (
        <AmountModal
          title={"Enter approval details"}
          approveAmount={approveAmount}
          onClose={() => setAmountDetails(false)}
        />
      )}
      {boardMemberModal && (
        <BoardMemberModal
          data={boardMemberDetails}
          onClose={() => setBoardMemberModal(false)}
        />
      )}
    </Fragment>
  );
};
export default Board;
