import React, { Fragment, useEffect, useState } from "react";
import { MapPin } from "react-feather";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { API_PROPERTY_PROFILE } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  ROOM_TYPE,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import OptionTab from "./optionsTab";
import SimpleImageSlider from "react-simple-image-slider";
import { API_URL } from "../../constant/common";
import { formateAmount } from "../../utils/helper";

const Details = (props) => {
  const { id } = props;
  const [data, setData] = useState();
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetch = () => {
    let request = { ...API_PROPERTY_PROFILE.view };
    request.url = request.url + id;
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        setData(res.data);
      } else {
        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
      }
    });
  };

  return (
    <Fragment>
      <Button className="m-l-10" color="primary">
        {props.title}
      </Button>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{"Property Details"}</ModalHeader>
        <ModalBody>
          {data && (
            <div>
              <div className="mb-3">
                <div>
                  <h5>
                    <strong>{data ? data.housingName : ""}</strong>
                  </h5>
                </div>
                <div className="d-flex">
                  <MapPin
                    className="fillSvgColor mr-1"
                    style={{ width: 20, height: 20 }}
                  />
                  {data &&
                    `${data.street ? data.street : ""} 
                    ${data.streetNumber ? data.streetNumber : ""} 
                    ${data.floorNo ? data.floorNo : ""} 
                    ${data.side ? data.side + "," : ""} 
                    ${data.zip ? data.zip : ""}
                    ${data.city ? data.city : ""} `}
                </div>
              </div>
              <hr />
              <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                <h5>
                  <strong> Current Value </strong>
                </h5>
              </div>
              <div className="m-10 p-10">
                <div className="d-flex row">
                  <div style={{ width: "30%" }}>
                    <strong>Total Investments</strong>
                    <h6 className="color-grey">
                      {data.propertySummary &&
                        data.propertySummary.totalInvestment
                        ? formateAmount(data.propertySummary.totalInvestment) + " Kr."
                        : "-"}
                    </h6>
                  </div>
                  <div style={{ width: "30%" }}>
                    <strong>Apartments</strong>
                    <h6 className="color-grey">
                      {data.propertySummary &&
                        data.propertySummary.propertyAmount
                        ? formateAmount(data.propertySummary.propertyAmount) + " Kr."
                        : "-"}
                    </h6>
                  </div>
                  <div style={{ width: "30%" }}>
                    <strong>Improvements</strong>
                    <h6 className="color-grey">
                      {data.propertySummary &&
                        data.propertySummary.improvementAmount
                        ? formateAmount(data.propertySummary.improvementAmount) + " Kr."
                        : "-"}
                    </h6>
                  </div>
                </div>
                <div className="d-flex row">
                  <div style={{ width: "30%" }}>
                    <strong>Movables</strong>
                    <h6 className="color-grey">
                      {data.propertySummary &&
                        data.propertySummary.movableAmount
                        ? formateAmount(data.propertySummary.movableAmount) + " Kr."
                        : "-"}
                    </h6>
                  </div>
                  <div style={{ width: "30%" }}>
                    <strong>Furniture</strong>
                    <h6 className="color-grey">
                      {data.propertySummary &&
                        data.propertySummary.furnitureAmount
                        ? formateAmount(data.propertySummary.furnitureAmount) + " Kr."
                        : "-"}
                    </h6>
                  </div>
                </div>
              </div>
              <hr />
              <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                <h5>
                  <strong> Property Attributes</strong>
                </h5>
              </div>
              <div className="default-according style-1 faq-accordion">
                {data.rooms && data.rooms.length
                  ? data.rooms.map((d, i) => {
                    return (
                      <OptionTab
                        key={i}
                        id={i}
                        question={
                          (d.numberOfRooms ? d.numberOfRooms : 0) +
                          " " +
                          ROOM_TYPE[d.roomType]
                        }
                      >
                        {d.roomData && d.roomData
                          ? d.roomData.map((ds) => {
                            return (
                              <>
                                <div>
                                  <strong>&#8226; {ds.name} </strong>
                                  {ds.improvements &&
                                    ds.improvements.length ? (
                                    ds.improvements.map((id, i) => {
                                      let im = data.improvements.filter(
                                        (e) => e._id === id._id
                                      )[0];
                                      let path = [];
                                      if (id.invoice && id.invoice.length) {
                                        id.invoice.map((inv) => {
                                          path.push({
                                            url: `${API_URL}${inv}`,
                                          });
                                        });
                                      }
                                      if (id.photos && id.photos.length) {
                                        id.photos.map((ph) => {
                                          path.push({
                                            url: `${API_URL}${ph}`,
                                          });
                                        });
                                      }
                                      return (
                                        <>
                                          {" "}
                                          <div className="d-flex align-items-center property-index">
                                            <div className="w-50">
                                              <div>
                                                {" "}
                                                Improvement Type :{" "}
                                                {im.improvementTypeId
                                                  ? im.improvementTypeId
                                                    .name
                                                  : " -"}{" "}
                                              </div>
                                              <div>
                                                {" "}
                                                Item Name :{" "}
                                                {im.itemName
                                                  ? im.itemName
                                                  : " -"}
                                              </div>
                                              <div>
                                                {" "}
                                                Depreciation Model :{" "}
                                                {im.improvementModel
                                                  ? im.improvementModel.name
                                                  : " -"}{" "}
                                              </div>
                                              <div>
                                                {" "}
                                                Date :{" "}
                                                {im.date
                                                  ? CommonService.getDate(
                                                    im.date
                                                  )
                                                  : " -"}
                                              </div>
                                              <div>
                                                {" "}
                                                Amount :{" "}
                                                {im.amount
                                                  ? im.amount + " Kr."
                                                  : " -"}
                                              </div>
                                              <div>
                                                {" "}
                                                Improvements Amount :
                                                {im.depreciationCurrent
                                                  ? Math.round(im.depreciationCurrent.amount) + " Kr."
                                                  : " -"}{" "}
                                                |{" "}
                                                {im.depreciationCurrent
                                                  ? im.depreciationCurrent.depreciationPercentage.toFixed(
                                                    2
                                                  ) + " %"
                                                  : " -"}{" "}
                                              </div>
                                            </div>
                                          </div>
                                          {path && path.length !== 0 && (
                                            <SimpleImageSlider
                                              style={{ marginLeft: "70px" }}
                                              width={500}
                                              height={200}
                                              images={path}
                                              showBullets
                                            />
                                          )}
                                        </>
                                      );
                                    })
                                  ) : (
                                    <div className={"property-index"}>
                                      No Improvements Added.
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })
                          : ""}
                      </OptionTab>
                    );
                  })
                  : ""}
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Details;
