import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import useForm from 'react-hook-form';
import OtpInput from 'react-otp-input';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import Logo from '../../components/common/logo';
import { API_USER } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
const ForgetPassword = (props) => {
    const [otpSent, setOTPSent] = useState(false)//otp sent or not
    const [otp, setOTP] = useState()
    const [otpMessage, setOTPMessage] = useState()
    const { register, handleSubmit, errors } = useForm({ mode: "onChange" }); // initialise the hook
    const onSubmit = data => {
        if (data !== '') {
            //API call
            if (otpSent) {
                if (!otp) {
                    setOTPMessage("Please enter OTP")
                    return
                }
                else if (otp && otp.toString().length < 6) {
                    setOTPMessage("OTP should contain 6 digits.")
                    return
                }
                let request = { ...API_USER.resetPasswordOTP };
                request.obj = { ...data, otp: otp };
                UtilService.callApi(request, function (res) {
                    if (res && res.data && res.code === API_STATUS.OK) {
                        toast.success(res.message)
                        props.history.push({
                            pathname: '/reset-password',
                            state: { email: data.email, otp: otp }
                        })
                    }
                    else {
                        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                    }
                });
            }
            else {
                let request = { ...API_USER.forgetPassword };
                request.obj = { ...data };
                UtilService.callApi(request, function (res) {
                    if (res && res.data && res.code === API_STATUS.OK) {
                        setOTPSent(true)
                        toast.success(res.message)
                    }
                    else {
                        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                    }
                });
            }

        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            <Helmet>
                <title>Forget Password</title>
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <Logo logoSize={'md'} />
                                            <h3 className={'p-t-15'}>
                                                {"Domus"}
                                            </h3>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Forget Password</h4>
                                                    {/* <h6> Click on send OTP to get OTP on entered email ID.</h6> */}
                                                </div>
                                                <form className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group form-row">
                                                        <label htmlFor="validationCustom01"
                                                            className="col-form-label pt-0">EMAIL ID</label>
                                                        <input
                                                            placeholder="Enter Email ID"
                                                            className="form-control"
                                                            onKeyUp={(event) => {
                                                                let k = event ? event.which : window.event.keyCode;
                                                                if (k === 32) return false;
                                                            }}
                                                            name="email"
                                                            disabled={otpSent}
                                                            ref={register({
                                                                required: true, pattern: {
                                                                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                                                    message: "Email address is not valid."
                                                                }
                                                            })}
                                                            type="text" />
                                                        <span className="color-red">{errors.email && (errors.email.message ? errors.email.message : 'Please enter email ID.')}</span>
                                                        {/* <div className="valid-feedback">Looks good!</div> */}
                                                    </div>
                                                    {otpSent && <div className="form-group form-row">
                                                        <label className="col-form-label">OTP</label>
                                                        <div className="w-100">
                                                            <OtpInput
                                                                containerStyle="w-100 otp-input-box"
                                                                inputStyle="form-control otp-input m-r-20 p-0"
                                                                onChange={otp => setOTP(otp.toString())}
                                                                value={otp}
                                                                numInputs={6}
                                                            />
                                                            <span className="color-red">{otpMessage ? otpMessage : ""}</span>
                                                        </div>

                                                    </div>}
                                                    <div className="form-grouform-rowp form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit">{otpSent ? "Submit" : "Send OTP"}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </Fragment >
    );
};

export default withRouter(ForgetPassword)
