import React, { Fragment, useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { API_DEPRECIATION } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
import MultiLanguage from '../common/MultiLanguage';
const Upsert = (props) => {
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const { edit } = props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const prevEdit = usePrevious({ edit });
    const [modal8, setModal8] = useState();
    const [defaultLanguage, setDefaultLanguage] = useState()
    const [selectedLanguage, setSelectedLanguage] = useState()
    const [multiLanguageData, setMultiLanguageData] = useState()
    const { register, handleSubmit, errors, setValue, reset } = useForm({ mode: 'onChange' });
    const toggle8 = () => {
        if (modal8) {

            setMultiLanguageData(null)
            setSelectedLanguage(defaultLanguage)
            props.editDone()
        }
        setModal8(!modal8)
    }
    useEffect(() => {
        register({ name: 'name' }, { required: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (prevEdit && prevEdit.edit !== edit) {
            if (edit) {
                toggle8()
                fetch(edit)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    const fetch = id => {
        let request = { ...API_DEPRECIATION.view };
        request.url = request.url + id
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let { name, depreciationYear, initialDepreciation, initialDepreciationYear, scrapValue, multiLanguageData } = res.data
                setValue("name", name)
                setValue("depreciationYear", depreciationYear)
                setValue("initialDepreciation", initialDepreciation)
                setValue("initialDepreciationYear", initialDepreciationYear)
                setValue("scrapValue", scrapValue)

                if (!multiLanguageData) multiLanguageData = {}
                multiLanguageData[defaultLanguage] = {
                    "name": name
                }
                setMultiLanguageData(multiLanguageData)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }
    const onSubmit = data => {
        if (data !== '') {
            let request = edit ? { ...API_DEPRECIATION.edit } : { ...API_DEPRECIATION.create };
            request.url = edit ? request.url + edit : request.url
            request.obj = { ...data };
            request.obj.multiLanguageData = multiLanguageData
            if (props.parentId) request.obj.parentId = props.parentId
            UtilService.callApi(request, function (res) {
                if (res && res.data && (res.code === API_STATUS.CREATED || res.code === API_STATUS.OK)) {
                    toggle8()
                    props.fetch()
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };


    return (
        <Fragment>
            <Button className="m-l-10" color="primary" onClick={toggle8}>{props.title}</Button>
            <Modal isOpen={modal8} toggle={toggle8} size="lg" centered={true}>
                <ModalHeader toggle={toggle8}>{edit ? "Edit Depreciation" : "Add Depreciation Model"}</ModalHeader>
                <ModalBody>
                    <form className="form theme-form add-form" onSubmit={handleSubmit(onSubmit)}>
                        <MultiLanguage
                            setSelectedLanguage={(lang) => setSelectedLanguage(lang)}
                            setDefaultLanguage={(lang) => setDefaultLanguage(lang)}
                            defaultLanguage={defaultLanguage}
                            selectedLanguage={selectedLanguage}
                        />
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Model Name <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input
                                    value={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].name ? multiLanguageData[selectedLanguage].name : ""}
                                    className="form-control"
                                    onChange={e => {
                                        let var1 = { "name": e.target.value }
                                        let val = {
                                            [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
                                        }
                                        setMultiLanguageData({ ...multiLanguageData, ...val })
                                        if (selectedLanguage === defaultLanguage) {
                                            setValue("code", e.target.value.split(" ").join("").toUpperCase())
                                            setValue("name", e.target.value)
                                        }
                                    }
                                    }
                                    type="text" placeholder="Enter Model Name" />
                                <span className="color-red">{errors.name && 'Please enter model name for english language.'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Depreciation Year <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="depreciationYear" className="form-control" min={0}
                                    ref={register({
                                        required: true, min: { value: 0, message: "Depreciation Year must be greater than 0." }
                                    })} type="Number" placeholder="Enter the number of years the item is depreciated" />
                                <span className="color-red">{errors.depreciationYear && (errors.depreciationYear.message ? errors.depreciationYear.message : 'Please enter the number of years.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Initial Depreciation <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="initialDepreciation" className="form-control" min={0} max={100}
                                    ref={register({
                                        required: true, min: { value: 0, message: "Initial Depreciation percentage must be greater than 0." }, max: { value: 100, message: "Initial Depreciation percentage must be less than 100." }
                                    })} type="Number" placeholder="Enter the (%) of intial depreciation" />
                                <span className="color-red">{errors.initialDepreciation && (errors.initialDepreciation.message ? errors.initialDepreciation.message : 'Please enter the initial depreciation.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Initial Depreciation Year <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="initialDepreciationYear" className="form-control" min={0}
                                    ref={register({
                                        required: true, min: { value: 0, message: "Initial Depreciation Year must be greater than 0." }
                                    })} type="Number" placeholder="Enter the initial years where inital depreciation is depreciated" />
                                <span className="color-red">{errors.initialDepreciationYear && (errors.initialDepreciationYear.message ? errors.initialDepreciationYear.message : 'Please enter the number of years.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Scrap Value <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="scrapValue" className="form-control" min={0} max={100}
                                    ref={register({
                                        required: true, min: { value: 0, message: "Scrap Value percentage must be greater than 0." }, max: { value: 100, message: "Scrap Value percentage must be less than 100." }
                                    })} type="Number" placeholder="Enter the scrap value (%)" />
                                <span className="color-red">{errors.scrapValue && (errors.scrapValue.message ? errors.scrapValue.message : 'Please enter the scrap value.')}</span>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>
                    <Button onClick={() => {
                        reset()
                        setMultiLanguageData(null)
                        setSelectedLanguage(defaultLanguage)
                    }} outline={true} color="primary">Reset</Button>
                    <Button outline={true} color="primary" onClick={toggle8}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default Upsert;
