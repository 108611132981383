import React, { Fragment, useEffect, useState } from "react";
import { MapPin } from "react-feather";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import CommonService from "../../constant/commonService";
import ImageByGender from "../../components/common/ImageByGender";

const Details = (props) => {
  const { id } = props;
  const [data, setData] = useState();
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const fetch = () => {
    setData(props.id);
  };

  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>
          {"Customers Details (Only customer can change the information)"}
        </ModalHeader>
        <ModalBody>
          {data && (
            <div>
              <div className="mb-3">
                <div>
                  <h5>
                    {" "}
                    <strong>{data ? data.housingName : ""}</strong>{" "}
                  </h5>
                </div>
                <div className="d-flex">
                  <ImageByGender
                    ImageClass="img-50 img-fluid height-50-px rounded-circle"
                    src={data.image}
                    gender={data.gender}
                  />
                </div>
              </div>
              <hr />

              <div className="m-10 p-10">
                <div className="d-flex row">
                  <div style={{ width: "30%" }}>
                    <strong>Name</strong>
                    <h6 className="color-grey">
                      {data && data.name ? data.name : "-"}
                    </h6>
                  </div>
                  <div style={{ width: "30%" }}>
                    <strong>Mobile No</strong>
                    <h6 className="color-grey">
                      {data.mobiles &&
                      data.mobiles.length != 0 &&
                      data.mobiles[0].countryCode
                        ? data.mobiles[0].countryCode
                        : ""}
                      {data.mobiles &&
                      data.mobiles.length != 0 &&
                      data.mobiles[0].mobile
                        ? data.mobiles[0].mobile
                        : "-"}
                    </h6>
                  </div>
                  <div style={{ width: "30%" }}>
                    <strong>Email Address</strong>
                    <h6 className="color-grey">
                      {data.emails &&
                      data.emails.length != 0 &&
                      data.emails[0].email
                        ? data.emails[0].email
                        : "-"}
                    </h6>
                  </div>
                </div>
                <div className="d-flex row">
                  <div style={{ width: "30%" }}>
                    <strong>Registration Date</strong>
                    <h6 className="color-grey">
                      {data.createdAt
                        ? CommonService.getDate(data.createdAt) + " "
                        : " "}
                      {data.createdAt
                        ? CommonService.getTime(data.createdAt)
                        : "-"}
                    </h6>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Details;
