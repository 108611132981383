import React, { useState } from "react";
import ToolTipCommon from "../ToolTip";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { ASSURER_MANAGEMENT } from "../../../constant/common";

const Approve = (props) => {
  const {id,handleApproveDecline,activeTabId,handleApproveExtraJobs,} = props
  const [show, showModal] = useState(false);

  const onClose = () => {
    showModal(false);
  };

  return (
    <>
      <ToolTipCommon
        onClick={() =>{
        if(activeTabId == ASSURER_MANAGEMENT.ASSURER_EXTRA_JOB_REQUEST){
        return  handleApproveExtraJobs(id, {}, onClose, "Approve")
        }else{
        return  handleApproveDecline(id, {}, onClose, "Approve")

        }

        }}
        title="Approve"
      >
        <a
          //  href="#"
          className="d-flex align-items-center text-decline bg-success py-1 px-2 mr-2 cursor-pointer"
        >
          Approve
        </a>
      </ToolTipCommon>

      <Modal isOpen={show} toggle={onClose} size="lg" centered={true}>
        <ModalHeader toggle={onClose}>{"Approve"}</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label for="exampleInputEmail1">Amount</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter amount"
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary">Save</Button>
          <Button outline={true} color="primary">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Approve;
