import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Datatable from "../../components/common/DataTable";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import Upsert from "../../components/Version/upsert";
import { API_VERSION, API_CHECKLIST } from "../../constant/apiConstants";
import { Button } from "reactstrap";
import {
  API_STATUS,
  DEFAULT_PAGE_SIZE,
  MODEL,
  VERSION_PLATFORM,
  CHECKLIST_TYPE,
  VERSION_STATUS_TAB4,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import ToolTipCommon from "../../components/common/ToolTip";
import { Eye } from "react-feather";
import AddAndEditChecklist from "./AddAndEditChecklist.js";

const Checklist = (props) => {
  const [masterData, setMasterData] = useState([]); // data list api
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [activeTab, setActiveTab] = useState(CHECKLIST_TYPE.ATTRIBUTE);
  const [popupDetail, setPopupDetail] = useState();
  const [addChecklistModal, setAddChecklistModal] = useState();
  const [editOrAdd, setEditOrAdd] = useState('add');
  const [showAttribute, setShowAttribute] = useState(true)

  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {
        type: CHECKLIST_TYPE.ATTRIBUTE,
      },
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      search: {
        keys: ["name"],
        keyword: "",
      },
    },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);
  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const handleFilterChange = (limit) => {
    // page and limit filter change from data table
    let filter = { ...filters, ...limit };
    setFilters(filter);
  };
  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.page = 1;
    filter.sort = sort;
    setFilters(filter);
    fetch();
  };
  const search = (searchVal) => {
    // handle search filters from search component
    let filter = filters;
    filter.search.keyword = searchVal;
    filter.page = 1;
    setFilters(filter);
    fetch();
  };
  const fetch = () => {
    // api data
    let request = { ...API_CHECKLIST.paginate };
    request.obj = filters;
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        setMasterData(res.data.list);
        setTotalPages(Math.ceil(res.data.count / filters.limit));
      } else {
        setMasterData([]);
        setTotalPages(1);
      }
    });
  };
  const handleTabChange = (id) => {
    setActiveTab(id);
    if (id === CHECKLIST_TYPE.ATTRIBUTE) {
      setShowAttribute(true)
    } else {
      setShowAttribute(false)
    }
    let key = VERSION_STATUS_TAB4.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.page = 1;
    filterToSet.filter.type = key.value
    setFilters(filterToSet);
    fetch();
  };
  const handleEdit = (data) => {
    setAddChecklistModal(true);
    setEditOrAdd('edit');
    setPopupDetail(data);
  }
  const handleAddCheckListButton = () => {
    setAddChecklistModal(true);
    setEditOrAdd("add");
    setPopupDetail("");
  }

  const columns = [
    // table columns
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
    }, {
      Header: "Attribute",
      accessor: "attrId.name",
      Cell: ({ original={} }) => {
        return (original.attrId?.name ||"")
      },
      show: showAttribute
    }
  ];
  return (
    <Fragment>
      <Helmet>
        <title>Checklist</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="flex-wrap pageBodyHeader d-flex justify-content-between align-items-center">
              <div>
                <h5>Checklist</h5>
                <span></span>
              </div>
              <div className="flex-wrap d-flex align-items-right">
                <div className="d-flex">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by name."}
                  />
                  <Button
                    color="primary"
                    onClick={handleAddCheckListButton}
                  >
                    Add Checklist
                  </Button>
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={VERSION_STATUS_TAB4}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {VERSION_STATUS_TAB4.map((d, i) => {
                return activeTab === d.id ? (
                  <HRightTab key={i} tabId={d.id}>
                    <div className="search-page profile-page">
                      <div className="PageBodyWrapper">
                        <div className="pageBodyContent product-page-main">
                          <div className="table-responsive support-table">
                            <div className="search-page profile-page">
                              <div className="PageBodyWrapper">
                                <div className="pageBodyContent product-page-main">
                                  <div className="table-responsive support-table">
                                    <Datatable
                                      columns={columns}
                                      myData={masterData}
                                      pagination={true}
                                      pageSize={filters.limit}
                                      page={filters.page}
                                      class="-striped -highlight"
                                      handleChange={handleFilterChange}
                                      pages={totalPages}
                                      handleSort={handleSort}
                                      fetch={fetch}
                                      model={MODEL.VERSION}
                                      actions={{
                                        canDelete: true,
                                        canEditData: true,
                                      }}
                                      edit={(res) => handleEdit(res)}
                                      externalDelete={API_CHECKLIST.delete}
                                      tittle={'Checklist'}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </HRightTab>
                ) : null;
              })}
            </Navigation>
          </div>
        </div>
      </div>
      {addChecklistModal && (
        <AddAndEditChecklist
          editData={popupDetail}
          onClose={() => setAddChecklistModal(false)}
          editOrAdd={editOrAdd}
          fetchListing={fetch}

        />
      )}
    </Fragment>
  );
};
export default Checklist;
