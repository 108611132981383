import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Datatable from '../../components/common/DataTable';
import Navigation from '../../components/common/Navigation';
import HRightTab from '../../components/common/Navigation/hRightTab';
import SearchBar from '../../components/common/SearchBar';
import Upsert from '../../components/Version/upsert';
import { API_VERSION } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_PAGE_SIZE, MODEL, VERSION_PLATFORM, VERSION_STATUS_TAB } from '../../constant/common';
import CommonService from '../../constant/commonService';
import UtilService from '../../services/common.service';
const Version = (props) => {
    const [masterData, setMasterData] = useState([]); // data list api
    const [totalPages, setTotalPages] = useState(1) // total pages for pagination
    const [edit, setEditKey] = useState(); // edit record..
    const [activeTab, setActiveTab] = useState(VERSION_PLATFORM.ANDROID);
    const initialApiFilter = { // filter to send to api
        filter: {
            filter: {
                isDeleted: false,
                platform: VERSION_PLATFORM.ANDROID
            },
            page: 1,
            limit: DEFAULT_PAGE_SIZE,
            search: {
                keys: ["name", "number"],
                keyword: ""
            }
        }
    }

    const [filters, setFilters] = useState(initialApiFilter.filter)
    useEffect(() => { // call api on filter change 
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handleFilterChange = (limit) => { // page and limit filter change from data table
        let filter = { ...filters, ...limit }
        setFilters(filter)
    }
    const handleSort = (sort) => { // handle sort change from data table
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const search = (searchVal) => { // handle search filters from search component
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = () => { // api data 
        let request = { ...API_VERSION.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                setTotalPages(Math.ceil(res.data.count / filters.limit))
            }
            else {
                setMasterData([])
                setTotalPages(1)
            }
        })
    }
    const handleTabChange = (id) => {
        setActiveTab(id)
        let key = VERSION_STATUS_TAB.filter((d) => {
            return d.id === id
        })[0]
        let filterToSet = filters
        filterToSet.page = 1
        filterToSet.filter[key.key] = key.value
        setFilters(filterToSet)
        fetch()
    }
    const columns = [ // table columns
        {
            Header: "No.",
            id: "_id",
            Cell: row => {
                return (row.index + 1) + ((filters.page - 1) * filters.limit)
            },
            sortable: false,
            resizable: false,
            width: 50
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Version",
            accessor: "number"
        },
        {
            Header: "Date",
            id: "createdAt",
            Cell: row => {
                return <div><div>{CommonService.getDate(row.original.createdAt)}</div><div>{CommonService.getTime(row.original.createdAt)}</div></div>
            }
        },
        {
            Header: "Update",
            id: "isHardUpdate",
            accessor: d => d.isHardUpdate ? "Hard Update" : "Soft Update",
            sortable: false
        },
    ]
    return (
        <Fragment>
            <Helmet>
                <title>App Version</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                            <div>
                                <h5>App Version</h5><span></span>
                            </div>
                            <div className="d-flex flex-wrap align-items-right">
                                <div className="m-r-10" >
                                    <SearchBar handleSearch={search} title={"Search by name and version."} />
                                </div>
                                <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={"Add Version"} />
                            </div>
                        </div>
                        <Navigation
                            TabType="borderb-tab-primary m-b-0"
                            data={VERSION_STATUS_TAB}
                            setActiveTab={handleTabChange}
                            activeTab={activeTab}
                        >
                            {VERSION_STATUS_TAB.map((d, i) => {
                                return activeTab === d.id ? <HRightTab key={i} tabId={d.id}>
                                    <div className="search-page profile-page">
                                        <div className="PageBodyWrapper">
                                            <div className="pageBodyContent product-page-main">
                                                <div className="table-responsive support-table">
                                                    <Datatable
                                                        columns={columns}
                                                        myData={masterData}
                                                        pagination={true}
                                                        pageSize={filters.limit}
                                                        page={filters.page}
                                                        class="-striped -highlight"
                                                        handleChange={handleFilterChange}
                                                        pages={totalPages}
                                                        handleSort={handleSort}
                                                        fetch={fetch}
                                                        model={MODEL.VERSION}
                                                        actions={{ canDelete: true, canEdit: true, canActive: true }}
                                                        edit={(id) => { setEditKey(id) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </HRightTab> : null
                            })}
                        </Navigation>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
export default Version;