import React, { Fragment, useEffect, useState } from 'react';
import { TabPane } from 'reactstrap';
import { API_MASTER } from '../../../constant/apiConstants';
import { API_STATUS } from '../../../constant/common';
import UtilService from '../../../services/common.service';
import VerticalNavigation from '../../common/Navigation/VerticalNavigation';
import SubMaster from "./submaster/index";

const DataBank = () => {
    const [activeTab, setActiveTab] = useState();
    const [masterData, setMasterData] = useState([]);
    const [filters, setFilters] = useState({
        filter: {
            isDeleted: false,
            parentId: null
        },
        sort: { "sortingSequence": "ASC" },
        page: 1,
        search: {
            keys: ["name", "code"],
            keyword: ""
        }
    })
    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const search = (searchVal) => {
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = (fromPrev) => {
        let request = { ...API_MASTER.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                fromPrev ? setActiveTab(activeTab) : setActiveTab(res.data.list && res.data.list.length ? res.data.list[0]._id : "")
            }
            else {
                setMasterData([])
                setActiveTab("")
            }
        })
    }
    return (
        <Fragment>
            <div className="">
                <div className="row">
                    <VerticalNavigation
                        activeTab={activeTab}
                        handleSearch={search}
                        TabType="vertical-tabset nav-pills nav-primary m-t-15"
                        data={masterData && masterData.length ? masterData.map((d) => {
                            return { id: d._id, name: d.name, count: "(" + (d.submasterCount ? d.submasterCount : "0") + ")" }
                        }) : []}
                        setActiveTab={setActiveTab} >
                        {masterData && masterData.length && masterData.map((d, i) => {
                            return <TabPane key={i} tabId={d._id}>{activeTab === d._id ? <SubMaster fetch={() => fetch(true)} id={d._id} name={d.name} /> : null}</TabPane>
                        })}
                    </VerticalNavigation>
                </div>
            </div>
        </Fragment>
    )
}
export default DataBank;