import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import 'react-image-lightbox/style.css';
import Navigation from '../../components/common/Navigation';
import HRightTab from "../../components/common/Navigation/hRightTab";
import Configuration from '../../components/setting/Configuration';
import DataBank from '../../components/setting/DataBank';
import Master from '../../components/setting/Master';
import { SETTINGS, SETTINGS_MENU } from '../../constant/common';

const Setting = (props => {
    const [activeTab, setActiveTab] = useState(SETTINGS.MASTER);
    return (
        <Fragment>
            <Helmet>
                <title>Settings</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyHeader">
                        <h5>Settings</h5><span></span>
                    </div>
                    <div className="pageBodyContent product-page-main">
                        <Navigation
                            TabType="borderb-tab-primary"
                            data={SETTINGS_MENU}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab} >
                            <HRightTab tabId={SETTINGS.MASTER}>{activeTab === SETTINGS.MASTER ? <Master /> : null}</HRightTab>
                            <HRightTab tabId={SETTINGS.DATA_BANK}>{activeTab === SETTINGS.DATA_BANK ? <DataBank /> : null}</HRightTab>
                            <HRightTab tabId={SETTINGS.CONFIG}>{activeTab === SETTINGS.CONFIG ? <Configuration /> : null}</HRightTab>
                        </Navigation>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    // }
});

export default Setting;