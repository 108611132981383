import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API_MASTER } from '../../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR, DEFAULT_PAGE_SIZE, MODEL } from '../../../constant/common';
import UtilService from '../../../services/common.service';
import ActiveDeactive from '../../common/ActiveDeactive';
import DataTable from '../../common/DataTable';
import SearchBar from '../../common/SearchBar';
import Upsert from './upsert';

const Master = () => {
    const [masterData, setMasterData] = useState([]);
    const [edit, setEditKey] = useState();
    const [filters, setFilters] = useState({
        filter: {
            isDeleted: false,
            parentId: null
        },
        sort: { "sortingSequence": "ASC" },
        page: 1,
        // limit: DEFAULT_PAGE_SIZE,
        search: {
            keys: ["name", "code"],
            keyword: ""
        }
    })
    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handleFilterChange = (limit) => {
        let filter = { ...filters, ...limit }
        setFilters(filter)
    }
    const handleSort = (sort) => {
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const handleEdit = (edit) => {
        setEditKey(edit)
    }
    const search = (searchVal) => {
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = () => {
        let request = { ...API_MASTER.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
            }
            else {
                setMasterData([])
                // toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    }
    const updateSequence = (oldIndex, newSeq) => { // api data 
        let request = { ...API_MASTER.updateSequence };
        request.url = request.url + masterData.filter((m) => m.sortingSequence === oldIndex)[0]._id
        request.obj = {
            "sortingSequence": newSeq
        }
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                fetch()
            }
            else {
                toast.error(res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    }
    const columns = [
        {
            Header: "No.",
            Cell: row => {
                return (row.index + 1)
            },
            sortable: false
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Code",
            accessor: "code"
        },
        {
            Header: "Active",
            accessor: "isActive",
            Cell: row => {
                return <ActiveDeactive fetch={fetch} id={row.original._id} status={row.original.isActive} model={MODEL.MASTER} />
            },
            sortable: false
        }
    ]
    return (
        <Fragment>
            <div className="m-b-10 d-flex justify-content-between flex-wrap">
                <div>
                    <h6 className="m-b-0">Master</h6>
                    <span></span>
                </div>
                <div className="d-flex align-items-center">
                    <SearchBar title={"Search by name and code."} handleSearch={search} />
                    <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={edit ? "Edit Master" : "Add Master"} />
                </div>
            </div>
            <DataTable
                actions={
                    {
                        canDelete: true,
                        canEdit: true,
                        canDrag: true
                    }
                }
                columns={columns}
                myData={masterData}
                pagination={false}
                pageSize={masterData && masterData.length ? masterData.length : DEFAULT_PAGE_SIZE}
                class="-striped -highlight"
                handleChange={handleFilterChange}
                handleSort={handleSort}
                fetch={fetch}
                model={MODEL.MASTER}
                edit={handleEdit}
                updateSequence={updateSequence}
                sortable={false}
            />
        </Fragment>
    )
}
export default Master;