import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import 'react-image-lightbox/style.css';
import { Nav, TabContent } from 'reactstrap';
import SearchBar from "../SearchBar/index";
import VLeftTab from "./vLeftTab";

const VerticalNavigation = props => {
    return (
        <Fragment>
            <div className="col-sm-4 col-md-4 col-lg-2 col-xs-12 lefttabContent">
                <SearchBar handleSearch={props.handleSearch} />
                <Nav tabs className={props.TabType}>
                    {props.data.map((d, i) => {
                        return (
                            <VLeftTab
                                key={i}
                                count={d.count}
                                conetent={d.name}
                                activeTab={props.activeTab === (d.id) ? 'active d-flex justify-content-between' : 'd-flex justify-content-between '}
                                setActiveTab={() => { props.setActiveTab(d.id) }} />
                        )
                    })}
                </Nav>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-10 col-xs-12">
                <TabContent activeTab={props.activeTab}>
                    {props.activeTab ? props.children : null}
                </TabContent>
            </div>
        </Fragment>
    );
};
VerticalNavigation.propTypes = {
    TabType: PropTypes.string,
};
export default VerticalNavigation;