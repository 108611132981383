import React, { Component, Fragment } from 'react';
import 'react-image-lightbox/style.css';
import { TabPane } from 'reactstrap';

class HRightTab extends Component {
    render() {
        const { tabId } = this.props;
        return (
            <Fragment>
                <TabPane tabId={tabId}>
                    {this.props.children}
                </TabPane>
            </Fragment>
        );
    }
}

export default HRightTab;