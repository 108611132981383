import React, { useState } from 'react';
import { Edit } from "react-feather";
import { Collapse } from 'reactstrap';
import { MODEL } from '../../../constant/common';
import Delete from '../Delete';
import ToolTipCommon from '../ToolTip';


const List = (props) => {
    const [viewAnswer, setViewAnswer] = useState(false);
    const { DragHandle } = props
    return (
        <div className="card" count={props.count}>
            <div className="card-header">
                <h5 className="mb-0 d-flex justify-content-between align-items-center">
                    <button className="btn btn-link collapsed pl-0 f-15" onClick={() => setViewAnswer(!viewAnswer)}
                        data-toggle="collapse" data-target={viewAnswer} aria-expanded={viewAnswer} aria-controls="collapseicon">
                        <span className="m-r-10 f-16 font-weight-bold">{props.count}.</span>
                        {props.question}
                    </button>
                    <div className="m-r-15 d-flex faqActionBtn">
                        <DragHandle />
                        <ToolTipCommon title="Edit" onClick={() => { props.edit(props.id) }}  ><Edit style={{ width: 24, height: 24, padding: 3, color: 'rgb(40, 167, 69)', margin: '0 2px', }} /></ToolTipCommon>
                        <Delete model={MODEL.FAQS} id={props.id} fetch={props.fetch} />
                    </div>
                </h5>
            </div>
            <Collapse isOpen={viewAnswer}>
                <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: props.children }} />
                </div>
            </Collapse>
        </div>
    );
};

export default List;