import React, { Fragment } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useForm from "react-hook-form";
import { API_ORDER_MANAGEMENT } from "../../constant/apiConstants";
import { API_STATUS, LOCALISATION } from "../../constant/common";
import UtilService from "../../services/common.service";
import { toast } from "react-toastify";

const OrderModal = (props) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const { title = "", orderModal } = props;

  const onSubmit = (data) => {
    let request = { ...API_ORDER_MANAGEMENT.approve };
    request.url = request.url + `?lng=${LOCALISATION.ENGLISH}`;
    request.obj = {
      "amount": orderModal.amount,
      "userId": orderModal.userId._id,
      "orderId": orderModal._id,
      ...data,
    };
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        props.fetchList();
        toast.success(message);
        props.onClose();
      } else {
        toast.error(message);
      }
    });
  };

  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{title}</ModalHeader>
        <ModalBody>
          <div className=" style-1 faq-accordion">
            <div className="card">
              <div className="card-header">
                <div class="">
                  <div>
                    <div className="position-relative d-flex align-items-center mb-3">
                      <input
                        name="paymentId"
                        className="form-control"
                        type="text"
                        placeholder="Enter transaction id of order"
                        ref={register({
                          required: { value: true, message: "Transaction id is required!" },
                        })}
                      />
                    </div>
                    <span className="color-red">
                      {errors.paymentId &&
                        (errors.paymentId.message
                          ? errors.paymentId.message
                          : "Please enter Transaction id.")}
                    </span>
                  </div>
                  <div className="text-right">
                    <button
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-primary d-inline-block mt-3"
                    >
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default OrderModal;
