import { DatePickerInput } from "rc-datepicker";
import React, { useState } from 'react';
import 'rc-datepicker/lib/style.css'
import { DATE_FORMAT } from "../../../constant/common";

const RcPicker = ({ callBackDate, ref, ...rest }) => {
    const [date, setDate] = useState()

    const onChangeDate = (date, momentDate) => {
        setDate(momentDate)
        callBackDate(momentDate)
    }
    return (<DatePickerInput
        onChange={onChangeDate}
        locale='en'
        className='rc-custom-datepicker-component'
        showOnInputClick
        value={date}
        closeOnClickOutside
        autoClose
        showInputButton
        floating
        small
        placeholder={DATE_FORMAT}
        // returnFormat={DATE_FORMAT}
        format={DATE_FORMAT}
        displayFormat={DATE_FORMAT}
        ref={ref}
        {...rest}
    />);
}

export default RcPicker;