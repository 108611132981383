import React, { Fragment, useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Datatable from "../DataTable";
import { MODEL } from "../../../constant/common";


const ViewHistory = ({ onClose, myData }) => {
    const columns = [
        {
            Header: "No of jobs.",
            accessor: "noOfJobs",
        },
        {
            Header: "Job Create Price",
            accessor: "newJob",
        },

        {
            Header: "Approve Job Price",
            accessor: "acceptJob",
        },
        {
            Header: "Transaction Number",
            accessor: "transNo",
        },


    ];

    return (
        <Fragment>
            <Modal isOpen={true} toggle={onClose} size="lg" centered={true} scrollable={true} >
                <ModalHeader toggle={onClose}>Request History</ModalHeader>
                <ModalBody style={{
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'auto'
                }}>
                    {/* <div className="form theme-form add-form"> */}
                    <Datatable
                        columns={columns}
                        myData={myData}
                        class="-striped -highlight"
                        model={MODEL.VERSION}
                        pagination={false}


                    />
                    {/* </div> */}
                </ModalBody>

            </Modal>
        </Fragment>
    );
};

export default ViewHistory;
