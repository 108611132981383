import React, { Fragment, useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { API_VERSION } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR, VERSION_PLATFORM } from '../../constant/common';
import UtilService from '../../services/common.service';
const Upsert = (props) => {
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const { edit } = props
    const prevEdit = usePrevious({ edit });
    const [modal8, setModal8] = useState();
    const { register, handleSubmit, errors, setValue } = useForm({ mode: "onChange" });
    const toggle8 = () => {
        if (modal8) props.editDone()
        setModal8(!modal8)
    }

    useEffect(() => {
        if (prevEdit && prevEdit.edit !== edit) {
            if (edit) {
                toggle8()
                fetch(edit)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])
    const fetch = id => {
        let request = { ...API_VERSION.view };
        request.url = request.url + id
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let { name, number, isHardUpdate, platform } = res.data
                setValue("name", name)
                setValue("number", number)
                setValue("platform", platform)
                setValue("isHardUpdate", isHardUpdate)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }
    const onSubmit = data => {
        if (data !== '') {
            let request = edit ? { ...API_VERSION.edit } : { ...API_VERSION.create };
            request.url = edit ? request.url + edit : request.url
            request.obj = { ...data };
            UtilService.callApi(request, function (res) {
                if (res && res.data && (res.code === API_STATUS.CREATED || res.code === API_STATUS.OK)) {
                    toggle8()
                    props.fetch()
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };


    return (
        <Fragment>
            <Button className="m-l-10" color="primary" onClick={toggle8}>{props.title}</Button>
            <Modal isOpen={modal8} toggle={toggle8} size="lg" centered={true}>
                <ModalHeader toggle={toggle8}>{edit ? "Edit Version" : "Add Version"}</ModalHeader>
                <ModalBody>
                    <form className="form theme-form add-form" onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Name <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="name" className="form-control"
                                    ref={register({
                                        required: true
                                    })} type="text" placeholder="Enter Version Name" />
                                <span className="color-red">{errors.name && 'Please enter version name.'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Number <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="number" className="form-control"
                                    ref={register({
                                        required: true,
                                    })} type="number" placeholder="Enter Version Number" />
                                <span className="color-red">{errors.number && 'Please enter version number.'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Platform<span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <select name={"platform"} ref={register({ required: true })} className="drop-down-input">
                                    <option value="" hidden={true}>Select Platform</option>
                                    {Object.keys(VERSION_PLATFORM).map((o, i) => <option key={i} value={VERSION_PLATFORM[o]}>
                                        {o}
                                    </option>)}
                                </select>
                                <span className="color-red">{errors.platform && 'Please select platform.'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Hard Update</label>
                            <div className="col-sm-9 col-md-10 col-form-label">
                                <input
                                    name="isHardUpdate" className="form-control" ref={register} defaultChecked={false} type="checkbox" />
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>
                    <Button outline={true} color="primary" onClick={toggle8}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default Upsert;