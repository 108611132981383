import React, { Fragment, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { API_MASTER, API_USER } from '../../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR, MASTER_CODES } from '../../../constant/common';
import UtilService from '../../../services/common.service';
import Button from "../../common/button/index";


const Address = () => {
    const userData = JSON.parse(localStorage.getItem("user"))
    const [cityMaster, setCityMaster] = useState();
    const [provinceMaster, setProvinceMaster] = useState();
    const { register, handleSubmit, errors, setValue } = useForm({ mode: "onChange" });
    useEffect(() => {
        fetchMasters()
        setDefaultValues()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetchMasters = () => {
        let request = { ...API_MASTER.listByCode };
        request.obj = {
            masters: [
                MASTER_CODES.CITY,
                MASTER_CODES.PROVINCE
            ],
            include: ["subMasters"]
        }

        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let masters = res.data;
                if (masters && masters[MASTER_CODES.PROVINCE] && masters[MASTER_CODES.PROVINCE].subMasters) {
                    setProvinceMaster(masters[MASTER_CODES.PROVINCE].subMasters)
                }
                if (masters && masters[MASTER_CODES.CITY] && masters[MASTER_CODES.CITY].subMasters) {
                    setCityMaster(masters[MASTER_CODES.CITY].subMasters)
                }
            }
        })
    }
    const setDefaultValues = () => {
        setValue("address", userData.address)
        setValue("province", userData.province)
        setValue("city", userData.city)
    }
    const onSubmit = data => {
        if (data !== '') {
            if (!data.province) delete data.province
            if (!data.city) delete data.city
            let request = { ...API_USER.edit }
            request.url = request.url + userData._id
            request.obj = { ...data };
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    localStorage.setItem("user", JSON.stringify(res.data))
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            <form className="form theme-form">
                <div className="">
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">address</label>
                        <div className="col-sm-9 col-md-3">
                            <input name="address" className="form-control" ref={register} type="text" placeholder="Enter Address" />
                            <span className="color-red">{errors.address && 'Please add an address.'}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">City</label>
                        <div className="col-sm-9 col-md-3">
                            <select value={userData.city} name={"city"} ref={register} className="drop-down-input">
                                <option value="" hidden={true}>Select City Name</option>
                                {cityMaster && cityMaster.map((o, i) => <option key={i} selected={o._id === userData.city} value={o._id}>
                                    {o.name}
                                </option>)}
                            </select>
                            <span className="color-red">{errors.city && 'Please select a city.'}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-2 col-form-label">Province</label>
                        <div className="col-sm-9 col-md-3">
                            <select name={"province"} ref={register} className="drop-down-input">
                                <option value="" hidden={true}>Select Province Name</option>
                                {provinceMaster && provinceMaster.map((o, i) => <option key={i} selected={o._id === userData.province} value={o._id}>
                                    {o.name}
                                </option>)}
                            </select>
                            <span className="color-red">{errors.province && 'Please select a province.'}</span>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="">
                        <Button onClick={handleSubmit(onSubmit)} color="primary mr-1">Submit</Button>
                        <Button onClick={setDefaultValues} color="light">Reset</Button>
                    </div>
                </div>
            </form>
        </Fragment >
    )
}
export default Address;