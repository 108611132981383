/* eslint-disable no-undef */
export const API_USER = {
  create: { method: "post", url: "/admin/user/add", showLoader: true },
  login: { method: "post", url: "/admin/login", showLoader: true },
  paginate: { method: "post", url: "/admin/user/paginate", showLoader: true },
  changePassword: {
    method: "post",
    url: "/api/v2/auth/change-password",
    showLoader: true,
  },
  edit: { method: "put", url: "/admin/user/update/", showLoader: true },
  forgetPassword: {
    method: "post",
    url: "/admin/forgot-password",
    showLoader: true,
  },
  resetPasswordOTP: {
    method: "post",
    url: "/admin/check-reset-password-otp",
    showLoader: true,
  },
  resetPassword: {
    method: "post",
    url: "/admin/reset-password",
    showLoader: true,
  },
  statusUpdate: {
    method: "put",
    url: "admin/user/update-status/",
    showLoader: true,
  },
  sendResetPassword: {
    method: "post",
    url: "/admin/user/reset-password/",
    showLoader: true,
  },
  sendPasswordResetLink: {
    method: "post",
    url: "/admin/user/reset-password-link/",
    showLoader: true,
  },
  availability: {
    method: "post",
    url: "/admin/physician/slots/",
    showLoader: true,
  },
  slots: {
    method: "post",
    url: "/admin/physician/slot/master",
    showLoader: true,
  },
  view: { method: "get", url: "/admin/user/view/", showLoader: true },
  roleChange: {
    method: "put",
    url: "/admin/user/switch-role/",
    showLoader: true,
  },
  updateSpecialization: {
    method: "put",
    url: "/admin/user/update-user/",
    showLoader: true,
  },
  all: { method: "post", url: "/admin/v2/building/all", showLoader: true },
  CreateBuilding: {
    method: "post",
    url: "/admin/v2/building/create",
    showLoader: true,
  },
};
export const API_MASTER = {
  paginate: { method: "post", url: "/admin/master/paginate", showLoader: true },
  create: { method: "post", url: "/admin/master/create", showLoader: true },
  edit: { method: "put", url: "/admin/master/update/", showLoader: true },
  view: { method: "get", url: "/admin/master/view/", showLoader: true },
  listByCode: {
    method: "post",
    url: "/admin/master/list-by-code",
    showLoader: false,
  },
  updateSequence: {
    method: "put",
    url: "/admin/master/update-sequence/",
    showLoader: true,
  },
};
export const API_COMMON = {
  delete: {
    method: "post",
    url: "admin/common/remove-record",
    showLoader: true,
  },
  activeDeactive: {
    method: "post",
    url: "/admin/common/boolean-status-update",
    showLoader: true,
  },
  fileUpload: {
    method: "post",
    url: "/admin/file/file-upload",
    showLoader: true,
  },
  removeFile: {
    method: "post",
    url: "/admin/file/delete-file",
    showLoader: true,
  },
};

export const API_SPECIALIZATION = {
  paginate: { method: "post", url: "admin/theme/paginate", showLoader: true },
  paginateNew: {
    method: "post",
    url: "admin/theme/paginate-v2",
    showLoader: true,
  },

  create: { method: "post", url: "admin/theme/add", showLoader: true },
  createNew: { method: "post", url: "admin/theme/add-v2", showLoader: true },
  edit: { method: "post", url: "/admin/theme/update/", showLoader: true },
  view: { method: "get", url: "/admin/theme/view/", showLoader: true },
  updateSequence: {
    method: "put",
    url: "/admin/theme/update-sequence/",
    showLoader: true,
  },
};

export const API_DEPRECIATION = {
  paginate: {
    method: "post",
    url: "admin/depreciation/paginate",
    showLoader: true,
  },
  create: { method: "post", url: "admin/depreciation/add", showLoader: true },
  edit: {
    method: "post",
    url: "/admin/depreciation/update/",
    showLoader: true,
  },
  view: { method: "get", url: "/admin/depreciation/view/", showLoader: true },
};
export const API_DEPRECIATION_MODEL = {
  paginate: {
    method: "post",
    url: "admin/improvement-model/v2/view",
    showLoader: true,
  },
};

export const API_PHARMACY = {
  paginate: {
    method: "post",
    url: "/admin/pharmacy/paginate",
    showLoader: true,
  },
  create: { method: "post", url: "/admin/pharmacy/add", showLoader: true },
  edit: { method: "put", url: "/admin/pharmacy/update/", showLoader: true },
  view: { method: "get", url: "/admin/pharmacy/view/", showLoader: true },
};

export const API_APPOINTMENT = {
  paginate: {
    method: "post",
    url: "/admin/appointment/paginate",
    showLoader: true,
  },
  edit: { method: "put", url: "/admin/appointment/update/", showLoader: true },
  view: { method: "get", url: "/admin/appointment/view/", showLoader: true },
};

export const API_FAQS = {
  paginate: { method: "post", url: "/admin/faqs/paginate", showLoader: true },
  create: { method: "post", url: "/admin/faqs/add", showLoader: true },
  update: { method: "post", url: "/admin/faqs/update/", showLoader: true },
  updateSequence: {
    method: "put",
    url: "/admin/faqs/update-sequence/",
    showLoader: true,
  },
  view: { method: "get", url: "/admin/faqs/view/", showLoader: true },
};

export const API_PROMO_CODE = {
  paginate: {
    method: "post",
    url: "/admin/promocode/paginate",
    showLoader: true,
  },
  create: { method: "post", url: "/admin/promocode/add", showLoader: true },
  edit: { method: "post", url: "/admin/promocode/update/", showLoader: true },
  view: { method: "get", url: "/admin/promocode/view/", showLoader: true },
  updateSequence: {
    method: "put",
    url: "/admin/promocode/update-sequence/",
    showLoader: true,
  },
};

export const API_DISPUTES = {
  paginate: {
    method: "post",
    url: "/admin/appointment-dispute/paginate",
    showLoader: true,
  },
  sendNotification: {
    method: "post",
    url: "admin/notification/send",
    showLoader: true,
  },
};

export const API_PAYMENT = {
  paginate: {
    method: "post",
    url: "/admin/payment/paginate",
    showLoader: true,
  },
};

export const API_FAX = {
  paginate: { method: "post", url: "/admin/fax/paginate", showLoader: true },
};
export const API_PAYOUT = {
  paginate: {
    method: "post",
    url: "/admin/payment/physician-payout/paginate",
    showLoader: true,
  },
};
export const API_DASHBOARD = {
  view: {
    method: "post",
    url: "/admin/dashboard/get-statistics",
    showLoader: true,
  },
};

export const API_CONTACT_US = {
  paginate: {
    method: "post",
    url: "/admin/contact-us/paginate",
    showLoader: true,
  },
};

export const API_VERSION = {
  paginate: {
    method: "post",
    url: "/admin/version/paginate",
    showLoader: true,
  },
  create: { method: "post", url: "/admin/version/add", showLoader: true },
  edit: { method: "post", url: "/admin/version/update/", showLoader: true },
  view: { method: "get", url: "/admin/version/view/", showLoader: true },
};

export const Refresh_Token = {
  send: { method: "post", url: "/admin/token", showLoader: true },
};

export const API_CONFIG = {
  update: {
    method: "put",
    url: "/admin/setup-config/update/",
    showLoader: true,
  },
  view: { method: "get", url: "/admin/setup-config/view", showLoader: true },
};

export const API_PROPERTY_PROFILE = {
  paginate: {
    method: "post",
    url: "/admin/property/paginate",
    showLoader: true,
  },
  view: { method: "get", url: "/admin/property/view/", showLoader: true },
  send_email: {
    method: "get",
    url: "/admin/property/sendPropertyMail/",
    showLoader: true,
  },
  delete: {
    method: "post",
    url: "/api/v2/property/remove/",
    showLoader: true,
  },
};
export const FETCH_TYPE = {
  paginate: {
    method: "get",
    url: "/admin/improvement-type/view-v2",
    showLoader: true,
  },
};

export const API_TNC = {
  view: {
    method: "get",
    url: "/admin/terms-and-condition/view/",
    showLoader: true,
  },
  update: {
    method: "post",
    url: "/admin/terms-and-condition/update/",
    showLoader: true,
  },
  create: {
    method: "post",
    url: "/admin/terms-and-condition/add",
    showLoader: true,
  },
  paginate: {
    method: "post",
    url: "/admin/terms-and-condition/paginate",
    showLoader: true,
  },
};
export const API_CHECKLIST = {
  create: {
    method: "post",
    url: "/admin/v2/checklist/create",
    showLoader: true,
  },
  paginate: {
    method: "post",
    url: "/admin/v2/checklist/list",
    showLoader: true,
  },
  update: {
    method: "post",
    url: "/admin/v2/checklist/update/",
    showLoader: true,
  },
  delete: {
    method: "post",
    url: "/admin/v2/checklist/delete/",
    showLoader: true,
  },
};
export const API_REQUEST_APARTMENT = {
  paginate: {
    method: "post",
    url: "/admin/v1/requestApartment/list",
    showLoader: true,
  },
  approveDecline: {
    method: "put",
    url: "/admin/v1/requestApartment/acceptDecline/",
    showLoader: true,
  },
};

export const API_ASSURER_MANAGEMENT = {
  paginate: { method: "post", url: "/admin/v1/assurer/list", showLoader: true },
  extraJobRequest:{method: "post", url: "/admin/v1/assurer/limit-request-list", showLoader: true},
  delete: {
    method: "post",
    url: "/admin/v1/assurer/softDelete/",
    showLoader: true,
  },
  approveDecline: {
    method: "put",
    url: "/admin/v1/assurer/approve-or-decline/",
    showLoader: true,
  },
  APPROVE_JOBS: {
    method: "put",
    url: "/admin/v1/assurer/limit/approve-or-decline",
    showLoader: true,
  },
  updatePrice: {
    method: "put",
    url: "/admin/v1/assurer/update-price/",
    showLoader: true,
  },
};

export const API_ORDER_MANAGEMENT = {
  paginate: { method: "post", url: "/admin/orders/list", showLoader: true },
  approve: { method: "post", url: "/admin/orders/approve", showLoader: true },
};

export const API_BOARD_MANAGEMENT = {
  paginate: { method: "post", url: "/admin/v1/board/list", showLoader: true },
  delete: {
    method: "post",
    url: "/admin/v1/board/softDelete/",
    showLoader: true,
  },
  approveOrDecline: {
    method: "put",
    url: "/admin/v1/board/approve-or-decline/",
    showLoader: true,
  },
  boardMember: {
    method: "post",
    url: "/api/v1/board/list",
    showLoader: true,
  },
};

export const API_BUILDING = {
  ADD: { method: "post", url: "admin/v2/building/create", showLoader: true },
  paginate: {
    method: "post",
    url: "admin/v2/building/all",
    showLoader: true,
  },
  delete: {
    method: "post",
    url: "admin/v2/building/remove/",
    showLoader: true,
  },
  edit: {
    method: "post",
    url: "admin/v2/building/update/",
    showLoader: true,
  },
  uploadExcel: {
    method: "post",
    url: "admin/file/upload-excel",
    showLoader: true,
  },
  downloadExample: {
    method: "get",
    url: "admin/file/example-excel",
    get: true,
  },
};

export const API_INVOICE = {
  list: {
    method: "post",
    url: "/admin/invoices/list",
    showLoader: true,
  },
};

export const API_CITY = {
  list: {
    method: "post",
    url: "/api/v1/city/find",
    showLoader: true,
  },
};
