import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Datatable from "../../components/common/DataTable";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import {
  API_ASSURER_MANAGEMENT,
  API_ORDER_MANAGEMENT,
} from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_PAGE_SIZE,
  MODEL,
  ORDER_MANAGEMENT_TAB,
  ORDER_MANAGEMENT,
  ORDER_TYPE,
} from "../../constant/common";
import UtilService from "../../services/common.service";
import { toast } from "react-toastify";
import DetailsModalCommon from "../../components/common/modal/detailsmodal";
import EditPriceModal from "../../components/common/modal/editPriceModal";
import CommonService from "../../constant/commonService";
import SingleSelect from "../../components/common/select";
import CancelIcon from "../../components/common/closeIcon";
import OrderModal from "./OrderModal";

const AssurerManagement = (props) => {
  const [assurerData, setAssurerData] = useState([]); // data list api
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [activeTab, setActiveTab] = useState(ORDER_MANAGEMENT.ORDER_PENDING);
  const [deleteButton, setDeleteButton] = useState(false);
  const [approveDecline, setApproveDecline] = useState(true);
  const [actionColumn, setActionColumn] = useState(true);
  const [popupChild, setPopupChild] = useState("");
  const [detailsModal, setDetailsModal] = useState(false);
  const [editOption, setEditOption] = useState(false);
  const [editingPriceData, setEditingPriceData] = useState();
  const [editingUserId, setEditingUserId] = useState();
  const [orderType, setOrderType] = useState();
  const [orderModal, setOrderModal] = useState(false);

  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {
        sts: ORDER_MANAGEMENT.ORDER_PENDING,
      },
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
      // search: {
      //   keys: ["name", "number"],
      //   keyword: "",
      // },
    },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);

  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = ({ limit, page }) => {
    let filter = { ...filters };
    if (limit) {
      filter.offset = limit * page - limit;
      filter.limit = limit;
    } else {
      filter.offset = filters.limit * page - filters.limit;
    }
    filter.page = page;
    setFilters(filter);
  };

  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.page = 1;
    filter.sort = sort;
    setFilters(filter);
    fetch();
  };

  const search = (searchVal) => {
    let filter = filters;
    if (searchVal) {
      filter.search = searchVal;
    } else {
      filter.search = undefined;
    }
    setFilters(filter);
    fetch();
  };

  const fetch = () => {
    // api data
    let request = { ...API_ORDER_MANAGEMENT.paginate };
    request.obj = filters;
    UtilService.callApi(request, function ({ data, code }) {
      if (data && code === API_STATUS.OK) {
        const { docs } = data;
        setTotalPages(Math.ceil(data.totalDocs / filters.limit));
        setAssurerData(docs);
      } else {
        setAssurerData([]);
        setTotalPages(1);
      }
    });
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
    let key = ORDER_MANAGEMENT_TAB.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.filter.sts = key.value;
    setApproveDecline(key.approveDecline);
    setActionColumn(key.approveDecline);
    setFilters(filterToSet);
    fetch();
  };

  const handleDelete = (id) => {
    let request = { ...API_ASSURER_MANAGEMENT.delete };
    request.url = request.url + id;
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        fetch();
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  const handleApproveDecline = (props, { declineReason = "" }) => {
    setOrderModal(props);
  };

  const columns = [
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Company Name",
      accessor: "userId.compName",
    },
    {
      Header: "Name",
      accessor: "userId.name",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ original = {} }) => {
        return original
          ? (original?.userId?.mobiles[0]
              ? original?.userId?.mobiles[0]?.countryCode
              : "") +
              " " +
              (original?.userId?.mobiles[0]
                ? original?.userId?.mobiles[0]?.mobile
                : "")
          : "";
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ original = {} }) => {
        return original
          ? original?.userId?.emails[0]
            ? original?.userId?.emails[0]?.email
            : ""
          : "";
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Order No.",
      accessor: "orderNo",
    },
    {
      Header: "Date Of Order",
      id: "createdAt",
      accessor: (d) => CommonService.getDate(d.createdAt),
    },
  ];
  const handleEdit = (data = {}) => {
    setEditingPriceData({
      ...data.assurerFees,
      name: `${data.firstName} ${data.lastName}`,
    });
    setEditingUserId(data.id);
  };

  const handleOrderTypeSelect = (opt) => {
    let filter = filters;
    filters.filter.type = opt.value;
    setFilters(filter);
    setOrderType(opt);
    fetch();
  };

  const handleOnClear = () => {
    setOrderType();
    let filter = filters;
    delete filter.filter.type;
    setFilters(filter);
    fetch();
  };

  return (
    <Fragment>
      <Helmet>
        <title>Orders management</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
              <div>
                <h5>Orders management</h5>
                <span></span>
              </div>

              <div className="flex-wrap d-flex align-items-right">
                <div className="d-flex">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by name, email, phone"}
                  />
                  <div style={{ width: "200px" }}>
                    <SingleSelect
                      id="order"
                      name="order"
                      placeholder={"Select order type"}
                      data={ORDER_TYPE}
                      onChangeSelect={handleOrderTypeSelect}
                      value={orderType || []}
                    />
                  </div>
                  {orderType && (
                    <div className="ml-2 mt-2" onClick={handleOnClear}>
                      <CancelIcon />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={ORDER_MANAGEMENT_TAB}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {ORDER_MANAGEMENT_TAB.map((d, i) => {
                return activeTab === d.id ? (
                  <HRightTab key={i} tabId={d.id}>
                    <div className="search-page profile-page">
                      <div className="PageBodyWrapper">
                        <div className="pageBodyContent product-page-main">
                          <div className="table-responsive support-table">
                            <div className="search-page profile-page">
                              <div className="PageBodyWrapper">
                                <div className="pageBodyContent product-page-main">
                                  <div className="table-responsive support-table">
                                    <Datatable
                                      columns={columns}
                                      myData={assurerData}
                                      pagination={true}
                                      pageSize={filters.limit}
                                      page={filters.page}
                                      class="-striped -highlight"
                                      handleChange={handleFilterChange}
                                      pages={totalPages}
                                      handleSort={handleSort}
                                      fetch={fetch}
                                      model={MODEL.VERSION}
                                      actions={{
                                        canEditData: editOption,
                                        canDelete: deleteButton,
                                        canApprove: approveDecline,
                                      }}
                                      externalDelete={
                                        API_ASSURER_MANAGEMENT.delete
                                      }
                                      handleDelete={handleDelete}
                                      handleApproveDecline={
                                        handleApproveDecline
                                      }
                                      actionColumn={actionColumn}
                                      edit={handleEdit}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </HRightTab>
                ) : null;
              })}
            </Navigation>
          </div>
        </div>
      </div>
      {detailsModal && (
        <DetailsModalCommon
          id={detailsModal}
          onClose={() => setDetailsModal(false)}
        />
      )}
      {orderModal && (
        <OrderModal
          title="Order Transaction Details"
          onClose={() => setOrderModal(false)}
          fetchList={fetch}
          orderModal={orderModal}
        />
      )}
      {editingPriceData && (
        <EditPriceModal
          price={editingPriceData}
          editingUserId={editingUserId}
          onClose={() => setEditingPriceData(false)}
          fetch={fetch}
        />
      )}
    </Fragment>
  );
};
export default AssurerManagement;
