import React, { Fragment, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Helmet } from 'react-helmet';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import { toast } from 'react-toastify';
import Logo from '../../components/common/logo';
import { API_USER } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';

const Login = (props) => {
    const { register, handleSubmit, errors } = useForm({ mode: "onChange" }); // initialise the hook
    const [showPassword, setShowPassword] = useState(false)
    const onSubmit = data => {
        if (data !== '') {
            //API call
            let request = { ...API_USER.login };
            request.obj = { ...data };
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    localStorage.setItem("token", JSON.stringify(res.data.token.jwt))
                    localStorage.setItem("user", JSON.stringify(res.data.user))
                    localStorage.setItem("userPermission", JSON.stringify(res.data.userPermission))
                    props.history.push("/")
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };

    return (
        <Fragment>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <Logo logoSize={'md'} />
                                            <h3 className={'p-t-15'}>
                                                {"Domus"}
                                            </h3>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>Login</h4>
                                                    <h6>Enter your Email ID and Password </h6>
                                                </div>
                                                <form className="theme-form" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group form-row">
                                                        <label htmlFor="validationCustom01"
                                                            className="col-form-label pt-0">Email ID</label>
                                                        <input
                                                            placeholder="Enter Email ID"
                                                            className="form-control"
                                                            onKeyUp={(event) => {
                                                                let k = event ? event.which : window.event.keyCode;
                                                                if (k === 32) return false;
                                                            }}
                                                            name="username" ref={register({ required: true })}
                                                            type="text" />
                                                        <span className="color-red">{errors.username && 'Please enter email ID.'}</span>
                                                    </div>
                                                    <div className="form-group form-row d-flex flex-column">
                                                        <label className="col-form-label">Password</label>
                                                        <div className="d-flex">
                                                            <input
                                                                placeholder="Enter Password"
                                                                className="form-control"
                                                                name="password" ref={register({ required: true })}
                                                                type={showPassword ? "text" : "password"} />
                                                            {showPassword ? <EyeOff className="eye-pass" onClick={() => setShowPassword(!showPassword)} /> : <Eye className="eye-pass" onClick={() => setShowPassword(!showPassword)} />}
                                                        </div>
                                                        <span className="color-red">{errors.password && 'Please enter password.'}</span>
                                                    </div>
                                                    <div className="form-grouform-rowp form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block" type="submit">Login</button>
                                                    </div>
                                                    <div className="mt-3 d-flex justify-content-center">
                                                        <Link to="/forget-password"> Forget Password ?</Link>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(translate(Login));