import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from "../../components/common/button/index";
import DataTable from '../../components/common/DataTable';
import Filter from '../../components/common/Filter';
import ShowRightSidebar from '../../components/common/Filter/rightSideBar';
import SearchBar from '../../components/common/SearchBar';
import Upsert from '../../components/Theme/upsert';
import { API_MASTER, API_SPECIALIZATION } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_PAGE_SIZE, MASTER_CODES, MODEL, STATUS_FILTER } from '../../constant/common';
import CommonService from '../../constant/commonService';
import UtilService from '../../services/common.service';
const Theme = (props) => {
    const [masterData, setMasterData] = useState([]); // data list api
    const [totalPages, setTotalPages] = useState(1) // total pages for pagination
    const [edit, setEditKey] = useState(); // edit record..
    const [symptomsFilter, setSymptomsFilter] = useState([{ label: "All", value: 1 }]) // subjects filter
    const initialFilter = { // filter to send to api
        defaultFilter: {
            subjects: 1,
            isActive: 1
        },
        filter: {
            filter: {
                isDeleted: false
            },
            sort: { "createdAt": -1},
            page: 1,
            limit: DEFAULT_PAGE_SIZE,
            search: {
                keys: ["name", "code"],
                keyword: ""
            }
        }
    }
    const [defaultFilter, setDefaultFilter] = useState(initialFilter.defaultFilter) // default filters
    const pageFilter = [ // filters array 
        {
            title: "Status",
            list: STATUS_FILTER,
            select: defaultFilter.isActive,
            key: "isActive"
        },
        {
            title: "Subject",
            list: symptomsFilter,
            select: defaultFilter.subjects,
            key: "subjects"
        }
    ]
    const [filters, setFilters] = useState(initialFilter.filter)
    useEffect(() => { // call api - componentDidMount
        fetchFilterAPIData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => { // call api on filter change 
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handlePageFilter = (data) => { // set data which comes from manual filter
        let filterToSet = filters
        filterToSet.page = 1
        filterToSet.filter = CommonService.getKeyList(data, pageFilter, filters.filter)
        setDefaultFilter(data)
        setFilters(filterToSet)
        fetch()
    }
    const handleFilterClear = () => {
        setDefaultFilter(initialFilter.defaultFilter)
        setFilters(initialFilter.filter)
    }
    const handleFilterChange = (limit) => { // page and limit filter change from data table
        let filter = { ...filters, ...limit }
        setFilters(filter)
    }
    const handleSort = (sort) => { // handle sort change from data table
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const search = (searchVal) => { // handle search filters from search component
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = () => { // api data 
        let request = { ...API_SPECIALIZATION.paginateNew };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                setTotalPages(Math.ceil(res.data.count / filters.limit))
            }
            else {
                setMasterData([])
                setTotalPages(1)
            }
        })
    }
    const fetchFilterAPIData = () => { // filter for city and province
        let request = { ...API_MASTER.listByCode };
        request.obj = {
            masters: [
                MASTER_CODES.SUBJECT
            ],
            include: ["subMasters"]
        }
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let masters = res.data;
                let subjects = []
                masters && masters[MASTER_CODES.SUBJECT] && masters[MASTER_CODES.SUBJECT].subMasters && masters[MASTER_CODES.SUBJECT].subMasters.map(e => subjects.push({ value: e._id, label: e.name, type: e._id }))
                setSymptomsFilter([...symptomsFilter, ...subjects])
            }
        })
    }
    const columns = [ // table columns
        {
            Header: "No.",
            id: "_id",
            Cell: row => {
                return (row.index + 1)
            },
            sortable: true,
            resizable: false,
            width: 50
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Subject",
            id: "subjects",
            Cell: row => {
                return row.original.improvementsData && row.original.improvementsData.length ? row.original.improvementsData.slice(0, 1).map((a) => {
                    let danish = ''
                    let eng = ''
                    if (a.subjectId && a.subjectId.multiLanguageData) {
                        if (a.subjectId.multiLanguageData.EN && a.subjectId.multiLanguageData.EN.name) {
                            eng = a.subjectId.multiLanguageData.EN.name
                        }
                        if (a.subjectId.multiLanguageData.DANISH && a.subjectId.multiLanguageData.DANISH.name) {
                            danish = a.subjectId.multiLanguageData.DANISH.name
                        }
                    }
                    return <>
                        {eng &&
                            <>
                                <span>
                                    English : {eng}
                                </span>
                                <br />

                            </>
                        }
                        {danish &&

                            <span>
                                Danish : {danish}
                            </span>
                        }
                    </>
                }) : ""
            },
            sortable: false
        }
    ]
    return (
        <Fragment>
            <Helmet>
                <title>Theme</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                            <div>
                                <h5>Theme</h5><span></span>
                            </div>
                            <div className="d-flex flex-wrap align-items-right">
                                <div className="m-r-10" >
                                    <SearchBar handleSearch={search} title={"Search by name and code."} />
                                </div>
                                <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={"Add Theme"} />
                                <div className="m-l-10">
                                    <Button variant="outline-primary filter-btn" onClick={() => ShowRightSidebar(true)}><i className="fa fa-filter f-20"></i></Button>
                                </div>
                                <Filter onClear={handleFilterClear} data={pageFilter} handleFilter={handlePageFilter} />
                            </div>
                        </div>
                        <div className="pageBodyContent product-page-main">
                            <div className="table-responsive support-table">
                                <DataTable
                                    actions={{
                                        canEdit: true,
                                        canDelete: true,
                                        canActive: true
                                    }}
                                    sortable={false}
                                    columns={columns}
                                    myData={masterData}
                                    pagination={true}
                                    page={filters.page}
                                    class="-striped -highlight"
                                    handleChange={handleFilterChange}
                                    pages={totalPages}
                                    handleSort={handleSort}
                                    fetch={fetch}
                                    model={MODEL.THEME}
                                    edit={(id) => { setEditKey(id) }}
                                    pageSize={filters.limit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    // }
};
export default Theme;