import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Datatable from "../../components/common/DataTable";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import { API_REQUEST_APARTMENT } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_PAGE_SIZE,
  MODEL,
  VERSION_STATUS_TAB5,
  APARTMENT_LIMIT_REQUEST,
  DEFAULT_API_ERROR,
  REASON_FOR_APARTMENT_REQUEST,
  LOCALISATION,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import ToolTipCommon from "../../components/common/ToolTip";
import { Eye } from "react-feather";
import { toast } from "react-toastify";
import { commonSearchQuery } from "../../components/common/commonAPiRequest";
import Details from "../AssurerManagement/view";

const AppartmentRequest = () => {
  const [masterData, setMasterData] = useState([]); // data list api
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [activeTab, setActiveTab] = useState(APARTMENT_LIMIT_REQUEST.PENDING);
  const [popupDetail, setPopupDetail] = useState();
  const [actionColumn, setActionColumn] = useState(true);
  const [showDeclineReason, setShowDeclineReason] = useState(false);
  const [showReason, setShowReason] = useState(true);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupChild, setPopupChild] = useState("");

  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {
        status: APARTMENT_LIMIT_REQUEST.PENDING,
      },
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
    },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);

  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = (limit) => {
    // page and limit filter change from data table
    let filter = { ...filters, ...limit };
    setFilters(filter);
  };

  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.page = 1;
    filter.sort = sort;
    setFilters(filter);
    fetch();
  };

  const search = (searchVal) => {
    // handle search filters from search component
    const searchKey = ["u.name", "u.email", "u.mobile"];
    let searchReg = commonSearchQuery(searchKey, searchVal);
    let filter = filters;
    filter.filter = { ...filter.filter, $or: searchReg };
    setFilters(filter);
    fetch();
  };

  const fetch = () => {
    // api data
    let request = { ...API_REQUEST_APARTMENT.paginate };
    request.obj = filters;
    UtilService.callApi(request, function ({ data, code }) {
      if (data && code === API_STATUS.OK) {
        if (data && code === API_STATUS.OK) {
          const { docs } = data;
          const id = [];
          const additionalRequest = docs.map((i) => {
            id.push(i._id);
            let arrayOfRequest = { ...i.requestedBy };
            arrayOfRequest.requestId = i._id;
            arrayOfRequest.declineReason = i.declineReason || "";
            arrayOfRequest.reqReason = i.reqReason || "";
            arrayOfRequest.requestedAt = i.requestedAt;
            return arrayOfRequest;
          });
          setMasterData(additionalRequest);
          setTotalPages(Math.ceil(data.totalDocs / filters.limit));
        } else {
          setMasterData([]);
          setTotalPages(1);
        }
      }
    });
  };

  const handlePopUpDetails = (data, title) => {
    setPopupDetail(data);
    setPopupTitle(title);
    if (title === REASON_FOR_APARTMENT_REQUEST.PENDING) {
      setPopupChild(data.reqReason);
    } else {
      setPopupChild(data.declineReason);
    }
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
    let key = VERSION_STATUS_TAB5.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.page = 1;
    filterToSet.filter.status = key.value;
    setShowDeclineReason(key.declineReason);
    setShowReason(key.reason);
    setActionColumn(key.action);
    setFilters(filterToSet);
    fetch();
  };

  const columns = [
    // table columns
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      Cell: ({ original }) => {
        return original
          ? (original?.mobiles?.[0] ? original?.mobiles?.[0]?.countryCode : "") +
              " " +
              (original?.mobiles?.[0] ? original?.mobiles?.[0]?.mobile : "")
          : "";
      },
      sortable: false,
      width: 150,
    },
    {
      Header: "Request date",
      accessor: "request_date",
      Cell: ({ original }) => {
        return (
          <div>
            <div>{CommonService.getDate(original.requestedAt)}</div>
            <div>{CommonService.getTime(original.requestedAt)}</div>
          </div>
        );
      },
    },
    {
      Header: REASON_FOR_APARTMENT_REQUEST.PENDING,
      accessor: "reqReason",
      Cell: ({ original }) => {
        return (
          <div>
            <ToolTipCommon
              title={REASON_FOR_APARTMENT_REQUEST.PENDING}
              onClick={() =>
                handlePopUpDetails(
                  original,
                  REASON_FOR_APARTMENT_REQUEST.PENDING
                )
              }
            >
              <Eye
                className="actions-icon action-primary"
                style={{ width: 22 }}
              />
            </ToolTipCommon>
          </div>
        );
      },
      show: showReason,
    },
    {
      Header: REASON_FOR_APARTMENT_REQUEST.DECLINE,
      accessor: "declineReason",
      Cell: ({ original }) => {
        return (
          <div>
            <ToolTipCommon
              title={REASON_FOR_APARTMENT_REQUEST.DECLINE}
              onClick={() =>
                handlePopUpDetails(
                  original,
                  REASON_FOR_APARTMENT_REQUEST.DECLINE
                )
              }
            >
              <Eye
                className="actions-icon action-primary"
                style={{ width: 22 }}
              />
            </ToolTipCommon>
          </div>
        );
      },
      show: showDeclineReason,
    },
  ];

  const handleApproveDecline = (props, data = {}, close = {}, title = "") => {
    let request = {};
    request = { ...API_REQUEST_APARTMENT.approveDecline };
    request.url =
      request.url + props.requestId + `?lng=${LOCALISATION.ENGLISH}` || "";
    if (title === "Decline") {
      request.obj = {
        isApproved: false,
        declineReason: data.declineReason,
      };
    } else {
      request.obj = {
        isApproved: true,
      };
    }
    UtilService.callApi(request, function ({ data, code, message }) {
      if (data && code === API_STATUS.OK) {
        fetch();
        close();
        toast.success(message);
      } else {
        toast.error(message ? message : DEFAULT_API_ERROR);
      }
    });
  };

  return (
    <Fragment>
      <Helmet>
        <title>3rd Apartment Request</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="flex-wrap pageBodyHeader d-flex justify-content-between align-items-center">
              <div>
                <h5>3rd Apartment Request</h5>
                <span></span>
              </div>
              <div className="flex-wrap d-flex align-items-right">
                <div className="d-flex">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by name email and phone."}
                  />
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={VERSION_STATUS_TAB5}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {VERSION_STATUS_TAB5.map((d, i) => {
                return activeTab === d.id ? (
                  <HRightTab key={i} tabId={d.id}>
                    <div className="search-page profile-page">
                      <div className="PageBodyWrapper">
                        <div className="pageBodyContent product-page-main">
                          <div className="table-responsive support-table">
                            <div className="search-page profile-page">
                              <div className="PageBodyWrapper">
                                <div className="pageBodyContent product-page-main">
                                  <div className="table-responsive support-table">
                                    <Datatable
                                      columns={columns}
                                      myData={masterData}
                                      pagination={true}
                                      pageSize={filters.limit}
                                      page={filters.page}
                                      class="-striped -highlight"
                                      handleChange={handleFilterChange}
                                      pages={totalPages}
                                      handleSort={handleSort}
                                      fetch={fetch}
                                      model={MODEL.VERSION}
                                      actions={{
                                        canApprove: true,
                                        canDecline: true,
                                      }}
                                      handleApproveDecline={
                                        handleApproveDecline
                                      }
                                      actionColumn={actionColumn}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </HRightTab>
                ) : null;
              })}
            </Navigation>
          </div>
        </div>
      </div>
      {popupDetail && (
        <Details
          id={popupDetail}
          child={popupChild}
          title={popupTitle}
          onClose={() => setPopupDetail(false)}
        />
      )}
    </Fragment>
  );
};
export default AppartmentRequest;
