import React, { Fragment, useEffect, useState } from "react";
import { MapPin } from "react-feather";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { API_PROPERTY_PROFILE } from "../../constant/apiConstants";
import {
  API_STATUS,
  DEFAULT_API_ERROR,
  ROOM_TYPE,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import OptionTab from "./optionsTab";
import SimpleImageSlider from "react-simple-image-slider";
import { API_URL } from "../../constant/common";

const AddPropertyDetails = (props) => {
  const { id } = props;
  const [data, setData] = useState();
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetch = () => {
    let request = { ...API_PROPERTY_PROFILE.view };
    request.url = request.url + id;
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        setData(res.data);
      } else {
        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
      }
    });
  };

  return (
    <Fragment>
      <Button className="m-l-10" color="primary">
        {props.title}
      </Button>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{"Property Details"}</ModalHeader>
        <ModalBody>
          <form className="form theme-form add-form">
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                User name <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter User name"
                />
                <span className="color-red"></span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Email <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter email"
                />
                <span className="color-red"></span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Phone number <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter phone number"
                />
                <span className="color-red"></span>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Apartment name <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter apartment name"
                />
                <span className="color-red"></span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
                Apartment address <span className="color-red m-l-5">*</span>
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Enter apartment address"
                />
                <span className="color-red"></span>
              </div>
            </div>
            <label className="mb-4">Property Attributes :</label>
            <div className="form-group row">
              <div className="col-sm-4 d-flex align-items-center">
                <label className="col-form-label">Number of Living Rooms</label>

                <input
                  name="name"
                  className="form-control ml-3"
                  type="text"
                  placeholder="0"
                  style={{ width: "60px" }}
                />
              </div>
              <div className="col-sm-4 d-flex align-items-center">
                <label className="col-form-label">Number of Kitchen</label>

                <input
                  name="name"
                  className="form-control ml-3"
                  type="text"
                  placeholder="0"
                  style={{ width: "60px" }}
                />
              </div>
              <div className="col-sm-4 d-flex align-items-center">
                <label className="col-form-label">Number of Toilets</label>

                <input
                  name="name"
                  className="form-control ml-3"
                  type="text"
                  placeholder="0"
                  style={{ width: "60px" }}
                />
              </div>
              <div className="col-sm-4 d-flex align-items-center mt-3">
                <label className="col-form-label">Number of Hallway</label>

                <input
                  name="name"
                  className="form-control ml-3"
                  type="text"
                  placeholder="0"
                  style={{ width: "60px" }}
                />
              </div>
              <div className="col-sm-4 d-flex align-items-center mt-3">
                <label className="col-form-label">Number of Staircase</label>

                <input
                  name="name"
                  className="form-control ml-3"
                  type="text"
                  placeholder="0"
                  style={{ width: "60px" }}
                />
              </div>
              <div className="col-sm-4 d-flex align-items-center mt-3">
                <label className="col-form-label">Number of Bedrooms</label>
                <input
                  name="name"
                  className="form-control ml-3"
                  type="text"
                  placeholder="0"
                  style={{ width: "60px" }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
              Apartment Fraction
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Apartment Fraction"
                />
                <span className="color-red"></span>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-md-2 col-form-label">
              Time Stamp
              </label>
              <div class="col-sm-9 col-md-10">
                <input
                  name="name"
                  className="form-control"
                  type="text"
                  placeholder="Time Stamp"
                />
                <span className="color-red"></span>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddPropertyDetails;
