import React from "react";
import PropTypes from "prop-types";

const Button = props => {
  return (
    <button
      className={`btn btn-${props.color} btn-${props.btnSize} btn-${props.btnStyle} btn-${props.variant} btn-${props.raisedStyle} ${props.disabled}`}
      onClick={props.onClick}
      type="button"
    >
      {props.children}
    </button>
  );
};
Button.propTypes = {
  color: PropTypes.string,
  btnSize: PropTypes.string,
  btnStyle: PropTypes.string,
  raisedStyle: PropTypes.string,
  onClick: PropTypes.any
};
Button.defaultProps = {
  color: "",
  btnSize: "",
  btnStyle: "",
  variant: "",
  raisedStyle: "",
  disabled: ""
};

export default Button;
