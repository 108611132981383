import React from "react"

const Input = ({
  type = "text",
  placeholder,
  label,
  error,
  value,
  mandatory,
  defaultValue,
  disabled = false,
  maxLength = "",
  minLength = "",
  onChange = () => false,
  onKeyDown = () => false,
  rest,
  name
}) =>{ 
    return(
  <div className="text-left">
    {label && (
      <label className="text-xs font-medium mb-2 inline-block text-foreground">
        {label} {mandatory ? <span className="color-red">*</span> : ""}
      </label>
    )}
    <input
    name={name}
      placeholder={placeholder}
      className="form-control m-b-20"
      maxLength={maxLength}
      minLength={minLength}
      disabled={disabled}
      type={type}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onInput={(event) => {
        if (event.target.value.trim() === "") event.target.value = ""
      }}
      ref={rest}
    />
    {error && <p className="color-red">{error}</p>}
  </div>
)}

export default Input
