import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API_MASTER } from '../../../../constant/apiConstants';
import { API_STATUS, API_URL, DEFAULT_API_ERROR, DEFAULT_PAGE_SIZE, MODEL } from '../../../../constant/common';
import UtilService from '../../../../services/common.service';
import ActiveDeactive from '../../../common/ActiveDeactive';
import DataTable from '../../../common/DataTable';
import Image from '../../../common/Image';
import SearchBar from '../../../common/SearchBar';
import Upsert from '../../Master/upsert';

const Master = (props) => {
    const [masterData, setMasterData] = useState([]);
    const [edit, setEditKey] = useState();
    const [filters, setFilters] = useState({
        filter: {
            isDeleted: false,
            parentId: props.id
        },
        sort: { "sortingSequence": "ASC" },
        page: 1,
        search: {
            keys: ["name", "code"],
            keyword: ""
        }
    })
    const handleEdit = (edit) => {
        setEditKey(edit)
    }
    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handleFilterChange = (limit) => {
        let filter = { ...filters, ...limit }
        setFilters(filter)

    }
    const handleSort = (sort) => {
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const search = (searchVal) => {
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = () => {
        let request = { ...API_MASTER.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                // setTotalPages(Math.ceil(res.data.count / filters.limit))
            }
            else {
                setMasterData([])
                // setTotalPages(1)
                // toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    }
    const updateDefault = (id) => {
        let request = { ...API_MASTER.edit };
        request.url = request.url + id
        request.obj = {
            isDefault: true
        };
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                fetch()
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }

    const updateSequence = (oldIndex, newSeq) => { // api data 
        let request = { ...API_MASTER.updateSequence };
        request.url = request.url + masterData.filter((m) => m.sortingSequence === oldIndex)[0]._id
        request.obj = {
            "sortingSequence": newSeq
        }
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                fetch()
            }
            else {
                toast.error(res.message ? res.message : DEFAULT_API_ERROR)
            }
        })
    }
    const columns = [
        {
            Header: "No.",
            id: "_id",
            Cell: row => {
                return (row.index + 1)
            },
            width: 80
        },
        {
            Header: "Image",
            id: "image",
            Cell: row => <Image ImageClass="align-self-center img-40 height-40-px rounded-circle lazyloaded" src={row.original.image ? API_URL + row.original.image : null} alt="" />,
            sortable: false
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Code",
            accessor: "code"
        },
        {
            Header: "Default",
            accessor: "isDefault",
            Cell: row => {
                return row.original.isDefault ? <i className="fa fa-check-circle font-success f-12" /> : <i onClick={() => { if (row.original.isActive) { updateDefault(row.original._id) } else { toast.error("You can not set deactive record to default. ") } }} className="fa fa-times-circle font-danger f-12" />
            }
        },
        {
            Header: "Active",
            accessor: "isActive",
            Cell: row => {
                return <ActiveDeactive canNotDeactive={row.original.isDefault} fetch={fetch} id={row.original._id} status={row.original.isActive} model={"Master"} />
            },
        }
    ]
    return (
        <Fragment>
            <div className="m-b-10 d-flex justify-content-between flex-wrap">
                <div>
                    <h6 className="m-b-0">{props.name}</h6>
                    <span></span>
                </div>
                <div className="d-flex align-items-center">
                    <SearchBar title={"Search by name and code."} handleSearch={search} />
                    <Upsert editDone={() => setEditKey('')} edit={edit} parentId={props.id} fetch={() => {
                        props.fetch()
                        fetch()
                    }} title={edit ? "Edit Data" : "Add Data"} />
                </div>
            </div>
            <DataTable
                actions={
                    {
                        canDelete: true,
                        canEdit: true,
                        canDrag: true
                    }
                }
                updateSequence={updateSequence}
                columns={columns}
                myData={masterData}
                pagination={false}
                pageSize={masterData && masterData.length ? masterData.length : DEFAULT_PAGE_SIZE}
                class="-striped -highlight"
                handleChange={handleFilterChange}
                handleSort={handleSort}
                fetch={() => {
                    props.fetch()
                    fetch()
                }}
                model={MODEL.MASTER}
                edit={handleEdit}
                sortable={false}
            />
        </Fragment>
    )
}
export default Master;