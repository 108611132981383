import React, { useState } from "react";
import { Trash2 } from "react-feather";
import { toast } from "react-toastify";
import { API_COMMON } from "../../../constant/apiConstants";
import { API_STATUS, DEFAULT_API_ERROR } from "../../../constant/common";
import UtilService from "../../../services/common.service";
import ToolTipCommon from "../ToolTip";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const Delete = (props) => {
  const [show, showModal] = useState(false);
  const onClose = () => {
    showModal(false);
  };

  const handleDelete = () => {
    let request = {};
    if (props.externalDelete) {
      request = { ...props.externalDelete };
      request.url = request.url + props.id;
    } else {
      request = { ...API_COMMON.delete };
      request.obj = {
        documentId: props.id,
        model: props.model,
        isSoftDelete: true,
      };
    }

    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {
        props.fetch();
        toast.success(res.message);
      } else {
        toast.error(res && res.message ? res.message : DEFAULT_API_ERROR);
      }
    });
  };

  const checkDelete = () => {
    if (props.handleDelete) {
      props.handleDelete(props.id);
    } else {
      handleDelete();
    }
  };
  return (
    <>
      <ToolTipCommon onClick={() => showModal(true)} title="Delete">
        <Trash2
          style={{
            width: 22,
            fontSize: 22,
            padding: 3,
            color: "#e4566e",
            margin: "0 2px",
          }}
        />
      </ToolTipCommon>

      <Modal isOpen={show} toggle={onClose} size="md" centered={true}>
        <ModalHeader toggle={onClose}>{"Delete"}</ModalHeader>
        <ModalBody>{`Are you sure for delete 
        ${props.tittle || ""}
         ?`}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={checkDelete}>
            Delete
          </Button>
          <Button
            outline={true}
            color="primary"
            onClick={() => showModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Delete;
