import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { RANGE_DATE_FORMAT, SELECT_STYLE } from '../../../constant/common';
import RightSidebar from "../right-sidebar";
import ShowRightSidebar from './rightSideBar';

const Filter = (props) => {
    const [FormData, setFormData] = useState();
    const [startDate, setStartDate] = useState(props.DateRange ? props.DateRange.startDate : null)
    const [endDate, setEndDate] = useState(props.DateRange ? props.DateRange.endDate : null)
    const [month, setMonth] = useState()
    const [error, setError] = useState()
    const inputValue = startDate ? moment(startDate).format("DD/MM/YYYY") + ' - ' + moment(endDate).format("DD/MM/YYYY") : null
    const onSubmit = () => {
        if (startDate && endDate && startDate > endDate) {
            setError("From date should be greater than To date.")
            return
        }
        else {
            setError(null)
        }
        let value = startDate || endDate ? [startDate, endDate] : null
        if (!value && month) {
            value = [moment(month).startOf('month').startOf('week').toDate(), moment(month).endOf('month').endOf('week').toDate()]
        }
        ShowRightSidebar(false)
        props.handleFilter(FormData, value)
    };
    useEffect(() => {
        let values = {}
        props.data && props.data.length > 0 && props.data.map((d) => {
            values[d.key] = d.select
        })
        setFormData(values)
    }, [props])
    return (
        <RightSidebar>
            <div onClick={() => ShowRightSidebar(false)} className="filterClose">
                <i className="icofont icofont-close-circled"></i>
            </div>
            <div className="d-flex justify-content-between align-items-center p-t-15 p-l-10 p-r-10 p-b-15 filterTopBar">
                <h3 className="font-dark f-18 m-b-0">Filter</h3>
                <div>
                    <button onClick={onSubmit} className="btn btn-primary m-r-10">Apply</button>
                    <button onClick={() => {
                        setFormData(null)
                        setMonth(null)
                        setError(null)
                        setStartDate(null)
                        setEndDate(null)
                        props.onClear()
                        ShowRightSidebar(false)
                    }} className="btn btn-outline-primary">Clear</button>
                </div>
            </div>
            <div className="custom-scrollbar filterScrollBox p-t-10 p-b-10">
                <form className="form theme-form">
                    {props.data && props.data.length > 0 ? props.data.map((d, i) => {
                        return <div key={i} className="form-group">
                            <label className="col-sm-12 col-md-12 col-12 col-form-label">{d.title}</label>
                            <div className="p-r-15 p-l-15">
                                <Select
                                    onChange={(e) => {
                                        setFormData({ ...FormData, [d.key]: e.value })
                                    }}
                                    value={d.list && d.list.filter(e => e.value === (FormData && FormData[d.key]))}
                                    options={d.list}
                                    getOptionLabel={option => option.label}
                                    getOptionValue={option => option.value}
                                    styles={SELECT_STYLE}
                                />
                            </div>
                        </div>
                    }) : <></>}
                    {props.DateRange ? <div className="form-group">
                        <label className="col-sm-12 col-md-12 col-12 col-form-label">{props.DateRange.title}</label>
                        <div className="p-r-15 p-l-15">
                            <DatePicker
                                className="form-control m-b-5"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat={RANGE_DATE_FORMAT}
                                placeholderText="From Date"
                                shouldCloseOnSelect
                                isClearable={true}
                            />
                            <DatePicker
                                className="form-control"
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dateFormat={RANGE_DATE_FORMAT}
                                placeholderText="To Date"
                                shouldCloseOnSelect
                                isClearable={true}
                            />
                        </div>
                        <span className="error-message m-t-10 m-b-10 span-r-15 p-l-15">{error ? error : ""}</span>
                    </div>
                        : <></>}
                    {props.weekSelect ? <>
                        <div className="form-group">
                            <label className="col-sm-12 col-md-12 col-12 col-form-label">{"Select Month"}</label>
                            <div className="p-r-15 p-l-15">
                                <DatePicker
                                    className="form-control m-b-5"
                                    selected={month}
                                    onChange={month => {
                                        setMonth(month)
                                        setEndDate(null)
                                        setStartDate(null)
                                    }}
                                    dateFormat={"MMM, yyyy"}
                                    placeholderText="Select Month"
                                    isClearable={true}
                                    shouldCloseOnSelect
                                    showMonthYearPicker
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-sm-12 col-md-12 col-12 col-form-label">{"Select Week"}</label>
                            <div className="p-r-15 p-l-15">
                                <DatePicker
                                    className="form-control m-b-5 w-95"
                                    selected={startDate}
                                    onChange={date => {
                                        setStartDate(date ? moment(date).startOf('week').toDate() : null)
                                        setEndDate(date ? moment(date).endOf('week').toDate() : null)
                                    }}
                                    disabled={month ? false : true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    // dateFormat={`w`}
                                    placeholderText="Select Week"
                                    isClearable={true}
                                    shouldCloseOnSelect={false}
                                    showWeekNumbers
                                    minDate={moment(month).startOf("month").toDate()}
                                    maxDate={moment(month).endOf("month").toDate()}
                                    value={inputValue}
                                />
                            </div>
                        </div></>
                        : <></>}
                </form>
            </div>
        </RightSidebar>
    );
};

export default Filter;