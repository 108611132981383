import React from "react";
export const CommonError = (errorText) => {

    if (!errorText) return false

    return (
        <span className="color-red">
            {errorText}
        </span>
    );
}
