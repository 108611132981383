import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { API_USER } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR, USER_TYPE } from '../../constant/common';
import UtilService from '../../services/common.service';
const Upsert = (props) => {
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const { edit } = props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const [showPassword, setShowPassword] = useState(false)
    const prevEdit = usePrevious({ edit });
    const [modal8, setModal8] = useState();
    const { register, handleSubmit, errors, setValue } = useForm({ mode: "onChange" });
    const toggle8 = () => {
        if (modal8) props.editDone()
        setModal8(!modal8)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        if (prevEdit && prevEdit.edit !== edit) {
            if (edit) {
                toggle8()
                fetch(edit)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])
    const fetch = id => {
        let request = { ...API_USER.view };
        request.url = request.url + id
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let { firstName, lastName, emails, password } = res.data
                setValue("firstName", firstName)
                setValue("lastName", lastName)
                setValue("password", password)
                setValue("email", emails[0].email)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }
    const onSubmit = data => {
        if (data !== '') {
            let request = edit ? { ...API_USER.edit } : { ...API_USER.create };
            request.url = edit ? request.url + edit : request.url
            request.obj = { ...data, type: USER_TYPE.ADMIN };
            UtilService.callApi(request, function (res) {
                if (res && res.data && (res.code === API_STATUS.CREATED || res.code === API_STATUS.OK)) {
                    toggle8()
                    props.fetch()
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };


    return (
        <Fragment>
            <Button className="m-l-10" color="primary" onClick={toggle8}>{props.title}</Button>
            <Modal isOpen={modal8} toggle={toggle8} size="lg" centered={true}>
                <ModalHeader toggle={toggle8}>{edit ? "Edit Admin/User" : "Add Admin/User"}</ModalHeader>
                <ModalBody>
                    <form className="form theme-form add-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">First Name <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="firstName" className="form-control"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[a-zA-Z]*$/,
                                            message: "First Name must contain only alphabetical characters."
                                        }
                                    })} type="text" placeholder="Enter First Name" />
                                <span className="color-red">{errors.firstName && (errors.firstName.message ? errors.firstName.message : 'Please enter first name.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Last Name <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="lastName" className="form-control"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[a-zA-Z]*$/,
                                            message: "Last Name must contain only alphabetical characters."
                                        }
                                    })} type="text" placeholder="Enter Last Name" />
                                <span className="color-red">{errors.lastName && (errors.lastName.message ? errors.lastName.message : 'Please enter last name.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Email Address <span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input name="email" className="form-control"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                            message: "Email address is not valid."
                                        }
                                    })} type="text" placeholder="Enter Email Address" />
                                <span className="color-red">{errors.email && (errors.email.message ? errors.email.message : 'Please enter email address.')}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-md-2 col-form-label">Password<span className="color-red m-l-5">*</span></label>
                            <div className="col-sm-9 col-md-10">
                                <input type={showPassword ? "text" : "password"} name="password" className="form-control"
                                    ref={register({
                                        required: true, pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_=+-]).{8,}$/,
                                            message: "Password Must be combination of uppercase, lowercase and a special character with 8 or more character"
                                        }
                                    })} placeholder="Enter Password" />
                                {showPassword ? <EyeOff className="eye-pass eye-profile" onClick={() => setShowPassword(!showPassword)} /> : <Eye className="eye-pass eye-profile" onClick={() => setShowPassword(!showPassword)} />}
                                <span className="color-red">{errors.password && (errors.password.message ? errors.password.message : 'Please enter password.')}</span>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>
                    <Button outline={true} color="primary" onClick={toggle8}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default Upsert;