import { createBrowserHistory } from "history";
import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/manualChange.css";
import App from "./components/app";
import Error400 from "./components/Error404";
import TermsAndCondition from "./containers/TermsAndCondition";
import Aboutus from "./components/Aboutus";
import Admin from "./containers/Admin";
import ContactUsDetail from "./containers/ContactUsDetail";
import Faq from "./containers/Faq";
import ForgetPassword from "./containers/ForgetPassword";
import Patient from "./containers/Patient";
import Profile from "./containers/Profile/index";
import PropertyProfile from "./containers/PropertyProfile";
import ResetPassword from "./containers/ResetPassword";
import Setting from "./containers/Setting/index";
import Signin from "./containers/Signin/";
import Theme from "./containers/Theme";
import Depreciation from "./containers/Depreciation";
import Version from "./containers/Version";
import AssurerManagement from "./containers/AssurerManagement";
import OrdersManagement from "./containers/Orders";
import BoardManagement from "./containers/BoardManagement";
// import AppartmentRequest from './containers/AppartmentRequest';
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import store from "./store/index";
import Building from "./containers/Building";
import Checklist from "./containers/Checklist";
import AppartmentRequest from "./containers/AppartmentRequest";
import Invoices from "./containers/Invoices";
const history = createBrowserHistory();
function Root() {
  const RestrictedRoutes = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("user") ? (
            <App>
              <Component {...props} />
            </App>
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };
  return (
    <div className="App custom-all-box">
      <Provider store={store}>
        <Helmet titleTemplate="%s - Domus" defaultTitle="Domus">
          <meta name="description" content="Domus" />
        </Helmet>
        <Router history={history} basename={`/`}>
          <ScrollContext>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/login`}
                component={Signin}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/forget-password`}
                component={ForgetPassword}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/reset-password`}
                component={ResetPassword}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  return (
                    <Redirect to={`${process.env.PUBLIC_URL}/simple-user`} />
                  );
                }}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/simple-user`}
                component={Patient}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/properties`}
                component={PropertyProfile}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/theme`}
                component={Theme}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/depreciation`}
                component={Depreciation}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/admin`}
                component={Admin}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/contact`}
                component={ContactUsDetail}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/version`}
                component={Version}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/faq`}
                component={Faq}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                component={TermsAndCondition}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/profile`}
                component={Profile}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/setting`}
                component={Setting}
              />
              {/* <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions/english`} component={TermsAndCondition} />
                            <Route path={`${process.env.PUBLIC_URL}/terms-and-conditions/danish`} component={TermsAndCondition} /> */}
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/about-us`}
                component={Aboutus}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/assurer`}
                component={AssurerManagement}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/orders`}
                component={OrdersManagement}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/building`}
                component={Building}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/checklist`}
                component={Checklist}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/board-management`}
                component={BoardManagement}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/appartment-request`}
                component={AppartmentRequest}
              />
              <RestrictedRoutes
                exact
                path={`${process.env.PUBLIC_URL}/invoice`}
                component={Invoices}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/404`}
                component={Error400}
              />
              <Redirect to="/404" />
            </Switch>
          </ScrollContext>
        </Router>
        <ToastContainer />
      </Provider>
    </div>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
