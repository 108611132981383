import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Datatable from "../../components/common/DataTable";
import Navigation from "../../components/common/Navigation";
import HRightTab from "../../components/common/Navigation/hRightTab";
import SearchBar from "../../components/common/SearchBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_INVOICE } from "../../constant/apiConstants";
import {
  API_STATUS,
  MODEL,
  INVOICE_TYPE,
  INVOICE_TYPE_LIST,
  INVOICE_TAB_TYPE,
  RANGE_DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
} from "../../constant/common";
import CommonService from "../../constant/commonService";
import UtilService from "../../services/common.service";
import { commonSearchQuery } from "../../components/common/commonAPiRequest";
import CancelIcon from "../../components/common/closeIcon";

const AppartmentRequest = () => {
  const [masterData, setMasterData] = useState([]); // data list api
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [activeTab, setActiveTab] = useState(INVOICE_TYPE.BOARD);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {
        type: INVOICE_TAB_TYPE.BOARD,
      },
      page: 1,
      limit: DEFAULT_PAGE_SIZE,
    },
  };

  const [filters, setFilters] = useState(initialApiFilter.filter);

  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBasedOnDateRange = () => {
    let filter = filters;
    filter.filter.createdAt = { $gt: startDate, $lt: endDate };
    fetch(filter);
  };

  useEffect(() => {
    if (endDate && startDate) {
      filterBasedOnDateRange();
    }
  }, [endDate, startDate]);

  const handleFilterChange = ({ limit, page }) => {
    // page and limit filter change from data table
    let filter = { ...filters };
    if (limit) {
      filter.offset = limit * page - limit;
      filter.limit = limit;
    } else {
      filter.offset = filters.limit * page - filters.limit;
    }
    filter.page = page;
    setFilters(filter);
    fetch(filter);
  };

  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.page = 1;
    filter.sort = sort;
    fetch(filter);
  };

  const search = (searchVal) => {
    // handle search filters from search component
    const searchKey = ["invoiceNumber"];
    let searchReg = commonSearchQuery(searchKey, searchVal);
    let filter = filters;
    filter.filter = { ...filter.filter, $or: searchReg };
    fetch(filter);
  };

  const fetch = (filter) => {
    // api data
    let request = { ...API_INVOICE.list };
    request.obj = filter ? filter : filters;
    UtilService.callApi(request, function ({ data, code }) {
      if (code === API_STATUS.OK) {
        const { docs } = data;
        setTotalPages(data.totalPages);
        setMasterData(docs);
      } else {
        setMasterData([]);
        setTotalPages(1);
      }
    });
  };

  const handleTabChange = (id) => {
    setActiveTab(id);
    let key = INVOICE_TYPE_LIST.filter((d) => {
      return d.id === id;
    })[0];
    let filterToSet = filters;
    filterToSet.page = 1;
    filterToSet.filter.type = key.value;
    fetch(filterToSet);
  };

  const handleDownload = (data) => {
    window.open(`${process.env.REACT_APP_API_URL}${data.uri}`);
  };

  const columns = [
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    {
      Header: "Invoice No",
      accessor: "invoiceNumber",
    },
    {
      Header: "User Name",
      accessor: "name",
    },
    {
      Header: "Date of Invoice",
      id: "createdAt",
      Cell: (row) => {
        return (
          <div>
            <div>{CommonService.getDate(row.original.createdAt)}</div>
          </div>
        );
      },
    },
  ];
  const handleOnClear = () => {
    setStartDate(null);
    setEndDate(null);
    const filter = filters;
    delete filter.filter.createdAt;
    fetch(filter);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <div className="container-fluid search-page profile-page p-t-15">
        <div className="PageBodyWrapper">
          <div className="pageBodyContent product-page-main">
            <div className="flex-wrap pageBodyHeader d-flex justify-content-between align-items-center">
              <div>
                <h5>Invoices</h5>
                <span></span>
              </div>
              <div className="flex-wrap d-flex align-items-right">
                <div className="d-flex">
                  <SearchBar
                    handleSearch={search}
                    title={"Search by Invoice Number."}
                  />
                  <div className="d-flex align-items-center ">
                    <div className="d-flex align-items-center">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={setStartDate}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat={RANGE_DATE_FORMAT}
                        placeholderText="From Date"
                        shouldCloseOnSelect
                      />
                      <DatePicker
                        className="form-control"
                        selected={endDate}
                        onChange={setEndDate}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat={RANGE_DATE_FORMAT}
                        placeholderText="To Date"
                        shouldCloseOnSelect
                      />
                    </div>
                    {startDate && endDate && (
                      <div className="ml-2" onClick={handleOnClear}>
                        <CancelIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Navigation
              TabType="borderb-tab-primary m-b-0"
              data={INVOICE_TYPE_LIST}
              setActiveTab={handleTabChange}
              activeTab={activeTab}
            >
              {INVOICE_TYPE_LIST.map((d, i) => {
                if (activeTab === d.id) {
                  return (
                    <HRightTab key={i} tabId={d.id}>
                      <div className="search-page profile-page">
                        <div className="PageBodyWrapper">
                          <div className="pageBodyContent product-page-main">
                            <div className="table-responsive support-table">
                              <div className="search-page profile-page">
                                <div className="PageBodyWrapper">
                                  <div className="pageBodyContent product-page-main">
                                    <div className="table-responsive support-table">
                                      <Datatable
                                        columns={columns}
                                        myData={masterData}
                                        pagination={true}
                                        pageSize={filters.limit}
                                        page={filters.page}
                                        class="-striped -highlight"
                                        handleChange={handleFilterChange}
                                        pages={totalPages}
                                        handleSort={handleSort}
                                        fetch={fetch}
                                        model={MODEL.VERSION}
                                        actions={{
                                          canDownload: true,
                                        }}
                                        download={handleDownload}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </HRightTab>
                  );
                } else {
                  return null;
                }
              })}
            </Navigation>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AppartmentRequest;
