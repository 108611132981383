import React, { useEffect, useState } from "react";
import Select from "react-select";
import { API_MASTER } from "../../constant/apiConstants";
import { API_STATUS, MASTER_CODES, SELECT_STYLE } from "../../constant/common";
import UtilService from "../../services/common.service";

export const MultiLanguage = (props) => {
  const [languageMaster, setLanguageMaster] = useState(); 
  useEffect(() => {
    fetchMasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchMasters = () => {
    let request = { ...API_MASTER.listByCode };
    request.obj = {
      masters: [MASTER_CODES.MULTI_LANGUAGE_SUPPORT],
      include: ["subMasters"],
    };
    UtilService.callApi(request, function (res) {
      if (res && res.data && res.code === API_STATUS.OK) {       
        let masters = res.data;
        if (
          masters &&
          masters[MASTER_CODES.MULTI_LANGUAGE_SUPPORT] &&
          masters[MASTER_CODES.MULTI_LANGUAGE_SUPPORT].subMasters
        ) {
          setLanguageMaster(
            masters[MASTER_CODES.MULTI_LANGUAGE_SUPPORT].subMasters
          );
          let defaultFind = masters[
            MASTER_CODES.MULTI_LANGUAGE_SUPPORT
          ].subMasters.filter((e) => e.isDefault === true);
          let defaultSet =
            defaultFind && defaultFind.length ? defaultFind[0].code : "";         
          props.setDefaultLanguage(defaultSet);
          props.setSelectedLanguage(defaultSet);
        } else {
          setLanguageMaster([]);
        }
      } else {
        setLanguageMaster([]);
      }
    });
  };
  return (
    <div className="form-group row">
      <label className="col-sm-3 col-md-2 col-form-label">Language</label>
      <div className={props.className ? props.className : "col-sm-9 col-md-10"}>
        <Select
          onChange={(e) => {            
            if (e) {
              props.setSelectedLanguage(e.code);
            }
          }}
          value={
            languageMaster &&
            languageMaster.filter((e) => e.code === props.selectedLanguage)
          }
          options={languageMaster}
          isMulti={false}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.code}
          placeholder="Select Language"
          styles={SELECT_STYLE}
        />
      </div>
    </div>
  );
};

export default MultiLanguage;
