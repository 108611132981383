import React, { forwardRef, Fragment, useImperativeHandle, useState } from 'react';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';
import { API_COMMON } from '../../../constant/apiConstants';
import { API_STATUS, API_URL, DEFAULT_API_ERROR, MEDIA_URL } from '../../../constant/common';
import CommonService from '../../../constant/commonService';
import UtilService from '../../../services/common.service';
import Image from '../Image';

const UserProfilePhoto = forwardRef((props, ref) => {
    const data = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).image
    const [fileObject, changeFileObject] = useState()
    const [ImageUploaderKey, setImageUploaderKey] = useState(0)
    const [success, setSuccess] = useState();
    useImperativeHandle(ref, () => ({
        onSuccess(img) {
            setSuccess(img ? img : "")
            setImageUploaderKey(ImageUploaderKey + 1)
        }
    }));
    const onDrop = (pictureFiles) => {
        if (pictureFiles && pictureFiles.length) {
            let request = { ...API_COMMON.fileUpload };
            const formData = new FormData();
            formData.append('file', pictureFiles[0])
            request.obj = formData
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    setSuccess(false)
                    changeFileObject(res.data.files[0].path)
                    props.setImage(res.data.files[0] ? res.data.files[0].path : "")
                    setImageUploaderKey(ImageUploaderKey + 1)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            })
        }
        else if (fileObject) {
            CommonService.removeFile(fileObject, () => {
                changeFileObject("")
                props.setImage('')
            })
        }
        if (props.image && !pictureFiles.length) {
            props.setImage(null)
        }
    }
    return (
        <Fragment >
            <div className={props.masterContent ? "float-left" : "d-flex align-items-center"}>
                {!props.masterContent && <div className="avatar">
                    <Image ImageClass="img-100 height-100-px rounded-circle" src={success ? MEDIA_URL + success : data ? MEDIA_URL + data : require("../../../assets/images/user/user.png")} alt="user" />
                </div>}
                <ImageUploader
                    key={ImageUploaderKey}
                    withIcon={false}
                    withPreview={true}
                    buttonStyles={{ borderRadius: "5px" }}
                    buttonClassName={"btn-primary"}
                    className={props.masterContent ? "master-file-container" : "m-l-10"}
                    defaultImages={props.image ? [API_URL + props.image] : success ? [] : fileObject ? [API_URL + fileObject] : []}
                    buttonText={props.masterContent ? "Select Image" : "Change Photo"}
                    withLabel={false}
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".jpeg", ".svg"]}
                    maxFileSize={500000}
                    fileSizeError="Image size must be less than 5 MB"
                    fileTypeError="Image must be .jpg, .jpeg, .png or .gif"
                    singleImage={true}
                    fileContainerStyle={props.masterContent ? { alignItems: "flex-start" } : {}}
                />
            </div>
        </Fragment >
    )
})
export default UserProfilePhoto;