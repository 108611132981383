import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from "../../components/common/button/index";
import DataTable from '../../components/common/DataTable';
import Filter from '../../components/common/Filter';
import ShowRightSidebar from '../../components/common/Filter/rightSideBar';
import SearchBar from '../../components/common/SearchBar';
import Upsert from '../../components/Depreciation/upsert';
import { API_DEPRECIATION } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_PAGE_SIZE, MODEL, STATUS_FILTER } from '../../constant/common';
import CommonService from '../../constant/commonService';
import UtilService from '../../services/common.service';
const Depreciation = (props) => {
    const [masterData, setMasterData] = useState([]); // data list api
    const [totalPages, setTotalPages] = useState(1) // total pages for pagination
    const [edit, setEditKey] = useState(); // edit record..
    const initialFilter = { // filter to send to api
        defaultFilter: {
            subjects: 1,
            isActive: 1
        },
        filter: {
            filter: {
                isDeleted: false
            },
            sort: { "createdAt": "DESC" },
            page: 1,
            limit: DEFAULT_PAGE_SIZE,
            search: {
                keys: ["name"],
                keyword: ""
            }
        }
    }
    const [defaultFilter, setDefaultFilter] = useState(initialFilter.defaultFilter) // default filters
    const pageFilter = [ // filters array 
        {
            title: "Status",
            list: STATUS_FILTER,
            select: defaultFilter.isActive,
            key: "isActive"
        }
    ]
    const [filters, setFilters] = useState(initialFilter.filter)

    useEffect(() => { // call api on filter change 
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])
    const handlePageFilter = (data) => { // set data which comes from manual filter
        let filterToSet = filters
        filterToSet.page = 1
        filterToSet.filter = CommonService.getKeyList(data, pageFilter, filters.filter)
        setDefaultFilter(data)
        setFilters(filterToSet)
        fetch()
    }
    const handleFilterClear = () => {
        setDefaultFilter(initialFilter.defaultFilter)
        setFilters(initialFilter.filter)
    }
    const handleFilterChange = (limit) => { // page and limit filter change from data table
        let filter = { ...filters, ...limit }
        setFilters(filter)
    }
    const handleSort = (sort) => { // handle sort change from data table
        let filter = filters
        filter.page = 1
        filter.sort = sort
        setFilters(filter)
        fetch()
    }
    const search = (searchVal) => { // handle search filters from search component
        let filter = filters
        filter.search.keyword = searchVal
        filter.page = 1
        setFilters(filter)
        fetch()
    }
    const fetch = () => { // api data 
        let request = { ...API_DEPRECIATION.paginate };
        request.obj = filters;
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setMasterData(res.data.list)
                setTotalPages(Math.ceil(res.data.count / filters.limit))
            }
            else {
                setMasterData([])
                setTotalPages(1)
            }
        })
    }

    const columns = [ // table columns
        {
            Header: "No.",
            id: "_id",
            Cell: row => {
                return (row.index + 1)
            },
            sortable: true,
            resizable: false,
            width: 50
        },
        {
            Header: "Name",
            accessor: "name"
        },
        {
            Header: "Depreciation Year",
            accessor: "depreciationYear"
        },
        {
            Header: "Initial Depreciation",
            id: "initialDepreciation",
            Cell: row => {
                return row.original.initialDepreciation && row.original.initialDepreciation + '%'
            },
            sortable: false
        },
        {
            Header: "Initial Depreciation Year",
            accessor: "initialDepreciationYear"
        },
        {
            Header: "Scrap Value",
            id: "scrapValue",
            Cell: row => {
                return row.original.scrapValue && row.original.scrapValue + '%'
            },
            sortable: false
        }
    ]
    return (
        <Fragment>
            <Helmet>
                <title>Depreciation</title>
            </Helmet>
            <div className="container-fluid search-page profile-page p-t-15">
                <div className="PageBodyWrapper">
                    <div className="pageBodyContent product-page-main">
                        <div className="pageBodyHeader flex-wrap d-flex justify-content-between align-items-center">
                            <div>
                                <h5>Depreciation Model</h5><span></span>
                            </div>
                            <div className="d-flex flex-wrap align-items-right">
                                <div className="m-r-10" >
                                    <SearchBar handleSearch={search} title={"Search by name."} />
                                </div>
                                <Upsert editDone={() => { setEditKey('') }} edit={edit} fetch={fetch} title={"Add Depreciation Model"} />
                                <div className="m-l-10">
                                    <Button variant="outline-primary filter-btn" onClick={() => ShowRightSidebar(true)}><i className="fa fa-filter f-20"></i></Button>
                                </div>
                                <Filter onClear={handleFilterClear} data={pageFilter} handleFilter={handlePageFilter} />
                            </div>
                        </div>
                        <div className="pageBodyContent product-page-main">
                            <div className="table-responsive support-table">
                                <DataTable
                                    actions={{
                                        canEdit: true,
                                        canDelete: true,
                                        canActive: true
                                    }}
                                    sortable={false}
                                    columns={columns}
                                    myData={masterData}
                                    pagination={true}
                                    page={filters.page}
                                    class="-striped -highlight"
                                    handleChange={handleFilterChange}
                                    pages={totalPages}
                                    handleSort={handleSort}
                                    fetch={fetch}
                                    model={MODEL.DEPRECIATION}
                                    edit={(id) => { setEditKey(id) }}
                                    pageSize={filters.limit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
    // }
};
export default Depreciation;