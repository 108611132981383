import React, { Fragment, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Datatable from "../../components/common/DataTable";
import { API_BOARD_MANAGEMENT } from "../../constant/apiConstants";
import { API_STATUS, MODEL } from "../../constant/common";
import UtilService from "../../services/common.service";
const BoardMemberModal = ({ data, onClose }) => {
  const { buildId } = data || {}; 
  const initialApiFilter = {
    // filter to send to api
    filter: {
      filter: {},
      page: 1,
      limit: 10,
      offset: 0
    },
  };
  const [filters, setFilters] = useState(initialApiFilter.filter);
  const [masterData, setMasterData] = useState([]); // data list api

  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const columns = [
    {
      Header: "No.",
      id: "_id",
      Cell: (row) => {
        return row.index + 1 + (filters.page - 1) * filters.limit;
      },
      sortable: false,
      resizable: false,
      width: 50,
    },
    { Header: "Name", accessor: "name" },
    {
      Header: "Phone number",
      Cell: ({ original = {} }) => {
        return original
          ? (original?.mobiles?.[0] ? original?.mobiles?.[0]?.countryCode : "") +
          " " +
          (original?.mobiles?.[0] ? original?.mobiles?.[0]?.mobile : "")
          : "";
      },
    },
    {
      Header: "email",
      Cell: ({ original = {} }) => {
        return original
          ? original?.emails[0]
            ? original?.emails[0]?.email
            : ""
          : "";
      },
    },
  ];
  const fetch = () => {
    // api data
    let request = { ...API_BOARD_MANAGEMENT.boardMember };
    filters.filter.buildId = buildId?._id;
    request.obj = filters;    
    UtilService.callApi(request, function ({ data = {}, code }) {
      if (code === API_STATUS.OK) {
        const { docs } = data;
        setMasterData(docs);
        setTotalPages(Math.ceil(data.totalDocs / filters.limit));
      } else {
        setMasterData([]);
        setTotalPages(1);
      }
    });
  };

  const handleSort = (sort) => {
    // handle sort change from data table
    let filter = filters;
    filter.page = 1;
    filter.sort = sort;
    setFilters(filter);
    fetch();
  };

  useEffect(() => {
    // call api on filter change
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFilterChange = ({ limit, page }) => {
    // page and limit filter change from data table
    let filter = { ...filters };
    if (limit) {
      filter.offset = limit * page - limit;
      filter.limit = limit;
    } else {
      filter.offset = filters.limit * page - filters.limit;
    }
    filter.page = page;
    setFilters(filter);
  };

  return (
    <Fragment>
      <Modal isOpen={true} toggle={onClose} size="lg" centered={true}>
        <ModalHeader toggle={onClose}>{buildId?.name}</ModalHeader>
        <ModalBody>
          <div className="default-according style-1 faq-accordion">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between">
                  <span>
                    <b>Street:</b> {buildId?.street}
                  </span>
                  <span>
                    <b>City:</b> {buildId?.city}
                  </span>
                  <span>
                    <b>Zip:</b> {buildId?.zip}
                  </span>
                </div>
              </div>
            </div>
            <Datatable
              columns={columns}
              myData={masterData}
              pagination={true}
              pageSize={filters.limit}
              page={filters.page}
              class="-striped -highlight"
              handleChange={handleFilterChange}
              pages={totalPages}
              handleSort={handleSort}
              fetch={fetch}
              model={MODEL.VERSION}
            />
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default BoardMemberModal;
