import React, { Fragment } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const DetailsModalCommon = (props) => {
  const { id } = props || {};
  return (
    <Fragment>
      <Modal isOpen={true} toggle={props.onClose} size="lg" centered={true}>
        <ModalHeader toggle={props.onClose}>{"Business List"}</ModalHeader>
        <ModalBody>
          
            <div className="default-according style-1 faq-accordion">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>Building name:</b> {id?.name}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      <b>Street:</b> {id?.street}
                    </p>
                    <p>
                      <b>City:</b> {id?.city}
                    </p>
                    <p>
                      <b>Zip:</b> {id?.zip}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default DetailsModalCommon;
