import React, { Fragment, useEffect, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { API_CONFIG } from '../../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../../constant/common';
import UtilService from '../../../services/common.service';
import Button from "../../common/button/index";

const Configuration = () => {
    const [configurationData, setConfigurationData] = useState()
    const { register, handleSubmit, errors, setValue, clearError, watch } = useForm({ mode: "onChange" });
    const watchCheck = watch("isMasterOtpEnabled", true);
    useEffect(() => {
        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const fetch = id => {
        let request = { ...API_CONFIG.view };
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                setDefaultValues(res.data)
                setConfigurationData(res.data)
            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }
    const setDefaultValues = (data) => {
        if (!data) data = configurationData
        setValue("masterOtp", data.masterOtp)
        setValue("isMasterOtpEnabled", data.isMasterOtpEnabled)
        setValue("isEmailServiceActive", data.isEmailServiceActive)
        setValue("isSmsServiceActive", data.isSmsServiceActive)
        setValue("maxLoginAttempts", data.maxLoginAttempts)
        setValue("blockedFor", data.blockedFor)
        setValue("attributeMaxCount", data.attributeMaxCount)
        setValue("selectFutureYearsCount", data.selectFutureYearsCount)
        setValue("selectPastYearsCount", data.selectPastYearsCount)
    }
    const onSubmit = data => {
        if (data !== '') {
            let request = { ...API_CONFIG.update }
            request.url = request.url + configurationData._id
            request.obj = { ...data };
            UtilService.callApi(request, function (res) {
                if (res && res.data && res.code === API_STATUS.OK) {
                    setConfigurationData(res.data)
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            <form className="form theme-form">
                <div className="">
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Is Master OTP Enable ?</label>
                        <div className="col-sm-9 col-md-3 col-form-label">
                            <input onChange={(e) => {
                                if (!e.target.checked) {
                                    clearError("masterOtp")
                                }
                            }}
                                name="isMasterOtpEnabled" ref={register} defaultChecked={true} type="checkbox" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Master OTP <span className="color-red">{watchCheck ? "*" : ""}</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="masterOtp" className="form-control"
                                ref={register({
                                    required: watchCheck ? true : false,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Please enter valid OTP."
                                    },
                                    minLength: {
                                        value: 4,
                                        message: "OTP should contain 4 digits."
                                    },
                                    maxLength: {
                                        value: 4,
                                        message: "OTP should contain 4 digits."
                                    }
                                })} type="number" placeholder="Enter Master OTP" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.masterOtp && (errors.masterOtp.message ? errors.masterOtp.message : 'Please enter master otp.')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Email Service</label>
                        <div className="col-sm-9 col-md-3 col-form-label">
                            <input
                                name="isEmailServiceActive" ref={register} defaultChecked={true} type="checkbox" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">SMS Service</label>
                        <div className="col-sm-9 col-md-3 col-form-label">
                            <input
                                name="isSmsServiceActive" ref={register} defaultChecked={true} type="checkbox" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Max Login Attempts <span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="maxLoginAttempts" className="form-control"
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Please enter valid number."
                                    },
                                    min: {
                                        value: 1,
                                        message: "Max login attempts should be greater than 0."
                                    }
                                })} type="number" placeholder="Enter Max Login Attempts" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.maxLoginAttempts && (errors.maxLoginAttempts.message ? errors.maxLoginAttempts.message : 'Please enter max login attempts.')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Blocked For (Minutes) <span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="blockedFor" className="form-control"
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Please enter valid number."
                                    },
                                    min: {
                                        value: 0,
                                        message: "Blocked Time should be greater than or equal to 0."
                                    }
                                })} type="number" placeholder="Enter Minutes" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.blockedFor && (errors.blockedFor.message ? errors.blockedFor.message : 'Please enter block time')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Property Attribute Max Count<span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="attributeMaxCount" className="form-control"
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Please enter valid number."
                                    },
                                    min: {
                                        value: 1,
                                        message: "Max count should be greater than 0."
                                    }
                                })} type="number" placeholder="Enter Max Count" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.attributeMaxCount && (errors.attributeMaxCount.message ? errors.attributeMaxCount.message : 'Please enter max count')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Allow Future Years Selection (Years)<span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="selectFutureYearsCount" className="form-control"
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Please enter valid number."
                                    },
                                    min: {
                                        value: 1,
                                        message: "Years should be greater than 0."
                                    }
                                })} type="number" placeholder="Enter Years" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.selectFutureYearsCount && (errors.selectFutureYearsCount.message ? errors.selectFutureYearsCount.message : 'Please enter max count')}</span>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-md-3 col-form-label">Allow Past Years Selection (Years)<span className="color-red">*</span></label>
                        <div className="col-sm-9 col-md-3">
                            <input name="selectPastYearsCount" className="form-control"
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]*$/,
                                        message: "Please enter valid number."
                                    },
                                    min: {
                                        value: 1,
                                        message: "Years should be greater than 0."
                                    }
                                })} type="number" placeholder="Enter Years" />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <span className="error-message sideErrorMessage m-t-10">{errors.selectPastYearsCount && (errors.selectPastYearsCount.message ? errors.selectPastYearsCount.message : 'Please enter max count')}</span>
                        </div>
                    </div>
                </div>
                <div className="">
                    <Button onClick={handleSubmit(onSubmit)} color="primary mr-3">Submit</Button>
                    <Button onClick={() => setDefaultValues(false)} color="light">Reset</Button>
                </div>
            </form>
        </Fragment>
    )
}
export default Configuration;