import React from 'react';
import CKEditor from "react-ckeditor-component";
export default class Editor extends React.Component {
    constructor(props) {
        super(props)
        this.handleRTEChange = this.handleRTEChange.bind(this)
    }
    handleRTEChange(evt) {
        this.props.onChange(evt)
    }
    render() {
        return (
            <div>
                <CKEditor
                    activeclassName="p10"
                    content={this.props.content}
                    events={{
                        "change": this.handleRTEChange
                    }}
                />
            </div>
        )
    }
}