import React, { Fragment, useEffect, useRef, useState } from 'react';
import useForm from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { API_FAQS } from '../../constant/apiConstants';
import { API_STATUS, DEFAULT_API_ERROR } from '../../constant/common';
import UtilService from '../../services/common.service';
import Editor from '../common/editor';
import MultiLanguage from '../common/MultiLanguage';
const Upsert = (props) => {
    const [defaultLanguage, setDefaultLanguage] = useState('EN')
    const [selectedLanguage, setSelectedLanguage] = useState('EN')
    const [multiLanguageData, setMultiLanguageData] = useState()

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const { edit } = props
    const prevEdit = usePrevious({ edit });
    const [modal8, setModal8] = useState();
    const { register, handleSubmit, watch, errors, setValue, reset } = useForm({ mode: "onChange" });
    const answer = useRef({});
    answer.current = watch("answer", "")
    const toggle8 = () => {
        if (modal8) {
            setValue("answer", "")
            setSelectedLanguage(defaultLanguage)
            setMultiLanguageData(null)

            props.editDone()
        }
        setModal8(!modal8)
    }
    useEffect(() => {
        register({ name: "answer" }, { required: true })
    }, [])
    useEffect(() => {
        if (prevEdit && prevEdit.edit !== edit) {
            if (edit) {
                toggle8()
                fetch(edit)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    const fetch = id => {
        let request = { ...API_FAQS.view };
        request.url = request.url + id
        UtilService.callApi(request, function (res) {
            if (res && res.data && res.code === API_STATUS.OK) {
                let { question, answer } = res.data
                setValue("question", question)
                setValue("answer", answer)
                if (res.data.multiLanguageData) {
                    setMultiLanguageData(res.data.multiLanguageData)
                    if (res.data.multiLanguageData.EN) {
                        setDefaultLanguage('EN')
                        setSelectedLanguage('EN')
                    } else if (res.data.multiLanguageData.DANISH) {
                        setDefaultLanguage('DANISH')
                        setSelectedLanguage('DANISH')
                    }
                } else {
                    setMultiLanguageData()

                }


            }
            else {
                toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
            }
        });
    }
    const onSubmit = data => {
        if (data !== '') {
            let request = edit ? { ...API_FAQS.update } : { ...API_FAQS.create };
            request.url = edit ? request.url + edit : request.url
            request.obj = { ...data };
            let datas = multiLanguageData
            if (Object.keys(datas).length) {
                Object.keys(datas).map((key, val) => {
                    datas[key].answer = data.answer

                })
            }
            request.obj.multiLanguageData = datas

            if (props.parentId) request.obj.parentId = props.parentId
            console.log(request)
            UtilService.callApi(request, function (res) {
                if (res && res.data && (res.code === API_STATUS.CREATED || res.code === API_STATUS.OK)) {
                    toggle8()
                    props.fetch()
                    toast.success(res.message)
                }
                else {
                    toast.error(res && res.message ? res.message : DEFAULT_API_ERROR)
                }
            });
        } else {
            errors.showMessages();
        }
    };
    let selected = selectedLanguage
    let defaults = defaultLanguage

    function handleAnswerChange(e) {
        var value = e.editor.getData();
        // setDefaultTerms(value)
        let var1 = { "answer": value }
        let val = {
            [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
        }
        setMultiLanguageData({ ...multiLanguageData, ...val })
        if (selectedLanguage === defaultLanguage) {
            setValue("answer", value)
        }
    }
    
    return (
        <Fragment>
            <Button className="m-l-10" color="primary" onClick={toggle8}>{props.title}</Button>
            <Modal isOpen={modal8} toggle={toggle8} size="lg" centered={true}>
                <ModalHeader toggle={toggle8}>{props.title}</ModalHeader>
                <ModalBody>
                    <form className="form theme-form" onSubmit={handleSubmit(onSubmit)}>

                        <MultiLanguage
                            setSelectedLanguage={(lang) => setSelectedLanguage(lang)}
                            setDefaultLanguage={(lang) => setDefaultLanguage(lang)}
                            defaultLanguage={defaultLanguage}
                            selectedLanguage={selectedLanguage}
                        />
                        
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-2 col-form-label">Question <span className="color-red">*</span></label>
                            <div className="col-sm-12 col-md-10">
                                <input name="question"
                                    value=
                                    {multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].question ? multiLanguageData[selectedLanguage].question : ""}
                                    onChange={e => {
                                        let var1 = { "question": e.target.value }
                                        let val = {
                                            [selectedLanguage]: multiLanguageData && multiLanguageData[selectedLanguage] ? { ...multiLanguageData[selectedLanguage], ...var1 } : var1
                                        }
                                        setMultiLanguageData({ ...multiLanguageData, ...val })
                                        if (selectedLanguage === defaultLanguage) {
                                            setValue("code", e.target.value.split(" ").join("").toUpperCase())
                                            setValue("question", e.target.value)
                                        }
                                    }}
                                    ref={register({ required: true })}
                                    className="form-control" type="text"
                                    placeholder="Enter Question"
                                />
                                <span className="color-red">{errors.question && 'Please enter question.'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-12 col-md-2 col-form-label">Answer <span className="color-red">*</span></label>
                            <div className="col-sm-12 col-md-10">
                                <Editor selectedLanguage={selectedLanguage}
                                    defaultLanguage={defaultLanguage}
                                    content={multiLanguageData && multiLanguageData[selectedLanguage] && multiLanguageData[selectedLanguage].answer ? multiLanguageData[selectedLanguage].answer : ""}

                                    onChange={(e, lan) => {

                                        handleAnswerChange(e)
                                        // setValue("answer", e)

                                    }} />
                                <span className="color-red">{errors.answer && 'Please enter answer.'}</span>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                
                <ModalFooter>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">Save</Button>
                    <Button onClick={()=>{
                        setDefaultLanguage('EN')
                        setSelectedLanguage('EN')
                        setMultiLanguageData({})
                        setValue('answer',"")
                        setValue('question',"")
                        setValue('code',"")
                    }} outline={true} color="primary">Reset</Button>
                    <Button outline={true} color="primary" onClick={toggle8}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
}

export default Upsert;