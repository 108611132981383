import React, { useState } from 'react'
import ToolTipCommon from '../ToolTip'
import useSearch from '../select/useSearch'

export const SearchBar = ({ title, handleSearch }) => {
  const [searchValue, setSearchValue] = useState('')
  const delay = useSearch()

  const handleChange = (e) => {
    const val = e.target.value
    setSearchValue(val)
    delay.debounced(() => handleSearch(val))
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch(searchValue)
    }
  }
  const handleCancel = () => {
    setSearchValue('')
    handleSearch('')
  }

  return (
    <div className="small-search-box">
      <div className="search-form">
        <div className="form-group m-0">
          <ToolTipCommon title={title}>
            <input
              value={searchValue}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleChange(e)}
              className="form-control"
              type="text"
              placeholder={title}
            />
          </ToolTipCommon>
          {searchValue !== '' ? (
            <div onClick={() => handleCancel()}>
              <i className="icon-close search-close f-12"></i>
            </div>
          ) : (
            <div>
              <i className="fa fa-search search-close f-12"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SearchBar
